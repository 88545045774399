<template>
  <VueRecaptcha :sitekey="siteKey" :load-recaptcha-script="true" @verify="handleSuccess" @error="handleError"></VueRecaptcha>
</template>

<script >

import { VueRecaptcha } from 'vue-recaptcha';

export default {
    name: 'ReCaptcha',
    emits: ["verify"],

    components: {
        VueRecaptcha
    },

    data(){
        return {
            siteKey:'6LfB1bgiAAAAAAlIv5A0KjP_0A-eG0FaPvTjzFpv',
        }
    },

    methods: {

        handleError() {

            this.$emit('verify', false);
        },

        handleSuccess() {

            this.$emit('verify', true);
        }
    }
}

</script>