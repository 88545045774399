<template>
    <div class="e170_1000">

        <div class="e170_1060 noselect">
            <div class="e170_1065">
                <CollapseObject :config="bidConfig" />
            </div>
            <div class="e170_1070 noselect">
                <div class="e170_1080 noselect" :style="getBackground">Submitted</div>
            </div>
        </div>

        <div :id="id" class="e170_1030">

            <div class="e170_1035">
                <div class="e170_1010">
                    <PilotTile  :config="data.pilotData" />
                </div>

                <div class="e170_1010">
                    <UserDrone  :config="data.droneData" />
                </div>
            </div>

            <div class="e170_1040">
                <TextField :config="textConfig" />
                <div class="e170_1100">
                    <div id="curr" class="e170_1110">
                        <InputField :config="currencyConfig" />
                    </div>
                    <div class="e170_1120">
                        <InputField :config="quoteConfig" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PilotTile from './PilotTile.vue'
import UserDrone from './UserDrone.vue'
import TextField from './TextField.vue'
import InputField from './InputField.vue'
import CollapseObject from './CollapseObject.vue'
//import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

//import { Spinner } from 'spin.js';
//import 'spin.js/spin.css';
//import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

export default {
  name: "BidTile",
  emits: [],

  components: {
    PilotTile,
    UserDrone,
    TextField,
    InputField,
    CollapseObject
  },

  props: {
    config:{
      type:Object,
    }
  },

  computed: {

        getBackground: function(){

            if(this.config){

                let background = {"background-color":'rgba(230, 230, 255, 1)'};

                switch(this.config.status){

                    case 1:
                        background = {"background-color":'rgba(230, 230, 255, 1)'};
                        break;

                }

                return background;

            } else return {"background-color":'rgba(230, 230, 255, 1)'};
        },
    },

  data(){
        return {
            id:'',
            pilot:null,
            drone:null,
            data:{description:'', quote:'0.00', currency:'USD', title:'', pilotData:{license:''}, droneData:{make:0, model:0}},
            currencyList:require('../assets/currency-list.json'),
            textConfig:{id:"description", type:"input", name:"description", disabled:true, title:"Description", rows:3, placeholder:"", value:"", required:false, dataTest:""},
            currencyConfig:{id:"quote", type:"input", regx:this._TEXT, name:"quote", disabled:true, title:"Currency", placeholder:"", align:'right', value:'', required:false, dataTest:""},
            quoteConfig:{id:"quote", type:"input", regx:this._TEXT, name:"quote", disabled:true, title:"Quotation Amount", placeholder:"", align:'right', value:'', required:false, dataTest:""},
            bidConfig:{label:"Bid", base:"Bid", div:"bid", fontSize:"18px"},
        }
  },

  created(){
    console.log(this.config);

    if(this.config) this.data = this.config;

    let r = (Math.random() + 1).toString(36).substring(7);

    this.textConfig.value = this.data.description;

    this.quoteConfig.value = this.data.quote;

    this.currencyConfig.value = this.data.currency;

    this.bidConfig.label = this.data.title;

    this.id = this.bidConfig.base + r;

    this.bidConfig.div = this.id;
  },

  methods: {

  }
}

</script>

<style scoped>
.e170_1000{
    width:100%;
    margin:0 auto;
    border:1px solid rgba(200, 200, 200, 1);
}

.e170_1010{
    vertical-align:top;
    display:inline-block;
}

.e170_1030{
    margin:5px;
    display:none;
}

.e170_1035{
    display:inline-block;
    vertical-align:top;
}

.e170_1040{
    width:calc(100% - 450px);
    position:relative;
    display:inline-block;
}

.e170_1060{
    width:100%;
    margin:10px auto;
}

.e170_1065{
    width:calc(100% - 100px);
    display:inline-block;
}

.e170_1070{
    width:90px;
    margin-right:10px;
    text-align:right;
    display:inline-block;
}

.e170_1080{
    height:30px;
    width:100%;
    text-align:center;
    border-radius:15px;
    font-family:'Open Sans';
    font-size:14px;
    font-weight:bold;
    padding-top:4px;
}

.e170_1100{
    width:100%;
    height:85px;
    text-align:right;
}

.e170_1110{
    width:80px;
    height:85px;
    margin-right:10px;
    display:inline-block;
}

.e170_1120{
    text-align:right;
    width:150px;
    height:85px;
    display:inline-block;
}

@media only screen and (max-width:600px) {

    .e170_1040{
        width:100%;
        display:block;
    }
}

@media only screen and (min-width:600px) {
    .e170_1040{
        width:90%;
        display:block;
        margin:0 auto;
    }
}

@media only screen and (min-width:1200px) {
    .e170_1040{
        width:calc(100% - 450px);
        position:relative;
        display:inline-block;
    }
}
</style>