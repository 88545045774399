<template>
    <canvas class="e120_1000" id=cvas></canvas>
</template>

<script>
import { Vector3, FreeCamera, Scene, Engine, SceneLoader, Layer} from 'babylonjs';
import 'babylonjs-loaders';

export default {
  name: 'ThreeDViewer',
  emits: [],

  components: {
      //Button
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
          
        }
  },

  created(){

    
  },

  async mounted(){

    // Get the canvas DOM element
    var canvas = document.getElementById('cvas');
    // Load the 3D engine

    var engine = new Engine(canvas, true, {preserveDrawingBuffer: true, stencil: true});
    // CreateScene function that creates and return the scene
    
    // call the createScene function
    var scene = this.createScene(engine, canvas);

    new Layer('','https://dtweb-storage-22ce1749111633-dev.s3.ap-southeast-1.amazonaws.com/private/model/sky.jpg', scene, true);

    // run the render loop
    engine.runRenderLoop(function(){

      scene.render();
    });
    // the canvas/window resize event handler
    window.addEventListener('resize', function(){

      engine.resize();
    });



/*
    loader.load('https://dtweb-storage-22ce1749111633-dev.s3.ap-southeast-1.amazonaws.com/private/model/ciltest2.glb',
      ( gltf ) => {

        console.log(gltf);

        let model = gltf.scene.children[0];

        //model.rotation.setFromVector3(new Vector3(0.079, 0.0346, -0.996));

        scene.add(model);

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.addEventListener('change', render);

        //this.fitCameraToCenteredObject(camera, model, -100, controls ) 
      },
      ( ) => {

        //progress console.log(x);
      },
      ( error ) => {

        console.log( error );
      }
    );

    const render = () =>{

        requestAnimationFrame(render);

        //renderer.outputEncoding = sRGBEncoding;

        renderer.render(scene, camera);

        var vector = new Vector3(); // create once and reuse it!

        camera.getWorldDirection( vector );

       // console.log(vector);
    }

    render();*/
  },

  methods: {

    createScene(engine, canvas){
      // Create a basic BJS Scene object
      var scene = new Scene(engine);

      // Create a FreeCamera, and set its position to {x: 0, y: 5, z: -10}
      var camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene);
      // Target the camera to scene origin
      camera.setTarget(Vector3.Zero());
      // Attach the camera to the canvas
      camera.attachControl(canvas, false);
      // Create a basic light, aiming 0, 1, 0 - meaning, to the sky
      //new HemisphericLight('light1', new Vector3(0, 1, 0), scene);
      // Create a built-in "sphere" shape; its constructor takes 6 params: name, segment, diameter, scene, updatable, sideOrientation
      //var sphere = Mesh.CreateSphere('sphere1', 16, 2, scene, false, Mesh.FRONTSIDE);
      // Move the sphere upward 1/2 of its height
      //sphere.position.y = 1;
      // Create a built-in "ground" shape; its constructor takes 6 params : name, width, height, subdivision, scene, updatable

      SceneLoader.Append("https://dtweb-storage-22ce1749111633-dev.s3.ap-southeast-1.amazonaws.com/private/model/", "FullSIte.glb", scene, function (scene) {

        let mesh = scene.meshes[1];

        mesh.setPivotPoint( Vector3.Zero() );
        
        // Create a default arc rotate camera and light.
        scene.createDefaultCameraOrLight(true, true, true);

        // The default camera looks at the back of the asset.
        // Rotate the camera by 180 degrees to the front of the asset.
        scene.activeCamera.alpha += Math.PI;
      });

      //Mesh.CreateGround('ground1', 6, 6, 2, scene, false);
      // Return the created scene
      return scene;
  }

  }
}

</script>

<style scoped>

.e120_1000{
    width:100%;
    height:100%;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>