<template>

  <!--<AppHead title="About" />-->
  <div class="e100_1000">

    <div v-if="clientLogin" id="loginForm" >

      <div v-if="userAuthenticated">
        
        <MainDashboard :config="config" :showProfile="showProfile" @home="home" @type="setType"/>
      </div>

      <div v-else>

        <LogIn3 :config="config" @login="login" @cklogin="cklogin" />
      </div>
    </div>

    <div v-else>

      <div class="e100_1010">
        <Header :config="config" :type="type" :headerBackground="headerBackground" :key="refresh" @login="login" @signup="signup" @quote="quote" @contact="contactUs" @home="home" @vertical="vertical" @service="service"  @profile="profile" @news="showNews" />
      </div>

      <div v-if="clientSignup" id="signUpForm" >
          <SignUp :config="config" />
      </div>

      <div v-else-if="landingPage">
        <Landing :config="config" @nav="nav" @contact="contactUs" @quote="quote" @background="setHeaderBackground"/>
      </div>

      <div v-else-if="contact">
        <ContactUs :config="config" @message="message" />
      </div>

      <div v-else-if="quotation">
        <QuotationPage :config="config" @contact="contactUs" @quotation="quotationReq" />
      </div>

      <div v-else-if="news">
        <NewsPage :config="config" :key="updateNews"/>
      </div>

      <div v-else-if="thankYou">
        <ThankYou :config="config" :key="thankYou" @home="home" />
      </div>

      <div class="e270_1000" v-else-if="verticalDetail" :key="redrawVertical">
        <VerticalDetail  :config="config" @contact="contactUs" @quote="quote" />
      </div>

      <div v-else-if="serviceDetail" :key="redrawService">
        <ServiceDetail :config="config" @contact="contactUs" @quote="quote"/>
      </div>

      <div v-if="!(clientLogin && userAuthenticated)" class="e100_1020">
          <Footer @contact="contact" @quote="quote" />
      </div>
    </div>
  </div>

</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Landing from './components/Landing.vue'
import VerticalDetail from './components/VerticalDetail.vue'
import ServiceDetail from './components/ServiceDetail.vue'
import LogIn3 from './components/LogIn3.vue'
import SignUp from './components/Signup.vue'
import ContactUs from './components/ContactUs.vue'
import QuotationPage from './components/QuotationPage.vue'
import NewsPage from './components/NewsPage.vue'
import ThankYou from './components/ThankYou.vue'
import MainDashboard from './components/MainDashboard.vue'

import { Auth } from 'aws-amplify';
import Amplify, { API } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);
API.configure(awsconfig);

const industries = [
  {id:0, title:"Commercial Real Estate"},
  {id:1, title:"Oil & Gas"},
  {id:2, title:"Environmental Monitoring"}
];

const verticals = [
  {id:0, img:require('./assets/facade1.png'), services:[4, 2, 3, 1], img_sm:require('./assets/facade1.png'), title:"Facade Inspection", text:'Reduce your maintenance costs and prioritize your spending with our state-of-the-art drone facade inspection service. Using the latest camera and thermal imaging technology, our drones can spot the telltale signs of façade deterioration so you can take action quickly with accurate, referenceable data.'},
  {id:1, img:require('./assets/Oil_Gas_01.png'), services:[1, 5, 3, 2], img_sm:require('./assets/oil_gas2_sm.png'), title: "Petro-Chemical Industry", text:'Drone data allows oil and gas professionals to make informed decisions regarding their operations while streamlining inspections and optimizing their current processes. This information can be shared with all teams in an easily digestible format, helping to inform every decision, big or small. The result is greater efficiency, increased employee safety, and better documentation of your sites.'},
  {id:2, img:require('./assets/env_01.png'), services:[1, 2, 3], img_sm:require('./assets/gas_sensing_sm.png'), title:"Environmental Monitoring", text:'Accurately measure emissions from industrial and commercial buildings and view geographically located results in real time. Using the latest gas sensor technology, our drones can measure the particle concentration of 8 gases, providing you with actionable data for environmental monitoring and audit activities.'},
];

const services = [
  {id:0, img:require('./assets/gas_001.jpg'), title:"Gas Measurement", features:[0, 1, 2, 3, 4, 5, 6], text:"Real-time visualisation of gas concentration distributions as a geographically located 2D grid, 2D contour map, or 3D point cloud. The data can be used for gas leak detection and historical analysis of gas concentrations."},
  {id:1, img:require('./assets/survey_001.jpg'), title:"Inspection & Survey", features:[0, 1, 2, 3, 4, 5, 6], text:"Improve survey and inspection times by up to 90% while reducing costs and risks. Using a combination of ground control points, real-time GPS corrections and state-of-the-art drones, highly accurate inspection and survey data can be obtained. This data can be used for 3D site reconstruction, construction planning and historical comparative analysis."},
  {id:2, img:require('./assets/3d_001.jpg'), title:"3D Model Reconstruction", features:[0, 1, 2, 3, 4, 5, 6], text:"Multiple photographs taken from different angles can be used to reconstruct a 3D model of a building or plant. This model can be used for construction planning or as part of a digital twin."},
  {id:3, img:require('./assets/oil_gas2.png'), title:"Aerial Photography", features:[0, 1, 2, 3, 4, 5, 6], text:"Create visually exciting, high-resolution images and videos with state-of-the-art drones equipped with gyro-stabilised cameras and 4K video recording capability. Make your marketing and promotional activities more effective by using up-to-date, high-resolution video and photo footage. "},
  {id:4, img:require('./assets/sd03.jpg'), title:"Digital Twin & Simulation", features:[0, 1, 2, 3, 4, 5, 6], text:"Create a cost-effective digital twin by overlaying critical sensor data on top of a 3D topographic model of your facility. This service is designed specifically for older factories that may not have been designed to BIM standards, but still want to take advantage of advances in 3D rendering technology and sensor integration."},
  {id:5, img:require('./assets/tank_001.jpg'), title:"Tank Inspection", features:[0, 1, 2, 3, 4, 5, 6], text:"Remotely inspect large chemical storage tanks for telltale signs of rust and corrosion. High definition photo and video footage can provide detailed information on any surface within the tank and can also serve as a baseline for future inspections."}

];

const features = [
  {id:0, img:require('./assets/gas_feature_001.jpg'), title:"Visualisation", text:"Visualise gas concentration distributions in real-time as a geographically located 2D grid, 2D contour map, or 3D point cloud. Data can be used for gas leak detection and historic analysis of gas concentrations."},
  {id:1, img:require('./assets/survey_002.jpg'), title:"Inspection & Survey", text:"Improve survey and inspection times by up to 90% while reducing costs and risks. Using a combination of ground control points, real-time GPS corrections and state-of-the-art drones, highly accurate inspection and survey data can be obtained. This data can be used for 3D site reconstruction, construction planning and historical comparative analysis."},
  {id:2, img:require('./assets/3d_001.jpg'), title:"3D Model Reconstruction", text:"Using a process known as photogrammetry, multiple photographs taken from different angles can be used to reconstruct a 3D model of a building or plant. This model can be used for construction planning or as part of a digital twin."},
  {id:3, img:require('./assets/photo_001.jpg'), title:"Aerial Photography", text:"Create visually exciting, high-resolution images and videos with state-of-the-art drones equipped with gyro-stabilised cameras and 4K video recording capability. Make your marketing and promotional activities more effective by using up-to-date, high-resolution video and photo footage. "},
  {id:4, img:require('./assets/sd03.jpg'), title:"Digital Twin & Simulation", text:"Create a cost-effective digital twin by overlaying critical sensor data on top of a 3D topographic model of your facility. This service is designed specifically for older factories that may not have been designed to BIM standards, but still want to take advantage of advances in 3D rendering technology and sensor integration."},
  {id:5, img:require('./assets/survey_003.jpg'), title:"Centimeter Level Precision", text:"We use high-precision GNSS receivers that support all major global satellite navigation systems and perform real-time differential corrections during drone flight to produce centimetre-level position data for improved relative accuracy."},
  {id:6, img:require('./assets/drone_001.jpg'), title:"State-of-the-Art Drones", text:"Our drones use the latest commercial technology, including laser rangefinding, thermal imaging, 120x zoom and 50MPixel cameras for a wide range of inspection and survey work. High-precision geographic data is obtained through real-time kinematic measurements."},
  {id:7, img:require('./assets/survey_003.jpg'), title:"Centimeter Level Precision", text:""},
  {id:8, img:require('./assets/survey_003.jpg'), title:"Centimeter Level Precision", text:""},
  {id:9, img:require('./assets/survey_003.jpg'), title:"Centimeter Level Precision", text:""},
  {id:10, img:require('./assets/survey_003.jpg'), title:"Centimeter Level Precision", text:""},
];

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Landing,
    LogIn3,
    ContactUs,
    SignUp,
    VerticalDetail,
    ServiceDetail,
    QuotationPage,
    NewsPage,
    ThankYou,
    MainDashboard,
  },
  
  
/*
FROM: https://metatags.io/
<!-- Primary Meta Tags -->
<title>Drone Tech: Professional Drone Solutions For Sensing, Survey and Inspection</title>
<meta name="title" content="Drone Tech: Professional Drone Solutions For Sensing, Survey and Inspection">
<meta name="description" content="State of the art drone platforms for sensing, inspection and survey tasks. We specialise in providing fast and affordable services for facade inspection and gas emission measurement.">

<!-- Open Graph / Facebook -->
<meta property="og:type" content="website">
<meta property="og:url" content="https://master.d3s7py07h3zan6.amplifyapp.com/">
<meta property="og:title" content="Drone Tech: Professional Drone Solutions For Sensing, Survey and Inspection">
<meta property="og:description" content="State of the art drone platforms for sensing, inspection and survey tasks. We specialise in providing fast and affordable services for facade inspection and gas emission measurement.">
<meta property="og:image" content="">

<!-- Twitter -->
<meta property="twitter:card" content="summary_large_image">
<meta property="twitter:url" content="https://master.d3s7py07h3zan6.amplifyapp.com/">
<meta property="twitter:title" content="Drone Tech: Professional Drone Solutions For Sensing, Survey and Inspection">
<meta property="twitter:description" content="State of the art drone platforms for sensing, inspection and survey tasks. We specialise in providing fast and affordable services for facade inspection and gas emission measurement.">
<meta property="twitter:image" content="">
*/

  data() {
    return {
      landingPage: true,
      contact: false,
      serviceDetail: false,
      clientLogin: false,
      clientSignup: false,
      loginok: false,
      quotation:false,
      news:false,
      verticalDetail:false,
      verticals:null,
      industries:null,
      services:null,
      showProfile:false,
      updateNews:false,
      item:'',
      navhash:{},
      hash: '#',
      verticalId:0,
      serviceId:0,
      redrawService:false,
      redrawVertical:false,
      user:null,
      userAuthenticated:false,
      thankYou:false,
      dashboard:false,
      type:0,
      refresh:0,
      headerBackground:'transparent',
    }
  },

  async created(){

    this.navhash[this.hash] = {item:this.item, lp:this.landingPage, vd:this.verticalDetail, sd:this.serviceDetail};

    window.location.hash = this.hash;

    setInterval(this.checkHash, 100);

    this.verticals = verticals;

    this.industries = industries;

    this.services = services;

    this.config = {verticals:verticals, industries:industries, services:services, features:features};

    let user
    
    try{
      
      user = await Auth.currentAuthenticatedUser({bypassCache: true});

      this.userAuthenticated = true;

      //this.user.data = Object.entries(user.attributes).map(([key, value]) => ({Name:key,Value:value}));

      this.user = user;

    } catch {

      this.userAuthenticated = false;
      
      this.user = null;
    }
  },

  methods:{

    checkHash(){

      if (window.location.hash != this.hash) {

          let navItem = (window.location.hash.length)?this.navhash[window.location.hash]:this.navhash["#"];

          if(navItem){

            this.landingPage = navItem.lp;
            this.verticalDetail = navItem.vd;
            this.serviceDetail = navItem.sd;

            this.hash = window.location.hash;

            this.nav(navItem.item, false);
          }
        }
    },

    setHeaderBackground(background){

      this.headerBackground = background;

      this.refresh = Math.random();
    },

    setType(type){

      this.type = type;

      this.refresh = Math.random();
    },

    clearAll(){

      this.landingPage = false;

      this.contact = false;

      this.quotation = false;

      this.news = false;

      this.clientSignup = false;

      this.clientLogin = false;

      this.verticalDetail = false;

      this.serviceDetail = false;

      this.thankYou = false;
      
      this.flightDash = false;

      this.showProfile = false;
    },

    profile(){

      this.clearAll();

      this.showProfile = true;

      this.clientLogin = true;
    },

    home(){

      this.headerBackground = 'transparent';

      this.clearAll();

      this.landingPage = true;

      this.type = 0;

      this.refresh = Math.random();
    },

    service(id){

      this.clearAll();

      this.config.id = id;

      this.serviceDetail = true;

      this.redrawService = Math.random();
    },

    vertical(id){

      this.clearAll();

      this.config.id = id;

      this.verticalDetail = true;

      this.redrawVertical = Math.random();
    },

    signup(){

      this.clearAll();

      this.clientSignup = true;
    },


    async login(){

      this.clearAll();

      this.clientLogin = true;

      let user
    
      try{
      
        user = await Auth.currentAuthenticatedUser({bypassCache: true});

        this.userAuthenticated = true;

        this.user = user;

      } catch {

        this.userAuthenticated = false;
        
        this.user = null;
      }
    },

    /*resetPassword(username) {
      // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
      // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

      // setup cognitoUser first
      cognitoUser = new AWSCognito.CognitoUser({
          Username: username,
          Pool: userPool
      });

      // call forgotPassword on cognitoUser
      cognitoUser.forgotPassword({
          onSuccess: function(result) {
              console.log('call result: ' + result);
          },
          onFailure: function(err) {
              alert(err);
          },
          inputVerificationCode() { // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
              var verificationCode = prompt('Please input verification code ', '');
              var newPassword = prompt('Enter new password ', '');
              cognitoUser.confirmPassword(verificationCode, newPassword, this);
          }
      });
    },*/

    contactUs(){

      this.headerBackground = '#ffffff';

      this.clearAll();

      this.contact = true;
    },

    quote(){

      this.headerBackground = '#ffffff';

      this.clearAll();

      this.quotation = true;
    },

    showNews(){

      this.headerBackground = '#ffffff';

      this.clearAll();

      this.news = true;

      this.updateNews = !this.updateNews;
    },

    async message(){

      this.clearAll();

      this.thankYou = true;
    },

    async quotationReq(){

      this.clearAll();

      this.thankYou = true;
    },

    cklogin(){

      this.clearAll();

      this.loginok = true;
    },

    cksignup(){

      this.clearAll();

      this.clientLogin = true;
    },

    randHash(){

      return '#' + (Math.random().toString(36)+'0000000000').slice(2, 8)
    },

    nav(item, pageNav=true){

      if(this.clientLogin) return;

      this.item = JSON.parse(JSON.stringify(item));

      switch(parseInt(item.id)){

        case 0:

          this.landingPage = true;
          this.verticalDetail = false;
          this.serviceDetail = false;
          break;

        case 1:

          this.landingPage = false;
          this.verticalDetail = true;
          this.item.id = 4;
          break;

        case 2:
          this.landingPage = false;
          this.verticalDetail = true;
          this.item.id = 5;
          break;

        case 3:
          this.landingPage = false;
          this.verticalDetail = true;
          this.item.id = 6;
          break;

        case 4:
        case 5:
        case 6:
          this.verticalDetail = false;
          this.serviceDetail = true;
          this.item.id = 0;
          break;
      }

      if(pageNav){

        this.hash = this.randHash();

        this.navhash[this.hash] = {item:JSON.parse(JSON.stringify(item)), lp:this.landingPage, vd:this.verticalDetail, sd:this.serviceDetail};

        window.location.hash = this.hash;

        console.log(this.hash);
        console.log(JSON.parse(JSON.stringify(item)));
        console.log(JSON.parse(JSON.stringify(this.navhash)));
        console.log("");
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

/*@font-face {
  font-family: "Montserrat";
  src: local("./assets/Montserrat"),
   url(./assets/Montserrat/static/Montserrat-Regular.ttf) format("truetype");
}*/

#app {
  font-family: 'Montserrat', 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root {

  /*--dt-font-family:'Lato', 'Arial';
  --dt-color1:#232F3F;
  --dt-color2:#1F2734;
  --dt-color3:#ffffff;
  --dt-color4:#ff5804;
  --dt-color5:#7F531F;*/
  --dt-width:800px;
  --color:#ff0000;

  --dt-font-family:'Montserrat', 'Open Sans';
  --dt-color1:#232F3F;
  --dt-color2:#1F2734;
  --dt-color3:#2a338f;
  --dt-color4:rgba(240, 66, 0, 1);
  --dt-color5:#7F531F;

  --dt-orange:rgba(240, 66, 0, 1);
  --dt-white:rgba(255, 255, 255, 1);

  --amplify-components-authenticator-router-background-color: rgba(255, 255, 255, 1); /*transparent;*/
  --amplify-components-heading-color:rgba(240, 66, 0, 1);
  --amplify-components-authenticator-router-border-style:none;
  --amplify-components-authenticator-router-box-shadow:none;
  --amplify-components-button-focus-color: rgba(255, 255, 255, 1);
  --amplify-components-checkbox-button-color:rgba(240, 66, 0, 1);

  --amplify-colors-brand-primary-10: var(--dt-color4);
  --amplify-colors-brand-primary-20: var(--dt-color4);
  --amplify-colors-brand-primary-40: var(--dt-color4);
  --amplify-colors-brand-primary-60: var(--dt-color4);
  --amplify-colors-brand-primary-80: var(--dt-color4);
  --amplify-colors-brand-primary-90: var(--dt-color4);
  --amplify-colors-brand-primary-100: var(--dt-color4);
}

.amplify-tabs {
  display: none;
}

html {
    height: 100%;
}

.noselect {
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none;
            user-select: none; 
}

body{
    background:#ffffff;
    margin:0;
    padding:0;
    width:100%;
}

amplify-authenticator {
    justify-content: center;
    align-items: center;
    
    --container-display:'block';
    --box-shadow: 0 0 2px var(--poc-color4);

    --amplify-font-family:'Montserrat';
    --amplify-primary-color:var(--dt-color4);
    --amplify-secondary-color:var(--dt-color1);
    --amplify-background-color:var(--dt-color3);
    --background-color-active:var(--dt-color5);
    --link-active:var(--dt-color5);
    --link-hover:var(--dt-color5);
    --background-color-active:var(--dt-color5);
    --amplify-primary-shade:var(--dt-color5);
    --amplify-primary-tint:var(--dt-color5);
}

.amplify-authenticator__subtitle{

}
.amplify-authenticator__federated-text:hover {
  color:rgba(255, 255, 255, 1);
}

.e270_1000 { 
  position:relative;
  overflow-x:hidden;
}

.e100_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e100_1010{
  display:block;
}

.e100_1020{
    display:block;
}

.close_icon{
    width:16px;
    height:16px;
    position:absolute;
    left:calc(100% - 25px);
    top:10px;
    background-image:url(./assets/close.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

</style>
