<template>
    <div class="e410_1000">

        <div class="e410_1010">

            <ButtonActive :config="addPilotBtn" @click="addPilotForm" />
        </div>

        <div id="spin" class="e410_1020">

            <div class="e410_1030">
                <PilotTile v-for="pilot of pilots" :key="pilot" :config="pilot" @remove="removePilot"/>
            </div>
        </div>
    </div>

    <div v-if="add" class=e410_2000>

        <AddPilotForm  :config="pilots"  @close="close" @add="addPilot" />
    </div>

</template>

<script>
import ButtonActive from './ButtonActive.vue'
import AddPilotForm from './AddPilotForm.vue'
import PilotTile from './PilotTile.vue'

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
  name: 'PilotList',
  emits: [],

  components: {
    ButtonActive,
    AddPilotForm,
    PilotTile
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            add:false,
            pilots:[],
            addPilotBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Pilot", width:"150px"},
        }
  },

  created(){

    this.getPilots();
  },

  methods: {

    addPilotForm(){

        this.add = true;
    },

    close(){

        this.add = false;
    },

    async removePilot(pilot){

        const apiName = 'dtApiGateway';
        const path = '/pilot';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                remove:pilot
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        this.pilots = await API.post(apiName, path, data);

        this.close();

        spinner.stop();
    },

    async addPilot(pilot){

        const apiName = 'dtApiGateway';
        const path = '/pilot';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                add:pilot
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        this.pilots = await API.post(apiName, path, data);

        this.close();

        spinner.stop();
    },

    async getPilots(){

        const apiName = 'dtApiGateway';
        const path = '/pilot';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        this.pilots = await API.post(apiName, path, data);

        this.pilots.forEach(pilot => pilot.add = true);

        this.close();

        spinner.stop();
    }
  }
}

</script>

<style scoped>

.e410_1000{
    margin:0 auto;
}

.e410_1010{
    width:160px;
    height:80px;
    margin-left:calc(100% - 160px);
    position:relative;
    display:block;
}

.e410_1020{
    width:80%;
    min-height:40px;
    margin:0 auto;
}

.e410_1030{
    margin:5px;
    display:inline-block;
}

.e410_2000{
    width:500px;
    height:500px;
    background-color:rgba(240, 240, 240, 1);
    position:fixed;
    top:100px;
    left:calc((100% - 500px)/2);
    z-index:10;
}

@media only screen and (max-width:600px) {

    .e410_2000{
        width:90%;
    }
}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>