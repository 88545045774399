<template>
    <div class="container">
        <div class="title">{{config.title}}<span v-if="required" class="asterix">*</span></div>
        
        <select :id="id" v-model="selected" :name="name" class="input" :disabled="disabled" :key="refresh" :data-test="dataTest" @change="selection">

            <option v-for="option in options" :key="option" :selected="option.selected" :value="option.value">
                {{ option.text }} 
            </option>

        </select>

        <div :id="idError" class="err">Error</div>

    </div>
</template>

<script>

export default {
    name: 'SelectField',
    emits: ["change"],

    components: {
        //Button
    },


    props: {
        config: Object,
        error:Boolean,
    },

    data(){
            return {
                id:0,
                idError:0,
                name:'',
                dataTest:'',
                selected:'',
                disabled:false,
                required:false,
                refresh:0,
            }
    },

    watch:{

        'config.options'(){

            this.options = this.config.options;

            this.refresh = Math.random();
        },

        'config.error'(){

            if (this.config.error) {

                document.getElementById(this.id).style.borderColor = "red";

                document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                document.getElementById(this.idError).style.display = "block";

                document.getElementById(this.idError).innerHTML = "Selected value invalid";

            } else {

                document.getElementById(this.id).style.borderColor = "#949494";

                document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                document.getElementById(this.idError).style.display = "none";
            }
        }
    },

    created(){

        this.id = this.config.id;

        this.idError = "Err" + this.config.id;

        this.options = this.config.options;

        this.required = this.config.required;

        this.selected = this.config.value;

        this.name = this.config.name;

        this.dataTest = this.config.dataTest;

        if(this.config.disabled) this.disabled = this.config.disabled;
    },

    methods: {

        selection(){

            let e = document.getElementById(this.id);

            this.$emit("change", {text:e.options[e.selectedIndex].text, value:e.options[e.selectedIndex].value, index:e.selectedIndex});
        }

    }
}

</script>

<style scoped>

select {
   -webkit-appearance: none;
   -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    background-image: url(../assets/chevron-down-24.png);   /* Add custom arrow */
    background-position: calc(100% - 20px) 25px;
    background-repeat: no-repeat;
    margin-right:10px;
}

.container {
    width:100%;
    position:relative;
    font-family:'Open Sans';
    font-size:16px;
    font-weight:400;
}

.title {
    position:absolute;
    top:0px;
    left:0px;
    font-size:12px;
    font-weight:500;
}

.asterix{
    color:rgba(255, 0, 0, 1);
    font-size:12px;
    font-weight:500;
    margin-left:5px;
}
.input {
    position:absolute;
    top:20px;
    left:0px;
    width:100%;
    height:59px;
    border:1px solid #949494;
    border-radius:4px;
    padding:16px 12px;
}

.err {
    width:300px;
    position:absolute;
    top:82px;
    left:0px;
    font-family:'Open Sans';
    font-size:12px;
    font-weight:400;
    color:rgba(255, 0, 0, 1);
    display:none;
    text-align:left;
}
</style>