<template>
  
      <div class="e210_1030">

        <div class="e210_1035" :style="getImg"></div>

        <div class="e210_1040">

          <div class="e210_1130">
    
            <div class="e210_1140">{{title}}</div>

            <div class="e210_1150 noselect">{{text}}</div>
          
          </div>

        </div>

        <div class="e210_1050" :style="getImg">

        </div>

        <div class="e210_1060"></div>

        <div class="e210_1070"></div>

        <div class="e210_1080"></div>

        <div class="e210_1090"></div>

        <div class="e210_1100"></div>

        <div class="e210_1110"></div>

        <div class="e210_1120"></div>
  
        <!--<div>
          <FeatureHighlight />
          <FeatureHighlight />
          <FeatureHighlight />
          <FeatureHighlight />
        </div>-->
      </div>
          
      <div v-for="option in featureList" :key="option">
        <div v-if="option.align">
          <FeatureDetailRight :config="option" @service="service(option.id)"/>
        </div>

        <div v-else>
          <FeatureDetailLeft :config="option" @service="service(option.id)"/>
        </div>
      </div>

      <!--<SpecialOfferingContainer @contact="contact"/>-->

      <GetStarted :config="getStarted" @contact="contact" @quote="quote"/>

      <div id=pageTop class="e210_1180 noselect" @click="pageTop"></div>
      
  </template>
  
  <script>
  //import { API } from 'aws-amplify';
  
  //import FeatureHighlight from './FeatureHighlight.vue'
  import FeatureDetailLeft from './FeatureDetailLeft.vue'
  import FeatureDetailRight from './FeatureDetailRight.vue'
  //import SpecialOfferingContainer from './SpecialOfferingContainer.vue'
  import GetStarted from './GetStarted.vue'

  
  export default {
    name: 'ServiceDetail',
    emits:["nav", "contact", "quote"],
    components: {

      //FeatureHighlight,
      FeatureDetailRight,
      FeatureDetailLeft,
      //SpecialOfferingContainer,
      GetStarted,

    },
  
    props: {
       config: {
        type: Object
      }
    },
  
    data(){
      return {
        id:0,
        headerBackground: 'white',
        title:'',
        text:'',
        featureList:[],
        data:{color:"#FFFFFF", background:"#F04200", disabled:false, text:"Ask for a quote", width:"208px"},
        getStarted:{
        color:"#FFFFFF", 
        background:"#ffffff", 
        textColor:'#000000',
        ask1:{color:"#ffffff", background:"#F04200", border:"#F04200", disabled:false, text:"Ask for a quote", width:'233px'},
        ask2:{color:"#F04200", background:"#eeeeee", border:"#eeeeee", disabled:false, text:"General Enquiries", width:'233px'},
        },
        subsec:{color:"#F04200", background:"#FFFFFF", textColor:'#000000', hash:require('../assets/hatch_orange.png')},
      }
    },
  
      updated() {
  
    },
  
    computed: {
  
      getImg: function(){
  
          return {
            "background-image":'url(' + encodeURI(this.config.services[this.config.id].img) + ')',
          }
      },
  
      
    },
  
    created(){

      window.addEventListener('scroll', this.handleScroll);
  
      window.scroll({
        top: 0, 
        left: 0, 
        behavior: 'instant'
      });

      this.id = this.config.id;

      this.title = this.config.services[this.config.id].title;

      this.text = this.config.services[this.config.id].text;

      let align = false;

      for(let feature of this.config.services[this.id].features){

        this.featureList.push({id:feature, align:align, feature:this.config.features[feature]});

        align = !align;
      }
    },

    beforeUnmount(){

      window.removeEventListener('scroll', this.handleScroll);
    },
  
    methods: {
  
      nav(item){
  
        this.$emit('nav', item);
      },

      handleScroll(){

        if(window.scrollY > 50) {

          let opacity;

          if(window.innerWidth > 980) opacity = 1.0;
          else opacity = 0.4;

          this.headerBackground = 'rgba(255, 255, 255, ' + opacity + ')';

          document.getElementById("pageTop").style.display = "block";

        } else {

          this.headerBackground = 'transparent';

          document.getElementById("pageTop").style.display = "none";

        }
      },

      contact(){

        this.$emit('contact');
      },

      quote(){

        this.$emit('quote');
      },

      pageTop(){
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
      },
    }
  }
  </script>
  
<style scoped>

.e210_1000{
  width:100%;
  margin:0 auto;
}

.e210_1010{
  height:88px;
  background-color:rgba(255, 255, 255, 1);
}


.e210_1030{
  width:100%;
  height:650px;
  margin:0 auto;
  position:relative;
}

.e210_1035{
  display:none;
  width:100%;
  height:350px;
  background-size:cover;
  background-repeat:no-repeat;
}

.e210_1040{
  display:inline-block;
  width:50%;
  height:100%;
  background-color:rgba(240, 66, 0, 1);
}

.e210_1050{
  display:inline-block;
  width:50%;
  height:100%;
  background-size:cover;
  background-repeat:no-repeat;
}

.e210_1060 { 
  width:64px;
  height:81px;
  position:absolute;
  left:calc(50% - 32px);
  top:107px;
  background-image:url('../assets/hatch.png');
  background-size: contain;
}

.e210_1070 { 
  width:64px;
  height:81px;
  position:absolute;
  left:10%;
  top:580px;
  background-image:url('../assets/hatch.png');
  background-size: contain;
}

.e210_1080 { 
  transform: rotate(-45deg);
  width:150px;
  height:0px;
  position:absolute;
  left:-22px;
  top:140px;
  border-bottom:1px solid rgba(255, 255, 255 ,1);
}

.e210_1090 { 
  transform: rotate(-45deg);
  width:150px;
  height:0px;
  position:absolute;
  left:-22px;
  top:428px;
  border-bottom:1px solid rgba(255, 255, 255 ,1);
}

.e210_1100 { 
  transform: rotate(-45deg);
  width:300px;
  height:0px;
  position:absolute;
  left:calc(100% - 261px);
  top:107px;
  border-bottom:1px solid rgba(255, 255, 255 ,1);
}

.e210_1110 { 
  transform: rotate(-45deg);
  width:250px;
  height:0px;
  position:absolute;
  left:calc(100% - 210px);
  top:363px;
  border-bottom:1px solid rgba(255, 255, 255 ,1);
}

.e210_1120 { 
  transform: rotate(-45deg);
  width:450px;
  height:0px;
  position:absolute;
  left:calc(100% - 470px);
  top:478px;
  border-bottom:1px solid rgba(255, 255, 255 ,1);
}


.e210_1130{
  position:absolute;
  top:230px;
  left:calc((50% - 555px)/2);
  width:555px;
  color:rgba(255, 255, 255, 1);
  font-family:'Open Sans';
  font-weight:700;
}

.e210_1140{
  margin:0 auto;
  width:100%;
  font-size:48px;
  text-shadow: 0 0 4px rgba(0, 0, 0 , 0.4);
}

.e210_1150{
  margin:16px auto 0 auto;
  width:100%;
  font-size:20px;
}

.e210_1180 { 
  background-color:rgba(240, 66, 0, 1);
  border-radius:6px;
	width:60px;
	height:60px;
	position:fixed;
	top: 50%;
  left: calc(100% - 30px);
  /* bring your own prefixes */
  transform: translate(-50%, -100%);
  background-image:url('../assets/arrowUp.png');
  background-size:center;
  background-repeat:no-repeat;
  cursor:pointer;
  opacity:0.6;
  z-index:5000;
}

@media only screen and (max-width: 600px) {

  .e213_1010{
    width:420px;
  }

  .e210_1035{
    display:block;
    width:100%;
    height:350px;
  }

  .e210_1050{
    display:none;
  }

  .e210_1030{
    height:750px;
  }
  .e210_1040{
    display:block;
    width:100%;
    height:350px;
  }

  .e210_1130{
    position:absolute;
    top:390px;
    left:calc((100% - 350px)/2);
    width:350px;
    color:rgba(255, 255, 255, 1);
    font-family:'Open Sans';
    font-weight:700;
  }

  .e210_1140{
    margin:0 auto;
    width:100%;
    font-size:32px;
    text-shadow: 0 0 4px rgba(0, 0, 0 , 0.4);
  }

  .e210_1150{
    font-size:16px;
    font-weight:400;
  }

  .e210_1060 { 
    width:30px;
    height:81px;
    position:absolute;
    left:calc(100% - 102px);
    top:310px;
    background-image:url('../assets/hatch.png');
    background-size: contain;
  }

  .e210_1070 { 
    width:30px;
    height:81px;
    position:absolute;
    left:10%;
    top:622px;
    background-image:url('../assets/hatch.png');
    background-size: contain;
  }

  .e210_1080 { 
    display:none;
  }

  .e210_1100 { 
    display:none;
  }

  .e210_1110 { 
    transform: rotate(-45deg);
    width:250px;
    height:0px;
    position:absolute;
    left:calc(100% - 210px);
    top:463px;
    border-bottom:1px solid rgba(255, 255, 255 ,1);
  }

  .e210_1120 { 
    transform: rotate(-45deg);
    width:350px;
    height:0px;
    position:absolute;
    left:calc(100% - 370px);
    top:520px;
    border-bottom:1px solid rgba(255, 255, 255 ,1);
  }

}

@media only screen and (min-width: 600px) {

  .e213_1010{
    width:100%;
  }

  .e210_1035{
    display:block;
    width:100%;
    height:550px;
  }

  .e210_1050{
    display:none;
  }

  .e210_1030{
    height:900px;
  }
  .e210_1040{
    display:block;
    width:100%;
    height:350px;
  }

  .e210_1130{
    position:absolute;
    top:590px;
    left:calc((100% - 350px)/2);
    width:350px;
    color:rgba(255, 255, 255, 1);
    font-family:'Open Sans';
    font-weight:700;
  }

  .e210_1140{
    margin:0 auto;
    width:100%;
    font-size:32px;
    text-shadow: 0 0 4px rgba(0, 0, 0 , 0.4);
  }

  .e210_1150{
    font-size:16px;
    font-weight:400;
  }

  .e210_1060 { 
    width:30px;
    height:81px;
    position:absolute;
    left:calc(100% - 102px);
    top:510px;
    background-image:url('../assets/hatch.png');
    background-size: contain;
  }

  .e210_1070 { 
    width:30px;
    height:81px;
    position:absolute;
    left:10%;
    top:822px;
    background-image:url('../assets/hatch.png');
    background-size: contain;
  }

  .e210_1080 { 
    display:none;
  }

  .e210_1100 { 
    display:none;
  }

  .e210_1110 { 
    transform: rotate(-45deg);
    width:250px;
    height:0px;
    position:absolute;
    left:calc(100% - 210px);
    top:763px;
    border-bottom:1px solid rgba(255, 255, 255 ,1);
  }

  .e210_1120 { 
    transform: rotate(-45deg);
    width:250px;
    height:0px;
    position:absolute;
    left:calc(100% - 270px);
    top:678px;
    border-bottom:1px solid rgba(255, 255, 255 ,1);
  }

  
  .e210_1090 { 
    transform: rotate(-45deg);
    width:150px;
    height:0px;
    position:absolute;
    left:-22px;
    top:628px;
    border-bottom:1px solid rgba(255, 255, 255 ,1);
  }
  
}

@media only screen and (min-width: 1200px) {

  .e213_1010{
    width:100%;
  }

  .e210_1035{
    display:none;
    width:100%;
    height:350px;
  }


  .e210_1050{
    display:inline-block;
  }

  .e210_1030{
    height:650px;
  }

  .e210_1035{
    display:none;
  }

  .e210_1040{
    display:inline-block;
    width:50%;
    height:100%;
  }

  .e210_1130{
    top:230px;
    left:calc((50% - 555px)/2);
    width:555px;
  }

  .e210_1140{
    font-size:48px;
  }

  .e210_1150{
    font-size:20px;
    font-weight:400;
  }

  .e210_1060 { 
    width:64px;
    left:calc(50% - 32px);
    top:107px;
  }

  .e210_1070 { 
    width:64px;
    height:81px;
    left:10%;
    top:580px;
  }

  .e210_1080 { 
    display:block;
  }

  .e210_1100 { 
    display:block;
  }

  .e210_1110 { 
    top:363px;
  }

  .e210_1120 { 
    width:450px;
    top:478px;
  }

  .e210_1090 { 
    top:428px;
  }

}
  
  </style>