<template>
    <div id="map" class="e650_1000">

    </div>
</template>

<script>

import { Loader } from '@googlemaps/js-api-loader';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

const axios = require("axios");

const loader = new Loader({
  apiKey: "AIzaSyA45jd4KEQSnivdctilskZj-Ix0yn0_OmY",
  version: "weekly",
  libraries: ["places"]
});

export default {
  name: 'MapView',
  emits: ["metar"],

  components: {

  },

  props: {
    config:{
        type:Object,
    }
  },

  data(){
        return {
            mouseDown:false,
            centerChanged:false,
        }
  },

  created(){


  },

mounted(){
   
    let map, geocoder;

    let gmarkers = [];

    let _this = this;

    if(this.config.id) {

        let latlng = this.latlngFromString();

        const mapOptions = {
            center: {
                lat: parseFloat(latlng.lat),
                lng: parseFloat(latlng.lng)
            },
            zoom: 18
            }

        loader
            .load()
            .then((google) => {

                map = new google.maps.Map(document.getElementById("map"), mapOptions);

                geocoder = new google.maps.Geocoder();

                var marker = new google.maps.Marker({position: mapOptions.center});

                marker.setMap(map);

                gmarkers.push(marker);

                var center = map.getCenter();

                let loc = _this.latlngToString(center);
                        
                document.getElementById("lat").value = loc.lat;
                document.getElementById("long").value = loc.lng;
            })
            .catch(e => {
                console.log("error:" + e)
            });

    }else {

        let options = {
            method: 'GET',
            url: 'https://api.ipify.org?format=json'
        };

        axios.request(options).then(function (response) {

            //console.log(response.data.ip);

            options = {
                method: 'GET',
                url: 'https://api.ipgeolocation.io/ipgeo?apiKey=62fd5e4e87034c7881177fff34a2e923&ip=' + response.data.ip + '&fields=geo',
            };

            axios.request(options).then(function (response) {

                //console.log(response.data);
                //console.log(response.data.latitude);
                //console.log(response.data.longitude);

                const mapOptions = {
                        center: {
                            lat: parseFloat(response.data.latitude),
                            lng: parseFloat(response.data.longitude)
                        },
                        zoom: 12
                        }

                loader
                    .load()
                    .then((google) => {

                        map = new google.maps.Map(document.getElementById("map"), mapOptions);

                        geocoder = new google.maps.Geocoder();

                        var marker = new google.maps.Marker({position: mapOptions.center});

                        marker.setMap(map);

                        gmarkers.push(marker);

                        var center = map.getCenter();

                        let loc = _this.latlngToString(center);
                                
                        document.getElementById("lat").value = loc.lat;
                        document.getElementById("long").value = loc.lng;

                        google.maps.event.addListener(marker,'click',function() {
                            map.setZoom(9);
                            map.setCenter(marker.getPosition());
                        });

                        map.addListener("center_changed", function() {
                                
                            var center = map.getCenter();

                            marker.setPosition(center);

                            let loc = _this.latlngToString(center);
                            
                            document.getElementById("lat").value = loc.lat;
                            document.getElementById("long").value = loc.lng;

                            this.centerChanged = true;          
                        });

                        map.addListener("mouseup", function() {

                            this.mouseDown = false;

                            if(this.centerChanged){

                                this.centerChanged = false;

                                geocoder.geocode({

                                    'latLng': map.getCenter()

                                }, function (results, status) {

                                    if (status === google.maps.GeocoderStatus.OK) {

                                        if (results[1]) {

                                            //console.log(results[1]);

                                            document.getElementById("loc").value = results[1].formatted_address;

                                            var elevationService = new google.maps.ElevationService();

                                            var requestElevation = {

                                                'locations': [gmarkers[0].getPosition()]
                                            };

                                            elevationService.getElevationForLocations(requestElevation, function(results, status) {

                                                if (status == google.maps.ElevationStatus.OK) {

                                                    if (results[0]) {

                                                        let elevation = parseFloat(results[0].elevation);

                                                        let m = elevation.toFixed(1) + " m / ";

                                                        let ft = (elevation * 3.28084).toFixed(1) + " ft";

                                                        document.getElementById('alt').value = m + ft;
                                                    }
                                                }
                                            });

                                        } else {

                                            console.log('No results found');
                                        }
                                    }
                                });
                            }
                        });

                        map.addListener("mousedown", function() {
                            this.mouseDown = true;
                        });
                    })
                    .catch(e => {
                        console.log("error:" + e)
                    });

                
            });
        });

        this.getWeatherReport();
    }
},

methods: {

    latlngFromString(){

        let lat, lng;

        let n = this.config.latitude.length;

        lat = parseFloat(this.config.latitude.substring(2, n - 1));

        if(this.config.latitude.substring(0, 1) === 'S') lat *= -1;

        n = this.config.longitude.length;

        lng = parseFloat(this.config.longitude.substring(2, n - 1));

        if(this.config.longitude.substring(0, 1) === 'W') lng *= -1;

        return {lat:lat, lng:lng};
    },

    latlngToString(location){

        let lat, lng;

        if(location.lat() > 0){

            lat = 'N ' + location.lat().toFixed(3) + String.fromCharCode(176);

        } else {

            lat = 'S ' + location.lat().toFixed(3) + String.fromCharCode(176);
        }

        if(location.lng() > 0){

            lng = 'E ' + location.lng().toFixed(3) + String.fromCharCode(176);

        } else {

            lng = 'W ' + location.lng().toFixed(3) + String.fromCharCode(176);
        }

        return {lat:lat, lng:lng};
    },

    async getWeatherReport(){

        // https://en.allmetsat.com/metar-taf/singapore.php?icao=WSSS
        const apiName = 'dtApiGateway';
        const path = '/weather';

        let user = await Auth.currentAuthenticatedUser();

        const myInit = {
            body: {
            
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        let res = await API.post(apiName, path, myInit);

        this.$emit('metar', res);
    },

  }
}

</script>

<style scoped>

.e650_1000{
    width:100%;
    height:100%;
}
</style>