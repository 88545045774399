<template>
	<div class=e500_1000>
		<div  class="e500_1010">
			<div class=e500_1020>

				<div class=e500_1030>
					<div  class="e500_1040"></div>
					<div class=e500_1050>
						<span  class="e500_1060">How We Operate </span>
						<div class=e500_1070>
							<div class=e500_1080>
							<span  class="e500_1090">Drive decision making using drone-based sensing solutions</span>
							<span  class="e500_1100">
								<p>We provide drone-based sensing solutions in order to capture, analyse and report data and insights for the owners and operators of industrial, commercial and residential built-environment, including real estate dispute resolution.</p>
								<p>Data captured from our drones is processed and, with our client's approval, stored in our secure cloud portal for visualisation. Our visualisation engine uses machine learning to detect common defects such as cracks, mold, rust and water damage and also allows image data to be overlayed with both sensor data and client annotations.</p>
								<p>These image composites can be downloaded or included in automated reports that can be used for either contractor tendering, litigation or historical documentation.</p></span>
							</div>
						</div>
					</div>
				</div>
				<div class=e500_1110>
					<div class=e500_1120>
						<div class=e500_1130>
							<div  class="e500_1140"></div>
							<div class=e500_1150>
								<div class=e500_1160>
									<div  class="e500_1170"></div>
								</div>
							</div>
							<div  class="e500_1180"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
//import Button from './LoginButton.vue'

export default {
  name: 'OfferingRight',
  emits: [],

  components: {
    //Button
  },

  props: {

  },

  methods: {

  }
}
</script>

<style scoped>
.e500_1000 { 
	width:100%;
	height:600px;
	position:relative;
}
.e500_1010 { 
	background-color:rgba(255, 255, 255, 1);
	height:600px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.e500_1020 { 
	height:490px;
	position:absolute;
	left:112px;
	top:85px;
}

.e500_1030 { 
	background-color:rgba(255, 255, 255, 1);
	width:237px;
	height:196px;
	position:absolute;
	left:980px;
	top:245px;
}

.e500_1030 { 
	width:640px;
	height:490px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1040 { 
	border-radius:500px;
	background-color:rgba(240, 66, 0, 1);
	width:28px;
	height:28px;
	position:absolute;
	left:0px;
	top:10px;
}
.e500_1050 { 
	width:602px;
	height:490.0234375px;
	position:absolute;
	left:38.06640625px;
	top:0px;
}
.e500_1060 { 
	color:rgba(240, 66, 0, 1);
	width:525px;
	height:32px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:32px;
	letter-spacing:0;
	line-height:px;
}
.e500_1070 { 
	width:602px;
	height:457px;
	position:absolute;
	left:0px;
	top:32px;
}
.e500_1080 { 
	width:602px;
	height:457px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1090 { 
	color:rgba(0, 0, 0, 1);
	width:536;
	height:79;
	position:absolute;
	left:0px;
	top:10px;
	font-family:'Open Sans';
	text-align:left;
	font-size:24px;
	letter-spacing:0;
	line-height:px;
}
.e500_1100 { 
	color:rgba(51, 51, 51, 1);
	width:602px;
	height:373px;
	position:absolute;
	left:0px;
	top:85px;
	font-family:'Open Sans';
	text-align:left;
	font-size:18px;
	letter-spacing:0;
}
.e500_1110 { 
	width:518.279296875px;
	height:443.7353515625px;
	position:absolute;
	left:704.06640625px;
	top:0px;
}
.e500_1120 { 
	width:518.279296875px;
	height:443.7353515625px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1130 { 
	width:518.279296875px;
	height:443.7353515625px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1140 { 
	background-color:rgba(240, 66, 0, 1);
	width:418.3981018066406px;
	height:387.921630859375px;
	position:absolute;
	left:13.7041015625px;
	top:39px;
}
.e500_1150 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1160 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
}
.e500_1170 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:16px;
	border-top-right-radius:16px;
	border-bottom-left-radius:16px;
	border-bottom-right-radius:16px;
	background-image:url(../assets/operate.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e500_1180 { 
	background-image:url(../assets/hatch.png);
    background-size:contain;
	width:237px;
	height:196px;
	position:absolute;
	left:280px;
	top:248px;
}

@media only screen and (max-width: 600px) {

	.e500_1000 { 
		height:920px;
	}

	.e500_1010 { 
		height:600px;
		left:calc((100% - 420px)/2);
		top:0px;
	}

	.e500_1020 { 
		left:35px;
		top:25px;
	}

	.e500_1040 { 
		width:20px;
		height:20px;
		left:4px;
		top:14px;
	}

	.e500_1060 { 
		font-weight:600;
	}

	.e500_1090 { 
		width:350px;
		left:-35px;
		top:20px;
		line-height:1.3em;
		font-weight:bold;
		font-size:20px;
	}

	.e500_1100 { 
		width:340px;
		height:373px;
		left:-35px;
		top:85px;
		font-size:14px;
	}

	.e500_1110 { 
		width:420px;
		height:400px;
		left:0;
		top:510px;
	}

	.e500_1170 { 
		width:320px;
		height:320px;
	}

	.e500_1140 { 
		width:340px;
		height:310px;
	}

	.e500_1180 { 
		left:230px;
		top:160px;
	}
}

@media only screen and (min-width: 600px) {
	.e500_1000 { 
		height:920px;
	}

	.e500_1010 { 
		height:600px;
		left:calc((100% - 600px)/2);
		top:0px;
	}

	.e500_1020 { 
		height:490px;
		width:600px;
		position:absolute;
		left:50px;
		top:45px;
	}

	.e500_1060 { 
		font-weight:700;
	}

	.e500_1090 { 
		width:500px;
		left:-35px;
		top:20px;
	}

	.e500_1100 { 
		width:500px;
		height:373px;
		left:-35px;
		top:85px;
		font-family:'Open Sans';
		text-align:left;
		font-size:14px;
		letter-spacing:0;
	}

	.e500_1110 { 
		width:355px;
		height:350px;
		left:calc((100% - 500px));
		top:450px;
	}

	.e500_1120 { 
		width:355px;
		height:350px;
		left:0px;
		top:0px;
	}

	.e500_1130 { 
		width:355px;
		height:350px;
		left:0px;
		top:0px;
	}

	.e500_1160 { 
		width:355px;
		height:350px;
		left:0px;
		top:0px;
	}

	.e500_1170 { 
		width:320px;
		height:320px;
	}

	.e500_1140 { 
		width:340px;
		height:310px;
	}

	.e500_1180 { 
		left:230px;
		top:160px;
	}
}

@media only screen and (min-width: 1200px) {
	.e500_1000 { 
		height:600px;
	}
	.e500_1010 { 
		left:calc((100% - 1440px)/2);
	}
	.e500_1020 { 
		left:112px;
		top:85px;
	}

	.e500_1060 { 
		font-weight:700;
	}

	.e500_1090 { 
		width:537px;
		left:0px;
		top:10px;
	}
	.e500_1100 { 
		width:602px;
		height:373px;
		left:0px;
		top:85px;
		font-size:18px;
	}
	.e500_1110 { 
		width:518px;
		height:444px;
		left:704px;
		top:0px;
	}

	.e500_1140 { 
		width:418px;
		height:388px;
	}
	.e500_1170 { 
		width:400px;
		height:400px;
	}
	.e500_1180 { 
		left:280px;
		top:248px;
	}
}
</style>