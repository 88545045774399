<template>

    <div class="e340_1005">

        <div class="e340_1020">Quotation Request</div>

        <div class="e340_1030">
            <div class="e340_1040">
                <InputField :config="nameConfig" @verify="nameVerify" />
            </div>
            <div class="e340_1045"></div>
            <div class="e340_1040">
                <InputField :config="emailConfig" @verify="emailVerify"/>
            </div>
        </div>

        <div class="e340_1030">
            <div class="e340_1040">
                <InputField :config="subjectConfig" @verify="subjectVerify"/>
            </div>
            <div class="e340_1045"></div>
            <div class="e340_1040">
                <!--<SelectField :config="enquiryConfig" @verify="enquiryVerify"/>-->
                <InputField :config="industryConfig" @verify="industryVerify"/>
            </div>
        </div>

        <div id="spin" class="e340_1035">

            <TextField :config="textConfig" @verify="textVerify"/>

        </div>
        
        <div class="e340_1060">
            <ReCaptcha @verify="captchaVerify" />

            <div class="e340_1070">
                <ButtonActive :config="submit" :key="submit" @click="quotation" />
            </div>
        </div>

    </div>
        
</template>

<script>
import ButtonActive from './ButtonActive.vue'
import InputField from './InputField.vue'
import TextField from './TextField.vue'
//import SelectField from './SelectField.vue'
import ReCaptcha from './ReCaptcha.vue'
import { Spinner } from 'spin.js';
import 'spin.js/spin.css';
import { API } from 'aws-amplify';
import { format } from 'date-fns'
import { ms } from 'date-fns/locale'

export default {
    name: 'QuotationPage',
    emits: [],
    components: {
        ButtonActive,
        InputField,
        TextField,
        //SelectField,
        ReCaptcha,
    },


    props: {
        config:{
            type:Object,
        }
    },

    data(){
            return {
                captchaState:false,
                emailState:false,
                nameState:false,
                subjectState:false,
                enquiryState:true,
                industryState:false,
                requestState:true,
                textState:false,
                
                email:'',
                name:'',
                subject:'',
                enquiry:'',
                industry:'',
                request:'',
                text:'',

                headerBackground: 'white',
                nameConfig:{id:"name", type:"input", regx:this._NAME, name:"name", title:"Name", placeholder:"", required:true, dataTest:""},
                emailConfig:{id:"email", type:"input", regx:this._EMAIL, name:"email", title:"Email", placeholder:"", required:true, dataTest:""},
                subjectConfig:{id:"subject", type:"input", regx:this._NAME, name:"subject", title:"Subject", placeholder:"", required:true, dataTest:""},
                enquiryConfig:{id:"enquiry", name:"enquiry", title:"Enquiry Type", dataTest:"", options: [
                    { text: 'General Enquiry', value: '0' },
                    { text: 'Media Enquiry', value: '1' },
                    { text: 'Investor Enquiry', value: '3' }
                ],},
                industryConfig:{id:"industry", type:"input", regx:this._NAME, name:"industry", title:"Request Industry", placeholder:"", required:false, dataTest:""},
                serviceOpt:{id:"service", name:"service", title:"Request Service",  dataTest:"", options: [
                    { text: 'Arrange a Meeting', value: '0' },
                    { text: 'Facade Inspection', value: '1' },
                    { text: 'Gas Concentration Measurement', value: '2' },
                    { text: 'Oil Tank Inspection', value: '3' }
                ],},
                textConfig:{id:"text", type:"input", name:"text", disabled:false, title:"Request", placeholder:"", rows:5, required:true, dataTest:""},

                submit:null,
                submitDisabled:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:true, highlightBorder:"#F04200", opacity:"0.4", text:"Submit", width:"140px"},
                submitReady:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Submit", width:"140px"},
            }
    },

    created(){

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });

        this.submit = this.submitDisabled
    },

    methods: {

        captchaVerify(result){

            this.captchaState = result;

            this.checkReady();
        },

        nameVerify(result){

            this.nameState = result.state;

            if(result.state) this.name = result.value;

            this.checkReady();
        },

        emailVerify(result){

            this.emailState = result.state;

            if(result.state) this.email = result.value;

            this.checkReady();
        },

        subjectVerify(result){

            this.subjectState = result.state;

            if(result.state) this.subject = result.value;

            this.checkReady();
        },

        enquiryVerify(result){

            this.enquiryState = result.state;

            if(result.state) this.enquiry = result.value;

            this.checkReady();
        },

        industryVerify(result){

            this.industryState = result.state;

            if(result.state) this.industry = result.value;

            this.checkReady();
        },

        requestVerify(result){

            this.requestState = result.state;

            if(result.state) this.request = result.value;

            this.checkReady();
        },

        textVerify(result){

            this.textState = result.state;

            if(result.state) this.message = result.value;

            this.checkReady();
        },

        checkReady(){

            //console.log("captchaState:" + this.captchaState + " nameState:" +  this.nameState + " emailState:" + this.emailState + " subjectState:" + this.subjectState + " enquiryState:" + this.enquiryState + " industryState:" + this.industryState + " requestState:" + this.requestState + " textState:" + this.textState);

            if(this.captchaState && this.nameState && this.emailState && this.subjectState && this.enquiryState && this.industryState && this.requestState && this.textState){

                this.submit = this.submitReady;
            }
        },

        async quotation(){

            const apiName = 'dtApiGateway';
            const path = '/quotation';
            /*const myInit = {
            body: {},
            headers: {
                "x-api-key":"CAeQNg9OQI8FARgTYX2GWaiPkRczKiN9785jYyn4"
            }
            };*/

            //let user = await Auth.currentAuthenticatedUser()

            //console.log("USER", user.signInUserSession.idToken.jwtToken);

            const myInit = {
                body: {
                    "date": format(new Date(), 'dd/MM/yy hh:mm:ss', { locale: ms }),
                    "name":this.name, 
                    "email":this.email, 
                    "subject":this.subject, 
                    "enquiry":this.enquiry, 
                    "industry":this.industry, 
                    "request":this.request, 
                    "message":this.message
                },
                headers: {
                    //"Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            this.submit = this.submitDisabled;

            await API.post(apiName, path, myInit);

            spinner.stop();

            this.$emit('quotation');
        }
    }
}

</script>

<style scoped>

.e340_1005{
    width:80%;
    min-width:380px;
    margin:100px auto 0 auto;
}

.e340_1010{
    width:100%;
    margin:0 auto;
    position:relative;
    height:120px;
}

.e340_1020{
    display:inline-block;
    margin:30px auto 30px auto;
    color:rgba(28, 28, 28, 1);
    font-family:'Open Sans';
    font-size:32px;
    font-weight:700;
}

.e340_1030{
    width:80%;
    display:block;
    position:relative;
    margin:0 auto;
    height:100px;
}

.e340_1035{
    width:80%;
    display:block;
    position:relative;
    margin:20px auto 0 auto;
    height:220px;
}

.e340_1060{
    width:80%;
    position:relative;
    margin:0 auto;
    height:220px;
}

.e340_1040{
    display:inline-block;
    width:45%;
}

.e340_1045{
    display:inline-block;
    width:10%;
}

.e340_1050{
    display:block;
    width:100%;
}

.e340_1055{
    display:block;
    width:100%;
}

.e340_1070{
    width:140px;
    margin:20px auto;
}

.e340_1080{
    width:100%;
    margin:0 auto;
}

@media only screen and (max-width:600px) {

    .e340_1030{
        height:220px;
    }

    .e340_1040{
        width:100%;
        height:100px;
    }

    .e340_1045{
        display:none;
    }
}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>