<template>

    <div class=e450_1000>
        
        <div class=e450_1020></div>

        <div class=e450_1030>

            <div class=e450_1035>

                <div class=e450_1040>

                    <Authenticator :social-providers="['amazon', 'apple', 'google']" initial-state="signUp" :services="services" >

                        <template v-slot:sign-up-header>
                            <h3
                                class="amplify-heading"
                                style="padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl)"
                            >
                                Sign up
                            </h3>
                        </template>

                        <template v-slot:sign-up-fields>
                            <AuthenticatorSignUpFormFields />
                            <AmplifyCheckBox 
                                :errorMessage="validationErrors.acknowledgement"
                                label="terms"
                                checked="true"
                            />
                            
                        </template>

                        <!--<template v-slot="{ user, signOut }">
                            <h1>Hello {{ user.username }}!</h1>
                            <button @click="signOut">Sign Out</button>
                        </template>-->

                    </Authenticator >
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { onAuthUIStateChange  } from '@aws-amplify/ui-components';
import { Authenticator, AuthenticatorSignUpFormFields, useAuthenticator, AmplifyCheckBox } from '@aws-amplify/ui-vue';
import "@aws-amplify/ui-vue/styles.css";

import Amplify, { API, Hub } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);
API.configure(awsconfig);

import { toRefs } from 'vue';



export default {
    name: 'SignUp',
    emits: [],

    components: {
        Authenticator,
        AuthenticatorSignUpFormFields,
        AmplifyCheckBox
    },


    props: {
        config:{
            type:Object,
        }
    },

    created() {
        this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            this.user = authData;
        });

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });

        Hub.listen('auth', (data) => {
            const { payload } = data;
            this.onAuthEvent(payload);           
            //console.log('A new auth event has happened: ', data.payload.data.username + ' has ' + data.payload.event);
        })
    },

    updated() {

    },

    data(){
        return {
            headerBackground:'white',
            email:{id:"email", type:"input", name:"email", title:"Email", placeholder:"", dataTest:""},
            password:{id:"password", type:"password", name:"password", title:"Password", placeholder:"", dataTest:""},
            
            submit:{color:"#FFFFFF", background:"#F04200", opacity:"1", text:"Submit", width:"132px"},

            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined,
            validationErrors: toRefs(useAuthenticator()),
            services: {

                /*async handleSignUp(formData) {

                    let { username, password, attributes } = formData;

                    // custom username
                    username = username.toLowerCase();

                    attributes.email = attributes.email.toLowerCase();

                    return Auth.signUp({
                        username,
                        password,
                        attributes,
                        autoSignIn: {
                            enabled: true,
                        },
                    });
                },*/

                async validateCustomSignUp(formData) {

                    if (!formData.acknowledgement) {

                   
                        return {
                            acknowledgement: 'You must agree to the Terms & Conditions',
                        };
                    }
                },
            }
        }
    },

    beforeUnmount() {

        this.unsubscribeAuth();
    },

    methods: {

        onAuthEvent(payload) {
            // ... your implementation
            //console.log(payload);
            
            if(payload.event === 'confirmSignUp'){

                //console.log("confirmSignUp");
            }

            else if(payload.event === 'signIn'){

                //console.log("signIn");

                this.$emit('login');
            }
        },
    }
}

</script>

<style scoped>

h3{
  font-weight:800;
  font-family:'Open Sans';
  font-size:36px;
}


.e450_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e450_1010{
    width:100%;
    margin:0 auto;
    position:relative;
    height:88px;
}

.e450_1020 {
    width:100%;
    height:750px;
    background-image:url("../assets/m30Tpic.png");
    background-repeat:no-repeat;
    background-size:cover;
}

.e450_1030{
    width:100%;
    position:absolute;
    top:140px;
}

.e450_1035{
    z-index:4;
    width:90%;
    max-width:496px;
    position:relative;
    margin: auto;
    font-family:'Open Sans';
    text-align:left;
    font-size:20px;
    border-radius:16px;
    background-color: rgba(255, 255, 255, 1);
}

.e450_1040{
    width:100%;
    padding:6px;
}

@media only screen and (max-width: 600px) {

}

</style>