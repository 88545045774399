import { createApp } from 'vue'
import App from './App.vue'
import Amplify, {Auth, Storage} from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

/*Amplify.configure({
  // OPTIONAL - if your API requires authentication 
  Auth: {
    
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'ap-southeast-1:4275fd13-ea61-4958-a2e2-77fd76207ed7',
      // REQUIRED - Amazon Cognito Region
      region: 'ap-southeast-1', 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-southeast-1_lbO166HGP', 
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '3qu24prdchtt0vkqp2it6ed4g',
      oauth: {}
  },
  API: {
      endpoints: [
          {
              name: "test",
              endpoint: " https://e0ezv56gze.execute-api.ap-southeast-1.amazonaws.com/dev",
              region: "ap-southeast-1"
          }
      ]
  },
  Storage: {
        AWSS3: {
            bucket: 'dtweb-storage-22ce1749111633-dev', //REQUIRED -  Amazon S3 bucket name
            region: 'ap-southeast-1', //OPTIONAL -  Amazon service region
        }
    }
});*/

let app = createApp(App);

app.config.globalProperties._EMAIL = 2;
app.config.globalProperties._NAME = 4;
app.config.globalProperties._DATE = 8;
app.config.globalProperties._LOCATION = 16;
app.config.globalProperties._FLIGHT_DASHBOARD = 1;
app.config.globalProperties.flightInProgress = false;

app.config.globalProperties.graphColors = ["#3A5F81", "#EDD667", "#8FAA58", "#8C6AA2", "#69A9D3", "#FF9300", "#0096FF", "#FF40FF", "#D6D6D6"],

app.config.globalProperties.flightDataTemplate = {
    checklist:0, 
    date_created:'', 
    id:0, 
    latitude:0, 
    location:'', 
    longitude:0, 
    metar:'', 
    notes:'', 
    taf:'', 
    user_end:'', 
    user_start:'', 
    windspeed:0,
    duration:0,
    project:'',
    company:0,
    type:0
}

// rename for contracts
/*app.config.globalProperties.flightDataTemplate = {
    title:'',
    description:'',
    deliver:'',
    terms:'',
    propStart:'',
    propEnd:'',
    awardDate:'',
    awardTo:'',
    value:0,
    start:'',
    end:''
}*/

app.config.globalProperties.spinnerOpts = {
    lines: 10, // The number of lines to draw
    length: 10, // The length of each line
    width: 10, // The line thickness
    radius: 25, // The radius of the inner circle
    scale: 0.5, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    color: '#F04200', // CSS color or array of colors
    fadeColor: 'transparent', // CSS color or array of colors
    top: '50%', // Top position relative to parent
    left: '50%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    zIndex: 2000000000, // The z-index (defaults to 2e9)
    className: 'spinner', // The CSS class to assign to the spinner
    position: 'absolute', // Element positioning
};

app.config.globalProperties._RECREATION = 1;
app.config.globalProperties._EDUCATION = 2;
app.config.globalProperties._CONTRACT = 3;
app.config.globalProperties._TRAINING = 4;

app.config.globalProperties._DESIGN = 1;
app.config.globalProperties._BUDGET_APPROVAL = 2;
app.config.globalProperties._VENDORS = 3;
app.config.globalProperties._SELECTION = 4;
app.config.globalProperties._EXTERNAL_APPROVAL = 5;
app.config.globalProperties._IN_PROGRESS = 6;
app.config.globalProperties._AWAITING_DELIVERABLES = 7;
app.config.globalProperties._COMPLETED = 8;

app.config.globalProperties.tagVals = [
    {n:0, text:'Conflict', words:['bombed', 'assault', 'attack']},
    {n:1, text:'Military', words:['army ', 'navy ', 'weapons', 'lethal', 'wargame', 'war game', 'war-game', 'manoeuvres', 'joint exercise', 'military']},
    {n:2, text:'Search & Rescue', words:['rescued', 'rescue']},
    {n:3, text:'Oil & Gas', words:['oil', 'pipeline', 'refinery']},
    {n:4, text:'Inspection & Survey', words:['inspection', 'survey']},
    {n:5, text:'Agriculture', words:['farmers', 'crop',' ag ']},
    {n:6, text:'Police', words:['police', 'suspect', 'caught', 'apprehended', 'correctional', 'drugs', 'controlled substance']},
    {n:7, text:'Technology', words:['advanced', 'fully integrated', 'compact', 'key insights', 'analysis', 'programme ', 'research ', 'R&D', 'R & D']},
    {n:8, text:'Education', words:['learning experience', 'students', 'school', 'college']},
    {n:9, text:'Companies', words:['Limited']},
    {n:10, text:'Delivery', words:['air cargo', ' delivery ', ' drone carrying ']},
    {n:11, text:'Legislation', words:['recently pass', 'legislation', 'illegal to']},
    {n:12, text:'Ukraine', words:['ukrain', 'kyiv']},
    {n:13, text:'United States', words:['Department of Defense', ' walmart ', ' dod ', 'verizon', 'washington', 'us army', 'ohio', 'colarado', 'california']},
    {n:14, text:'Europe', words:['germany', 'uk', 'france', 'italy', 'spain', 'netherland', 'romania', 'austria', 'switzerland']},
    {n:15, text:'North Korea', words:['north korea']},
    {n:16, text:'China', words:['china']},
    {n:17, text:'India', words:['india']},
    {n:18, text:'Middle East', words:['afghan', 'syria','kuwait','saudi arabia', 'lebanon', 'damascus', 'iraq']},
    {n:19, text:'Counter-UAV', words:['counter uav', 'counter-uav', 'cuas', 'counter drone', 'counter-drone', 'm-lids', 'mlids']},
    {n:20, text:'Analysis', words:['analysis']},
    {n:21, text:'Israel', words:['israel', 'jerusalem']},
    {n:22, text:'Russia', words:['moscow', 'russia']},
    {n:23, text:'Taiwan', words:['taiwan', 'taipei']},
    {n:24, text:'Sensors', words:['cmos', 'lidar', 'infrared']},
    {n:25, text:'Entertainment', words:['firework', 'crowds']},
    {n:26, text:'Asia', words:['thailand', 'singapore', 'laos', 'vietnam', 'cambodia', 'china', 'malaysia', 'indonesia', 'borneo']},
    {n:27, text:'Environment', words:['ecosystem', 'coastline', 'whale', 'shark', 'storm', 'tornado', 'flood', 'pollution', 'firefight', 'plastic waste', 'cliff', 'raptor']},
    {n:28, text:'Iran', words:['qods', 'iran']},
    {n:29, text:'Border Patrol', words:['border', 'border-patrol', 'border patrol']},
    {n:30, text:'Turkey', words:['turkish', 'baykar', 'trkiye', 'turkey', 'türkiye']},
    {n:31, text:'BVLOS', words:['bvlos']},
    {n:32, text:'Insurance', words:['insurance']},
    {n:33, text:'Photography', words:['photography', 'image']},
    {n:34, text:'Government', words:[]},
    {n:35, text:'Global', words:['global', 'worldwide']},
    {n:36, text:'Crash Investigation', words:['crashed', 'crash investigation']},
    {n:37, text:'Investment', words:['series a', 'series b', 'series c', 'startup', 'start-up', 'seed funding']},
    {n:38, text:'Racing', words:['fpv', 'drone racing']},
]

app.component(Auth, 'Auth');

app.mount("#app");

//API.configure();
Storage.configure({
    
    customPrefix: {
        public: '',
        protected: '',
        private: ''
    } 
});
