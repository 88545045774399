<template>
    <div :id="containerId" class="e010_1000 noselect" @dblclick="newImage" @mouseup="mouseup" @mousedown="mousedown" @mousemove="mousemove" @mousewheel="mousewheel">

        <input v-if="config.edit" @change="fileChanged" class="e010_1020" type=file :id="id" accept="image/png, image/gif, image/jpeg" />

        <canvas :id="cvasId" :width="cwidth" :height="cheight" class="e010_1010 noselect" ></canvas>
    </div>

</template>

<script>

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

export default {
  name: "PhotoEditor",
  emits: ["imageChange"],

  components: {
      //Button
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            ctx:null,
            tl:{x:0, y:0},
            stl:{x:0, y:0},
            pos:{x:0, y:0},
            cwidth:50,
            cheight:50,
            width:0,
            height:0,
            swidth:0,
            sheight:0,
            zoom:1,
            isMoving:false,
            id:'',
            cvasId:'',
            containerId:'',
            image_blank:require('../assets/blank_news_icon.png'),
            image:require('../assets/blank_news_icon.png'),
            cvas_image:new Image(),
        }
  },

  async created(){

    let r = (Math.random() + 1).toString(36).substring(7);

    this.id = r;

    this.cvasId = "cvas_" + r;

    this.containerId = "container_" + r;

    await this.getImage();
  },

  mounted(){

    let container = document.getElementById(this.containerId);

    this.cwidth = container.clientWidth;

    this.cheight = container.clientHeight;

    this.cvas = document.getElementById(this.cvasId);

    this.ctx = this.cvas.getContext("2d");  
  },

  methods: {

    toBlob(){

        this.cvas = document.getElementById(this.cvasId);

        let _this = this;

        this.cvas.toBlob(function(blob) {

            blobToBase64(blob).then(res => {
            
               _this.image = res;

               console.log(_this.image);
            });
        })
    },

    mousewheel(e){

        console.log(e);

        e.preventDefault();

        if(this.ctx){

            Math.max(-1, Math.min(1, e.wheelDelta || -e.detail)) > 0 ? this.zoom += .05 : this.zoom -= .05;

            this.tl.x -= this.pos.x;

            this.tl.y -= this.pos.y;

            this.tl.x *= this.zoom;

            this.tl.y *= this.zoom;

            this.tl.x += this.pos.x;

            this.tl.y += this.pos.y;

            this.swidth = this.width * this.zoom;

            this.sheight = this.height * this.zoom;

            this.ctx.fillStyle = "#ffffff";

            this.ctx.fillRect(0, 0, this.width, this.height);

            this.ctx.drawImage(this.cvas_image, this.stl.x, this.stl.y, this.swidth, this.sheight);
        }
    },

    async mouseup(){

        if(this.isMoving){

            this.isMoving = false;

            this.cvas = document.getElementById(this.cvasId);

            let _this = this;

            this.cvas.toBlob(function(blob) {

                blobToBase64(blob).then(res => {
                
                    _this.image = res;

                    _this.$emit("imageChange", _this.image);
                });
            })
        } 
    },

    mousedown(m){

        this.isMoving =  true;

        this.pos = {x:m.offsetX, y:m.offsetY};

        console.log(this.pos);
    },

    mousemove(m){

        if(this.isMoving){

            this.stl.x += m.movementX;

            this.stl.y += m.movementY;

            this.ctx.fillStyle = "#ffffff";

            this.ctx.fillRect(0, 0, this.width, this.height);

            this.ctx.drawImage(this.cvas_image, this.stl.x, this.stl.y, this.swidth, this.sheight);
        }

        this.pos = {x:m.offsetX, y:m.offsetY};
    },

    newImage(){

        if(this.config.edit) document.getElementById(this.id).click();
    },

    updateImage(){
        
        let _this = this;

        this.cvas_image.onload = function() {

            _this.width = this.width;

            _this.height = this.height;

            _this.swidth = this.width;

            _this.sheight = this.height;

            _this.ctx.fillStyle = "#ffffff";
            
            _this.ctx.fillRect(0, 0, _this.width, _this.height);

            _this.ctx.drawImage(_this.cvas_image, _this.stl.x, _this.stl.y, _this.swidth, _this.sheight);
        };

        this.cvas_image.src = this.user_image;
    },

    fileChanged(event){

        var selectedFile = event.target.files[0];

        var reader = new FileReader();

        let _this = this;

        if(selectedFile.size < 3000000){

            reader.onload = function(event) {

                 _this.user_image = event.target.result;

                _this.updateImage();

                _this.$emit("imageChange", _this.user_image);
            };

        } else {

            this.userImage =  this.sizeWarning;
        }

        reader.readAsDataURL(selectedFile);
    },

    async getImage(){

        let user_image;

        const apiName = 'dtApiGateway';
        const path = '/image';

        //let user = await Auth.currentAuthenticatedUser();

        if(this.config.image.length){

            const data = {
                body: {
                    path:this.config.path,
                    image:this.config.image
                },
                headers: {
                    //"Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            user_image = await API.post(apiName, path, data);

        } else {

            user_image = this.image_blank;
        }
        //console.log(user_image);
        if(user_image.length) {

            this.user_image = user_image;

            this.updateImage();
        }
    },
  }
}

</script>

<style scoped>

.e010_1000{
  display:flex;
  cursor:pointer;
}

.e010_1010{
  cursor:move;
  background-color:rgba(255, 255, 255, 1);
}

.e010_1020{
    display:none;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>