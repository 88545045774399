<template>
    <div id="page" class="e340_1000">

        <div class="e340_1240">
            <div class="e340_1103">
                <SelectField :config="filterConfig" @change="addTag"/>
            </div>
            <div class="e340_1105">
                <div class="e340_1106">
                    <div class="e340_1108" v-for="tag of tags" :key="tag">
                        <TagItem :config="tag" @remove="removeTag" />
                    </div>
                </div> 
            </div>
        </div>

        <div v-for="story in stories" :key="story">
            <NewsItem :config="story" :edit="userEdit" @editItem="editItem"/>
        </div>

        <div v-if="editConfig">
            <div id="edit" class="e340_1020" :style="getTop">
                <div class="e340_1030" @click="close"></div>
                <div class="e340_1038"><InputField :config="urlConfig" /></div>
                <div class="e340_1070" @click="openURL"></div>
                <div class="e340_1040"><InputField :config="titleConfig" /></div>
                <div class="e340_1050">
                    <div class="e340_1042"><InputField :config="authorConfig" /></div>
                    <div class="e340_1044"><InputField :config="dateConfig" /></div>
                </div>
                <div class="e340_1046"><InputField :config="imageConfig" /></div>
                <div id="spin" class="e340_1048"><TextField :config="descConfig" :text="description"/></div>

                <div class="e340_1102">
                    <SelectField :config="tagsConfig" @change="addTag"/>
                </div>
                <div class="e340_1104">
                    <div class="e340_1106">
                        <div class="e340_1108" v-for="tag of tags" :key="tag">
                            <TagItem :config="tag" @remove="removeTag" />
                        </div>
                    </div> 
                </div>

                <div class="e340_1110">
                    <ButtonActive :config="hideConfig" @click="hide" />
                </div>

                <div class="e340_1120">
                    <ButtonActive :config="saveConfig" @click="save" />
                </div>
            </div>
        </div>

        <!--<div class="e340_1130">
            <CatBar id="catBar" :config="catBarConfig" @clicked="pageChange"/>
        </div>-->

        <div v-else>
            <div id="spin" class="e340_1240"></div>
            <div v-if="firstPage">
                <div class="e340_1201">
                    <div class="e340_1210">
                        <ButtonActive :config="nextConfig" @click="nextPage" />
                    </div>
                </div>
            </div>

            <div v-else>
                <div v-if="lastPage">
                    <div class="e340_1201">
                        <div class="e340_1210">
                            <ButtonActive :config="prevConfig" @click="prevPage" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="e340_1200">
                        <div class="e340_1210">
                            <ButtonActive :config="prevConfig" @click="prevPage" />
                        </div>

                        <div class="e340_1220">{{ page }}</div>

                        <div class="e340_1230">
                            <ButtonActive :config="nextConfig" @click="nextPage" />
                        </div>

                        <div v-if="userEdit" class="e340_1221" @click="plus10">+10</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API } from 'aws-amplify'
import { Auth } from 'aws-amplify'
import NewsItem from './NewsItem.vue'
import InputField from './InputField.vue'
import SelectField from './SelectField.vue'
import TextField from './TextField.vue'
import ButtonActive from './ButtonActive.vue'
import TagItem from './TagItem.vue'

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

export default {
  name: 'NewsPage',
  emits: [],

  components: {
    NewsItem,
    InputField,
    SelectField,
    TextField,
    ButtonActive,
    TagItem,
    //CatBar
  },

  props: {
    config:{
        type:Object,
    }
  },

  data(){
        return {
            headerBackground: 'white',
            stories:[],
            tags:[],
            userEdit:false,
            editConfig:null,
            description:'',
            view:1,
            page:1,
            maxPage:0,
            items:10,
            editTop:'100px',
            storyRnd:0,
            cat: BigInt(128),
            firstPage:false,
            lastPage:false,
            filter:0,

            urlConfig:{id:"url", type:"input", regx:this._TEXT, name:"url", disabled:false, title:"URL", placeholder:"", value:'', required:false, dataTest:""},
            titleConfig:{id:"title", type:"input", regx:this._TEXT, name:"title", disabled:false, title:"Title", placeholder:"", value:'', required:false, dataTest:""},
            authorConfig:{id:"author", type:"input", regx:this._TEXT, name:"author", disabled:false, title:"Author", placeholder:"", value:'', required:false, dataTest:""},
            dateConfig:{id:"date", type:"input", regx:this._TEXT, name:"date", disabled:false, title:"Date", placeholder:"", value:'', required:false, dataTest:""},
            imageConfig:{id:"image", type:"input", regx:this._TEXT, name:"image", disabled:false, title:"Image", placeholder:"", value:'', required:false, dataTest:""},
            descConfig:{id:"desc", type:"input", name:"desc", disabled:false, title:"Description", rows:6, placeholder:"", value:'', required:false, dataTest:""},

            tagsConfig:{id:"tags", name:"tags", title:"Tags", dataTest:"", options: this.tagVals},
            filterConfig:{id:"filter", name:"filter", title:"Filter News Stories", required:false, value:128, dataTest:"", options: this.tagVals},
            hideConfig:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Hide", width:"132px"},
            saveConfig:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Save", width:"132px"},

            prevConfig:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"PREV", width:"110px"},
            nextConfig:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"NEXT", width:"110px"},

            catBarConfig:{min:1, max:0, items:[]},

            
                
        }
  },

  computed: {

    getTop: function(){

            return {
                "top":this.editTop + "px",
            }
        },
    },

    async created(){

        window.addEventListener('scroll', this.handleScroll);

        let userAttributes = await Auth.currentUserInfo();

        if(userAttributes){

            if(userAttributes.attributes['custom:access']) this.userEdit = true;
        }

        await this.getNews();

        this.tags = [];

        for(let n in this.tagVals){

            this.tagVals[n].value = Math.pow(2,  this.tagVals[n].n);

            if(BigInt(this.tagVals[n].value) & this.cat) this.tags.push({fontSize:'12px', background:'rgba(240, 66, 0, 1)', color:'rgba(255, 255, 255, 1)', edit:true, value: BigInt(this.tagVals[n].value), text: this.tagVals[n].text});
        }

        this.filterConfig.options.sort((a, b) => a.text.localeCompare(b.text));

        this.tagsConfig.options.sort((a, b) => a.text.localeCompare(b.text));

        //this.catBarSetup(res.count);
  },

  mounted(){

    
  },

  beforeUnmount(){

        window.removeEventListener('scroll', this.handleScroll);
    },

  methods: {

    async prevPage(){

        this.page--;

        if(this.page <= 0) this.page = 1;

        this.stories = [];

        await this.getNews();
    },

    async plus10(){

        this.page += 10;

        if(this.page >= this.maxPage) this.page = this.maxPage;

        this.stories = [];

        await this.getNews();
    },

    async nextPage(){

        this.page++;

        if(this.page >= this.maxPage) this.page = this.maxPage;

        this.stories = [];

        await this.getNews();
    },

    /*async pageChange(config){

        if(!isNaN(parseInt(config.ch))){

            this.page = parseInt(config.ch);

        } else {

            switch(config.ch){

                case String.fromCharCode(0x276e):
                    this.page--;
                    if(this.page <= 0) this.page = 1;
                    break;

                case String.fromCharCode(0x276f):
                    this.page++;
                    if(this.page >= this.catBarConfig.max) this.page = this.catBarConfig.max;
                    break;
            }
        }

        this.stories = [];

        let res = await this.getNews();

        res.items.forEach(story => {
            
            if(story.view) this.stories.push(story);
        });

        //this.catBarSetup(res.count);

        this.pageTop();
    },*/

    async news(){

        this.cat = BigInt(128);

        await this.getNews();

        this.tags = [];

        for(let n in this.tagVals){

            this.tagVals[n].value = Math.pow(2,  this.tagVals[n].n);

            if(BigInt(this.tagVals[n].value) & this.cat) this.tags.push({edit:true, value: BigInt(this.tagVals[n].value), text: this.tagVals[n].text});
        }

    },

    async getNews(){

        const apiName = 'dtApiGateway';
        const path = '/getNewsItems';

        var target = document.getElementById('spin');

        target.style.display = "block";

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const data = {
            body: {
                start:(this.page - 1) * 10,
                cat: this.cat.toString(),
                view:this.userEdit
            },
            headers: {
            }
        };

        this.firstPage = (this.page === 1)?true:false;

        let info
        
        try{
            
            info = await API.post(apiName, path, data);

        } catch(err){

            console.log("Newsfeed not available");
        }

        this.stories = [];

        info.items.forEach(story => {
            
            if(!story.alertsParse){
                if(this.userEdit) this.stories.push(story)
                else if(story.view) this.stories.push(story);
            }
        });

        //console.log(this.stories.length);

        //this.lastPage = ((!this.firstPage) && (this.stories.length !== 10))?true:false;

        this.pageTop();

        this.maxPage = Math.ceil(info.count / 10);

        spinner.stop();

        target.style.display = "none";

        return info;
    },

    async addTag(item){

        let cat = BigInt(this.cat);

        cat |= BigInt(item.value);

        this.cat = BigInt(cat);

        this.tags.push({value:BigInt(item.value), edit:true, text:item.text});

        if(!this.editConfig) await this.getNews();
    },

    async removeTag(item){

        item.edit = false;

        let cat = BigInt(this.cat);

        cat &= BigInt((~BigInt(item.value)));

        this.cat = BigInt(cat);

        for(let n in this.tags){

            if(this.tags[n].value === item.value) this.tags.splice(n, 1);
        }

        if(!this.editConfig) await this.getNews();
    },

    /*catBarSetup(max){

        this.catBarConfig.min = this.page;

        this.catBarConfig.max = Math.ceil(max / 10);

        console.log(this.catBarConfig.max);

        let catBar = document.getElementById('catBar');

        let width = catBar.offsetWidth;

        let itemWidth = 46;

        let w = 0;

        this.catBarConfig.items = [];

        this.catBarConfig.items.push({ch:String.fromCharCode(0x276e), color:'rgba(240, 66, 0, 1)', pointer:'pointer'});

        w += itemWidth;

        let count = this.catBarConfig.min;

        while(w < width - (4 * itemWidth)){

            this.catBarConfig.items.push({ch:count.toString(), color:'rgba(240, 66, 0, 1)', pointer:'pointer'});

            w += itemWidth;

            if(++count > 5) break;
        }

        this.catBarConfig.items.push({ch:String.fromCharCode(0x22ef), color:'rgba(51, 51, 51, 1)', pointer:'default'});

        this.catBarConfig.items.push({ch:(this.catBarConfig.max - 1).toString(), color:'rgba(240, 66, 0, 1)', pointer:'pointer'});

        this.catBarConfig.items.push({ch:this.catBarConfig.max.toString(), color:'rgba(240, 66, 0, 1)', pointer:'pointer'});

        this.catBarConfig.items.push({ch:String.fromCharCode(0x276f), color:'rgba(240, 66, 0, 1)', pointer:'pointer'});
    },*/

    close(){

        this.editConfig = null;
    },

    hide(){

        this.saveItem(0);
    },

    save(){

        this.saveItem(1);
    },

    async saveItem(view){

        let user = await Auth.currentAuthenticatedUser();

        let t1 = document.getElementById("desc").value.replace(/[\n\r]+/g, '');
              
        let t2 = t1.replace(/\s{2,10}/g, ' ');
        
        let desc = t2.replace(/\s*([,.!?:;]+)(?!\s*$)\s*/g, '$1 ').replace(/"/g, "'");
        
        let title = document.getElementById("title").value.replace(/"/g, "'");
        
        const data = {
            body: {
                "id":this.editConfig.id,
                "title":title, 
                "author":document.getElementById("author").value, 
                "news_date":document.getElementById("date").value, 
                "image_url":document.getElementById("image").value, 
                "description":desc,
                "view":view,
                "cat":this.cat.toString()
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/updateNewsItem';

        let res = await API.post(apiName, path, data);

        for(let story of this.stories){

            if(story.id === this.editConfig.id){

                story.title = res.item[0].title;
                story.author = res.item[0].author;
                story.news_date = res.item[0].news_date;
                story.image_url = res.item[0].image_url;
                story.image = res.item[0].image;
                story.description = res.item[0].description;
                story.reviewed = res.item[0].reviewed;
                story.cat = BigInt(res.item[0].cat);
                story.view = view;

                //console.log(res.item[0]);
            }
        }

        spinner.stop();

        this.editConfig = false;

        this.cat = this.filter;

        this.stories = [];

        await this.getNews();
    },

    handleScroll(){

        this.editTop = window.scrollY;
    },

    pageTop(){
      window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
      });
    },

    editItem(config){

        this.filter = this.cat;

        this.editConfig = config;

        this.urlConfig.value = this.editConfig.source + "/" + this.editConfig.path;
        this.titleConfig.value = this.editConfig.title;
        this.authorConfig.value = this.editConfig.author;
        this.dateConfig.value = this.editConfig.news_date;
        this.imageConfig.value = this.editConfig.image_url;
        this.descConfig.value = this.editConfig.description;
        this.cat = this.editConfig.cat;

        let desc = this.editConfig.description.toLowerCase();

        let cat = BigInt(this.cat);

        if(!this.cat){

            this.tagVals.forEach(tag => {

                tag.words.forEach(word => {

                    if(desc.indexOf(word) > -1) {
                        
                        //console.log(tag.value + " " + tag.text);

                        cat |= BigInt(tag.value);
                    }
                })
            });

            this.cat = cat;
        }

        this.tags = [];

        this.tagVals.forEach(item => {

            item.edit = true;

            if(BigInt(cat) & BigInt(item.value)) this.tags.push(item);
        });
    },

    openURL(){

        window.open(this.editConfig.source + "/" + this.editConfig.path)
    },
  }
}

</script>

<style scoped>
.e340_1000{
    width:100%;
    max-width:1200px;
    margin:88px auto 0 auto;
    position:relative;
}

.e340_1010{
    width:100%;
    margin:0 auto;
    position:relative;
    height:90px;
}

.e340_1020{
    width:80%;
    position:absolute;
    height:860px;
    left:15%;
    top:100px;
    background-color:rgba(240,240,240,1);
    border-radius:10px;
}

.e340_1030{
    width:16px;
    height:16px;
    position:absolute;
    left:calc(100% - 30px);
    top:10px;
    background-image:url(../assets/close.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

.e340_1070{
    width:30px;
    height:42px;
    position:absolute;
    left:calc(100% - 80px);
    top:65px;
    background-image:url(../assets/webpage.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

.e340_1038{
    position:absolute;
    width:calc(100% - 140px);
    top:40px;
    left:50px;
}

.e340_1040{
    position:absolute;
    width:calc(100% - 100px);
    top:140px;
    left:50px;
}

.e340_1050{
    position:absolute;
    width:calc(100% - 100px);
    top:240px;
    left:50px;
}

.e340_1042{
    position:relative;
    width:48%;
    float:left;
}

.e340_1044{
    position:relative;
    width:48%;
    float:right;
}

.e340_1046{
    position:absolute;
    width:calc(100% - 100px);
    top:340px;
    left:50px;
}

.e340_1048{
    position:absolute;
    width:calc(100% - 100px);
    top:440px;
    left:50px;
}

.e340_1060{
    width:80%;
    position:relative;
    margin:0 auto;
    height:220px;
}

.e340_1080{
    width:100%;
    margin:0 auto;
}

.e340_1100{
    position:absolute;
    width:300px;
    top:600px;
    left:50px;
}

.e340_1102{
    position:absolute;
    width:200px;
    top:670px;
    left:50px;
}

.e340_1103{
    position:absolute;
    width:200px;
    top:0px;
    left:0px;
}

.e340_1104{
    position:absolute;
    width:calc(100% - 260px);
    top:700px;
    left:260px;
}

.e340_1105{
    position:absolute;
    width:calc(100% - 220px);
    top:35px;
    left:210px;
    height:60px;
}

.e340_1106{
    position:relative;
    text-align:left;
}

.e340_1108{
    display:inline-block;
    margin: 0 10px 10px 0;
}

.e340_1110{
    position:absolute;
    top:780px;
    left:50px;
}

.e340_1120{
    position:absolute;
    top:780px;
    left:calc(100% - 50px - 132px);
}
.e340_1200{
    position:relative;
    width:320px;
    height:100px;
    margin:0 auto;
}

.e340_1201{
    position:relative;
    width:120px;
    height:100px;
    margin:0 auto;
}

.e340_1210{
    position:absolute;
    top:30px;
    left:0px;
}

.e340_1220{
    position:absolute;
    top:30px;
    left:152px;
    text-align:left;
    font-family:'Open Sans';
    font-size:40px;
    font-weight:700;
}

.e340_1221{
    position:absolute;
    top:30px;
    left:400px;
    text-align:left;
    font-family:'Open Sans';
    font-size:40px;
    font-weight:700;
    cursor:pointer
}

.e340_1230{
    position:absolute;
    top:30px;
    left:218px;
}

.e340_1240{
    position:relative;
    display:block;
    width:80%;
    margin:0 auto;
    height:90px;
}

@media only screen and (max-width:600px) {
    
    .e340_1105{
        width:100%;
        top:90px;
        left:0px;
        height:60px;
    }

    .e340_1130{
        width:80%;
        margin:20px auto;
    }

    .e340_1240{
        height:130px;
    }
}

@media only screen and (min-width:600px) {
    .e340_1130{
        width:60%;
        margin:20px auto;
    }
}

@media only screen and (min-width:1200px) {

    .e340_1130{
        width:50%;
        margin:20px auto;
    }
}
</style>