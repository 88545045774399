<template>
    
    <div class="e120_1000">

        <div v-if="add" class=e120_1040>

            <AddBidForm  :project="config" :config="bid"  @close="close" />
        </div>

        <div v-else class="e120_1020">
            <div class="e120_1030" v-for="bid of bids" :key="bid">
                <BidTile :config="bid" />
            </div>
        </div>

    </div>

    
</template>

<script>
//import ButtonActiveSm from './ButtonActiveSm.vue'
import AddBidForm from './AddBidForm.vue'
import BidTile from './BidTile.vue'

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

export default {
  name: 'BidList',
  emits: [],

  components: {
    //ButtonActiveSm,
    AddBidForm,
    BidTile,
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
    return {
            add:false,
            bids:[],
            contract:null,
            bid:null,
            found:0,
            addBidBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Bid", width:"100px"},
        }
  },

  async created(){

    console.log(this.config);

    await this.getBids();
  },

  methods: {

    close(){

        this.add = false;

        //this.getBids();
    },

    addBidForm(){

        this.add = true;
    },

    async getProjectFromBid(){

    },

    async getBids(){

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/bids';

        let user = await Auth.currentAuthenticatedUser();

        let data = {
            body: {
                user:0
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        if(this.config) data.body.pid = this.config.id;

        this.bids = await API.post(apiName, path, data);

        console.log(this.bids);

        for(let bid of this.bids){

            bid.config = {label:bid.title, fontSize:"18px"};
            
            bid.pilotData.add = false;
            bid.pilotData.remove = false;

            bid.droneData.add = false;
            bid.droneData.remove = false;

            if(bid.contractor) this.awarded++;
            if(bid.owner) this.created++;
            if(bid.owner & bid.completed_supplier) this.completed++;
        }

        this.close();

        spinner.stop();
    },
  }
}

</script>

<style scoped>

.e120_1000{
    width:100%;
    min-width:400px;
    margin:0 auto;
}

.e120_1010{
    width:180px;
    height:100px;
    margin-left:calc(100% - 200px);
    position:relative;
}

.e120_1020{
    width:100%;
}

.e120_1030{
    width:80%;
    margin:5px auto;
    
}

.e120_1040{
    width:100%;
    max-width:600px;
    margin:0 auto;
}

.e120_1050{
    width:100%;
    text-align:left;
    font-family:'Open Sans';
    font-weight:700;
    font-size:24px;
    margin:0 5px;
    display:inline-block;
}

.e120_1051{
    width:calc(100% - 120px);
    max-width:600px;
    text-align:left;
    font-family:'Open Sans';
    font-weight:700;
    font-size:24px;
    margin:0 5px;
    display:inline-block;
}

.e120_1055{
    width:100px;
    padding-top:3px;
    display:inline-block;
    vertical-align:top;
}

.e120_1060{
    width:90%;
    margin:20px auto 0 auto;
    font-family:'Open Sans';
    font-weight:400;
    font-size:16px;
}

.e120_3000{
    width:80%;
    height:800px;
    margin:0 auto;
}



@media only screen and (max-width:600px) {

    .e120_1030{
        width:95%;
    }
}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>