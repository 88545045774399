<template>

    <div class="e276_1000">

        <div class="e276_1003">

            <div class="e276_1005"></div>

            <div class="e276_1010" :style="getImg" @click="service" ></div>

            <div class="e276_1020"></div>

        </div>

        <div class="e276_1025">

            <div class="e276_1030"></div>

            <div class="e276_1040" @click="service">{{config.feature.title}}</div>

            <div class="e276_1050">{{config.feature.text}}</div>
        </div>

    </div>

</template>

<script>

export default {
    name: 'FeatureDetailLeft',
    emits: ["service"],

    components: {
        //Button
    },

    props: {

        config: {
            type:Object
        }
    },

    created(){

        this.id = this.config.id;
    },

    computed: {

        getImg: function(){

            //let img= this.config.img;

            return {
                backgroundImage:'url(' + encodeURI(this.config.feature.img) + ')',
            }
        },
    },

    data(){
        return {
            id:0,
            image:'',
        }
    },

    methods: {

        service(){

            this.$emit('service', this.id);
        },
    }
}

</script>

<style scoped>

.e276_1000 {
    display:block;
    width:100%;
    height:500px;
    position:relative;
    margin:0 auto;
}

.e276_1003 {
    position:absolute;
    top:35px;
    left:51px;
    z-index:1;
}


.e276_1025 {
    position:absolute;
    top:122px;
    left:811px;
    z-index:1;
}

.e276_1005 {
    background-color:rgba(240, 66, 0, 1);
    width:430px;
    height:380px;
    position:absolute;
    top:50px;
    left:265px;
    z-index:1;
}

.e276_1010 {
    width:620px;
    height:400px;
    position:absolute;
    top:0px;
    left:45px;
    border-radius:16px;
    background-size: contain;
    z-index:2;
    cursor:pointer;
}

.e276_1020 { 
    background-image:url(../assets/hatch.png);
	width:196px;
	height:294px;
	position:absolute;
	top:68px;
    left:0px;
    background-size: contain;
    background-repeat:no-repeat;
    z-index:3;
}

.e276_1030 { 
	border-radius:500px;
	background-color:rgba(240, 66, 0, 1);
	width:28px;
	height:28px;
	position:absolute;
    top:10px;
    left:0px;
}

.e276_1040 { 
	color:rgba(240, 66, 0, 1);
	width:540px;
	height:32px;
	position:absolute;
	top:0px;
    left:41px;
    font-family:'Open Sans';
    font-size:32px;
    font-weight:700;
    text-align:left;
    cursor:pointer;
}

.e276_1050 { 
	color:#485269;
	width:580px;
	height:158px;
	position:absolute;
	top:62px;
    left:0px;
    font-family:'Open Sans';
    font-size:18px;
    font-weight:400;
    text-align:left;
}

@media only screen and (max-width: 600px) {


    .e276_1003 {
        left:calc((100% - 460px)/2);
    }

    .e276_1025 {
        top:320px;
        left:calc((100% - 320px)/2);
    }

    .e276_1005 {
        width:230px;
        height:200px;
        left:185px;
    }

    .e276_1010 {
        width:350px;
        height:225px;
    }

    .e276_1020 { 
        width:110px;
        height:166px;
        top:38px;
    }

    .e276_1030 { 
        width:20px;
        height:20px;
    }

    .e276_1040 { 
        top:2px;
        font-size:24px;
        width:290px;
    }

    .e276_1050 { 
        width:310px;
        font-size:18px;
    }
}

@media only screen and (min-width: 600px) {


    .e276_1003 {
        left:calc((100% - 500px)/2);
    }

    .e276_1025 {
        top:320px;
        left:calc((100% - 500px)/2);
    }

    .e276_1005 {
        width:230px;
        height:200px;
        left:185px;
    }

    .e276_1010 {
        width:350px;
        height:225px;
    }

    .e276_1020 { 
        width:110px;
        height:166px;
        top:38px;
    }

    .e276_1030 { 
        width:20px;
        height:20px;
    }

    .e276_1040 { 
        top:2px;
        font-size:24px;
        width:450px;
    }

    .e276_1050 { 
        width:500px;
        font-size:18px;
    }
}

@media only screen and (min-width: 1200px) {


    .e276_1003 {
        left:51px;
    }


    .e276_1025 {
        top:122px;
        left:811px;
    }

    .e276_1005 {
        width:430px;
        height:380px;
        left:265px;
    }

    .e276_1010 {
        width:620px;
        height:400px;
    }

    .e276_1020 { 
        width:196px;
        height:294px;
        top:68px;
    }

    .e276_1030 { 
        width:28px;
        height:28px;
    }

    .e276_1040 { 
        top:0px;
        left:41px;
        font-size:32px;
    }

    .e276_1050 { 
        width:580px;
    }
}

</style>