<template>
    <div class=e271_6757>
        <div class=ei271_6757_271_6756>
            <div class=ei271_6757_271_6748>
                <span  class="ei271_6757_271_6749 noselect">Contact us for more information</span>
                <span  class="ei271_6757_271_6750 noselect">Ready to get started?</span>
            </div>
            <div class=ei271_6757_271_6751>
                <div class=ei271_6757_271_6752>
					<ButtonActive :config="ask1" @click="quote"/>
                </div>
                <div class=ei271_6757_271_6753>
					<ButtonActive :config="ask2" @click="contact"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'

export default {
    name: 'GetStarted',
    emits: ["contact", "quote"],

    components: {
        ButtonActive
    },

    props: {
		config: {
            type: Object,
        },
    },

	created(){

		this.background = this.config.background;

		this.textColor = this.config.textColor;

		this.color = this.config.color;

		this.ask1 = this.config.ask1;

		this.ask2 = this.config.ask2;
	},

	data(){
        return {
			background:'#F04200',
			color:'#F04200',
			textColor:'#ffffff',
            ask1:{},
            ask2:{},
		}
    },

    methods: {

		contact(){

			this.$emit('contact');
		},

		quote(){

			this.$emit('quote');
		},
	},
}

</script>

<style scoped>
.e271_6757 { 
	width:100%;
	height:250px;
	position:relative;
	background-color:v-bind(background);
}
.ei271_6757_168_3675 { 
	width:100%;
	height:250px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.ei271_6757_168_3676 { 
	width:100%;
	height:250px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_6757_168_3677 { 
	background-color:v-bind(background);
	border: 1px solid v-bind(color);
	width:100%;
	height:250px;
	position:absolute;
	left:0px;
	top:0px;
}

.ei271_6757_271_6756 { 
	width:850px;
	height:170px;
	position:absolute;
	left:calc((100% - 850px)/2);
	top:40px;
}
.ei271_6757_271_6748 { 
	width:850px;
	height:92px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_6757_271_6749 { 
	color:v-bind(textColor);
	width:850px;
	height:30px;
	position:absolute;
	left:0px;
	top:65px;
	font-family:'Open Sans';
	text-align:center;
	font-size:24px;
	font-weight:400;
	letter-spacing:-0.30000001192092896;
	line-height:px;
}
.ei271_6757_271_6750 { 
	color:v-bind(textColor);
	width:730px;
	height:60px;
	position:absolute;
	left:58px;
	top:0px;
	font-family:'Open Sans';
	text-align:center;
	font-size:48px;
	font-weight:700;
	letter-spacing:-1.2000000476837158;
	line-height:px;
}
.ei271_6757_271_6751 { 
	position:absolute;
	left:calc((100% - 480px)/2);
	top:120px;
}
.ei271_6757_271_6752 { 
	position:absolute;
	left:0px;
	top:0px;
}

.ei271_6757_271_6753 { 
	position:absolute;
	left:250px;
	top:0px;
}

@media only screen and (max-width: 600px) {
	.e271_6757 { 
		height:350px;
	}

	.ei271_6757_271_6756 { 
		width:350px;
		height:270px;
		position:absolute;
		left:calc((100% - 350px)/2);
		top:50px;
		text-align:center;
	}
	.ei271_6757_271_6748 { 
		width:350px;
		height:92px;
		position:absolute;
		left:0px;
		top:0px;
	}

	.ei271_6757_271_6750 { 
		left:0px;
		width:350px;
		font-size:28px;
		font-weight:700;
	}

	.ei271_6757_271_6749 { 
		width:350px;
		left:0px;
		top:45px;
		font-size:18px;
		font-weight:500;
	}

	.ei271_6757_271_6751 { 
		width:233px;
		height:58px;
		position:absolute;
		left:calc((100% - 233px)/2);
		top:110px;
	}

	.ei271_6757_271_6753 { 
		left:0px;
		top:80px;
	}
}

</style>