<template>
    <div class="container noselect">
        <div class="title">{{config.title}}<span v-if="required" class="asterix">*</span></div>
        
        <textarea :id="id" :type="type" v-model="editText" @keyup="check" :rows="rows" :placeholder="placeholder" :disabled="disabled" :name="name" class="input" :data-test="dataTest" ></textarea>

        <div :id="idError" class="err">Error</div>
    </div>
</template>

<script>

export default {
    name: 'InputField',
    emits: ["verify"],

    components: {
        //Button
    },


    props: {
        config: Object,
    },

    data(){
            return {
                id:0,
                idError:0,
                rows:0,
                type:'input',
                placeholder:'',
                name:'',
                height:'50px',
                dataTest:'',
                matches:null,
                disabled:true,
                unselectable:"off",
                required:false,
                editText:'',
            }
    },

    created(){

        this.id = this.config.id;

        this.idError = "err" + this.id;

        this.type = this.config.type;

        this.editText = this.config.value;

        this.placeholder = this.config.placeholder;

        this.name = this.config.name;

        this.dataTest = this.config.dataTest;

        this.disabled = this.config.disabled;

        if(this.disabled) this.unselectable = "on";

        this.required = this.config.required;

        this.rows = this.config.rows;

        this.height = (this.rows * 50) + 'px';
    },

    methods: {

        getDifference(s, t){
            s = [...s].sort();
            t = [...t].sort();
            return t.find((char, i) => char !== s[i]);
        },

        check(){

            let text = document.getElementById(this.id).value;

            let match = text.match(/^(?=.*[A-Z0-9])[/\n\w.,;:?!@)"'/$ ]+$/i);

            if (match) {

                this.matches = match[0];

                document.getElementById(this.id).style.borderColor = "#949494";

                document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                document.getElementById(this.idError).style.display = "none";

                this.$emit('verify', {state:true, value:document.getElementById(this.id).value});

            } else {

                if(this.matches){

                    let ch = this.getDifference(this.matches, text);

                    if(ch){

                        document.getElementById(this.id).style.borderColor = "red";

                        document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                        document.getElementById(this.idError).style.display = "block";

                        document.getElementById(this.idError).innerHTML = "Character '" + ch + "' is not allowed";
                    }

                    this.$emit('verify', {state:false, value:''});
                }
            }
        }

    }
}

</script>

<style scoped>

.container {
    width:100%;
    position:relative;
    font-family:'Open Sans';
    font-size:16px;
    font-weight:400;
    height:v-bind(height);
}

.title {
    position:absolute;
    top:0px;
    left:0px;
    font-size:12px;
    font-weight:500;
}

.asterix{
    color:rgba(255, 0, 0, 1);
    font-size:12px;
    font-weight:500;
    margin-left:5px;
}
.input {
    resize: none;
    position:absolute;
    top:20px;
    left:0px;
    width:100%;
    border:1px solid #949494;
    border-radius:4px;
    padding:20px 12px;
    outline: none;
}

.err {
    width:300px;
    position:absolute;
    top:193px;
    left:0px;
    font-family:'Open Sans';
    font-size:12px;
    font-weight:400;
    color:rgba(255, 0, 0, 1);
    display:none;
    text-align:left;
}

@media only screen and (max-width: 420px) {
    .container{
        width:100%;
    }
}

@media only screen and (min-width: 600px) {
    .container {
        width:100%;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width:100%;
    }
}
</style>