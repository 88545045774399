<template>
  <div class="e230_1000 noselect">
    <div  class="e230_1020 noselect" @click="openTab" >{{ title }}</div>
    <div  class=e230_1050>
      <div  class="e230_1060 noselect">{{news_date}}</div>
      <div  class="e230_1060 noselect">{{config.author}}</div>
    </div>

    <PhotoView v-if="hasImage" class="e230_1010 noselect" :config="imageConfig" @click="openTab"/>

    <div class="e230_1015 noselect">
      
      <div  class="e230_1030 noselect" @click="openTab">{{ description }}
        <a :href="link"> ... <span class="e230_1080">more</span></a>
      </div>

      <div class="e230_1100 noselect">
        <div class="e230_1110 noselect">
          <div class="e230_1120 noselect" v-for="tag of tags" :key="tag">
              <TagItem :config="tag" />
          </div>
        </div>
      </div>

      <div  v-if="edit" class="e230_1040 noselect" @click="editItem">
        <div v-if="config.reviewed">
          <div class=e230_1070></div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import PhotoView from './PhotoView.vue'

import { format } from 'date-fns';
import { parseISO } from 'date-fns';
import utf8 from 'utf8';

import TagItem from './TagItem.vue';

export default {
  name: 'NewsItem',
  emits: ['editItem'],

  components: {
    TagItem,
    PhotoView,
  },

  props: {
    config:{
      type:Object,
    },
    edit:{
      type:Boolean,
    }
  },

  computed: {

  },

  watch: {

    'config.title'(title) {

      this.title = title.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
    },

    'config.description'(description){

      this.description = description.substring(0, 300);

      this.tags = [];

      for(let n in this.tagVals){

        this.tagVals[n].value = BigInt(Math.pow(2,  this.tagVals[n].n));

        if(this.tagVals[n].value & BigInt(this.config.cat)) this.tags.push({edit:false, value:this.tagVals[n].value, text:this.tagVals[n].text});
      }
    },

    'config.source'(source){

      this.link = source + '/' + this.config.path;
    },

    'config.path'(path){

      this.link = this.config.source + '/' + path;
    },

    'config.news_date'(news_date){

      try{
        
        this.news_date = format(new Date(news_date), "dd MMM yyyy, HH") + "hrs";

      } catch(e) {

        let n = news_date.indexOf('T');

        if(n > -1){

          try{

            this.news_date = format(new Date(news_date.substring(0, n)), "dd MMM yyyy");

          } catch(e){

            this.news_date = format(new Date(this.config.date_created), "dd MMM yyyy, HH") + "hrs";
          }

        } else {

          this.news_date = format(new Date(this.config.date_created), "dd MMM yyyy, HH") + "hrs";
        }
      }
    },

    'config.view'(view){

      if(view) this.background = 'rgba(255, 255, 255, 1)'
      else this.background = 'rgba(240, 240, 240, 1)';
    },

    'config.cat'(cat){

      this.tags = [];

      for(let n in this.tagVals){

        this.tagVals[n].value = BigInt(Math.pow(2,  this.tagVals[n].n));

        if(this.tagVals[n].value & BigInt(cat)) this.tags.push({edit:false, value:this.tagVals[n].value, text:this.tagVals[n].text});
      }

      this.background = 'rgba(255, 255, 255, 1)';
    },
  },

  data(){
        return {
          link:'',
          news_date:'',
          hasImage:true,
          description:'',
          title:'',
          tags:[],
          width:'calc(100% - 220px)',
          background:'rgba(255, 255, 255, 1)',
          w:window.innerWidth,
          updateImg:0.1,
          image:require('../assets/blank_news_icon.png'),
          imageConfig:{image:'', path:'private/news/image/', edit:false}
        }
  },

  async created(){

    window.addEventListener('resize', this.resize);

    this.link = this.config.source + '/' + this.config.path;

    let title = this.config.title.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

    this.title = utf8.decode(title);

    //await this.getImage();
    this.imageConfig.image = this.config.id;

    /*if(this.image.length < 100) {
      
      this.hasImage = false;

      this.width = '100%';

      this.description = this.config.description.substring(0, 300);

    } else {

      this.description = this.config.description.substring(0, 200);
    }*/

    this.description = this.config.description.substring(0, 200);

    //if(this.config.news_date) this.news_date = this.config.news_date;
    //else this.news_date = this.config.date_created;

    if(this.config.news_date) {
      
      try{
        
        this.news_date = format(parseISO(this.config.news_date), "dd MMM yyyy, HH") + "hrs";

      } catch(e) {

        let n = this.config.news_date.indexOf('T');

        if(n > -1){

          try{

            this.news_date = format(parseISO(this.config.news_date.substring(0, n)), "dd MMM yyyy");

          } catch(e){

            this.news_date = format(parseISO(this.config.date_created), "dd MMM yyyy, HH") + "hrs";
          }

        } else {

          this.news_date = format(parseISO(this.config.date_created), "dd MMM yyyy, HH") + "hrs";
        }
      }
    }

    if(!this.config.view) this.background = 'rgba(240, 240, 240, 1)';

    this.tags = [];

    for(let n in this.tagVals){

      this.tagVals[n].value = BigInt(Math.pow(2,  this.tagVals[n].n));

      if(this.tagVals[n].value & BigInt(this.config.cat)) this.tags.push({fontSize:'10px', background:'rgba(220, 220, 220, 1)', color:'rgba(50, 50, 50, 1)', edit:false, value:this.tagVals[n].value, text:this.tagVals[n].text});
    }
  },

  beforeUnmount(){

    window.removeEventListener('resize', this.resize);
  },

  methods: {

    editItem(){

      this.$emit('editItem', this.config);
    },

    resize(){

      this.w = window.innerWidth;
    },

    openTab(){

      window.open(this.link);
    }
  }
}

</script>

<style scoped>

a:link { 
  text-decoration: none; 
  color:rgba(51, 51, 51, 1);
}
a:visited { 
  text-decoration: none; 
  color:rgba(100, 100, 100, 1);
}
a:hover { 
  text-decoration: none; 
  color:rgba(240, 66, 0, 1);
  cursor:pointer;
}
a:active { 
  text-decoration: none; 
  color:rgba(51, 51, 51, 1);
}

.e230_1000{
  display:block;
  position:relative;
  width:80%;
  border-top:solid 3px #888888;
  padding:10px 0;
  margin:0 auto;
  background-color:v-bind('background');
  cursor:pointer;
}

.e230_1010{
  display:inline-block;
  width:200px;
  height:120px;
  margin:10px 20px 10px 0;
}

.e230_1012{
  width:100%;
  height:100%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor:pointer;
}

.e230_1015{
  vertical-align:top;
  width:v-bind('width');
  display:inline-block;
  text-align:left;
  font-family:'Open Sans';
}

.e230_1020 { 
	color:rgba(28, 28, 28, 1);
	width:100%;
  font-weight:700;
	font-size:18px;
  margin-left:9px;
  display:block;
  text-align:left;
  cursor:pointer;
}
.e230_1030 { 
  margin-top:5px;
	color:rgba(51, 51, 51, 1);
	width:100%;
  text-align:justify;
  font-weight:400;
	font-size:14px;
  line-height:2em;
  display:block;
}

.e230_1040 { 
	width:30px;
	height:30px;
	position:absolute;
	top: calc(100% - 50px);
  left: calc(100% - 30px);
  background-image:url('../assets/edit.png');
  background-size:cover;
  background-repeat:no-repeat;
  cursor:pointer;
  opacity:1;
}

.e230_1050 { 
  margin:5px 0;
  margin-left:9px;
  display:block;
	color:rgba(51, 51, 51, 1);
	width:100%;
  font-weight:400;
	font-size:12px;
  text-align:left;
}

.e230_1060 { 
  display:inline-block;
  margin-right:10px;
}

.e230_1070{
  position:absolute;
  top:18px;
  left:18px;
  height: 6px;
  width: 6px;
  background-color: #0f0;
  border-radius: 50%;
}

.e230_1080{
  font-weight:700;
  color:rgba(240, 66, 0, 1);
}
.e230_1080:hover{

  color:rgba(51, 51, 51, 1);
}

.e230_1100{
    width:100%;
    margin-top:35px;
}

.e230_1110{
    position:relative;
    text-align:left;
    
}

.e230_1120{
  display:inline-block;
  margin: 0 10px 10px 0;
  vertical-align:bottom;
}

@media only screen and (max-width:600px) {
  .e230_1010{
    display:block;
    margin:0 auto;
  }

  .e230_1015{
    width:100%;
    margin-top:10px;
  }
}

@media only screen and (min-width:600px) {

  .e230_1010{
    display:inline-block;
  }
}

@media only screen and (min-width:1200px) {

}
</style>

<div class="thumb-responsive lazy-load lazy-loaded" style="border-radius: 0px; margin-bottom: 30px; padding-bottom: 56.1247%; background-image: url(&quot;https://mezha.media/wp-content/uploads/2022/11/Rocket_Attack_03.jpg&quot;);" title="A massive missile attack was repelled: Air Defense Forces destroyed 54 of 69 missiles and 11 UAVs"></div>