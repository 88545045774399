<template>

    <div class="e330_1005">

        <div class="e330_1020">Contact Us</div>

        <div class="e330_1030">

            <InputField :config="emailConfig" @verify="emailVerify"/>

        </div>

        <div id="spin" class="e330_1050">

            <TextField :config="textConfig" @verify="textVerify" />

        </div>
        
        <div class="e330_1050">
            <ReCaptcha @verify="captchaVerify" />

            <div class="e330_1060">
                <ButtonActive :config="submit" :key="submit" @click="message" />
            </div>
        </div>
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'
import InputField from './InputField.vue'
import TextField from './TextField.vue'
import ReCaptcha from './ReCaptcha.vue'
import { Spinner } from 'spin.js';
import 'spin.js/spin.css';
import { API } from 'aws-amplify';
import { format } from 'date-fns'

export default {
    name: 'ContactUs',
    emits: ["message"],
    components: {
        ButtonActive,
        InputField,
        TextField,
        ReCaptcha,
    },


    props: {
        config:{
            type:Object,
        }
    },

    data(){
            return {
                headerBackground: 'white',
                captchaState:false,
                emailState:false,
                textState:false,
                text:'',
                email:'',
                requestor:{id:"name", type:"input", name:"name", title:"Requestor", placeholder:"", required:false, dataTest:""},
                emailConfig:{id:"email", type:"input", regx:this._EMAIL, name:"email", title:"Email", placeholder:"", required:true, dataTest:""},
                subject:{id:"email", type:"input", name:"email", title:"Subject", placeholder:"", required:false, dataTest:""},
                enquiry:{id:"email", name:"email", title:"Enquiry Type", dataTest:"", options: [
                    { text: 'General Enquiry', value: '0' },
                    { text: 'Media Enquiry', value: '1' },
                    { text: 'Investor Enquiry', value: '3' }
                ],},
                industry:{id:"email", type:"input", name:"email", title:"Request Industry", placeholder:"", dataTest:""},
                serviceOpt:{id:"email", name:"email", title:"Request Service",  dataTest:"", options: [
                    { text: 'Arrange a Meeting', value: '0' },
                    { text: 'Facade Inspection', value: '1' },
                    { text: 'Gas Concentration Measurement', value: '2' },
                    { text: 'Oil Tank Inspection', value: '3' }
                ],},
                textConfig:{id:"text", type:"input", name:"text", disabled:false, title:"Request", rows:5, placeholder:"", required:true, dataTest:""},

                submit:null,
                submitDisabled:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:true, highlightBorder:"#F04200", opacity:"0.4", text:"Submit", width:"140x"},

                submitReady:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Submit", width:"140px"},
            }
    },

    created(){

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });

        this.submit = this.submitDisabled
    },

    methods: {

        captchaVerify(result){

            this.captchaState = result;

            this.checkReady();
        },

        emailVerify(result){

            this.emailState = result.state;

            if(result.state) this.email = result.value;

            this.checkReady();
        },

        textVerify(result){

            this.textState = result.state;

            if(result.state) this.text = result.value;

            this.checkReady();
        },

        checkReady(){

            if(this.captchaState && this.emailState && this.textState){

                this.submit = this.submitReady;
            }
        },

        async message(){

            const apiName = 'dtApiGateway';
            const path = '/contact';

            /*const myInit = {
                body: {},
                headers: {
                "x-api-key":"CAeQNg9OQI8FARgTYX2GWaiPkRczKiN9785jYyn4"
                }
            };*/

            //var sessionToken = AWS.config.credentials.sessionToken;
            //console.log(sessionToken);
            //let user = await Auth.currentAuthenticatedUser()
            
            //console.log("USER", user.signInUserSession.idToken.jwtToken);

            const myInit = {
                body: {
                "date": format(new Date(), 'dd/MM/yy hh:mm:ss'),
                "email":this.email, 
                "message":this.text
                },
                headers: {
                //"Authorization":sessionToken, //user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            this.submit = this.submitDisabled;

            let res = await API.post(apiName, path, myInit);

            console.log(res);

            spinner.stop();

            this.$emit('message');
        }
    }
}

</script>

<style scoped>

.e330_1005{
    width:50%;
    margin:100px auto 0 auto;
    min-width:300px;
}

.e330_1020{
    height:50px;
    color:rgba(28, 28, 28, 1);
    font-family:'Open Sans';
    font-size:32px;
    font-weight:700;
}

.e330_1030{
    height:105px;
}

.e330_1050{
    height:220px;
}

.e330_1040{
    position:absolute;
    top:0px;
    left:0px;
}

.e280_1050{
    position:absolute;
    top:0px;
    left:442px;
}

.e330_1060{
    width:140px;
    height:60px;
    margin:20px auto;
}

.e330_1065{
    width:200px;
}

.spinner{
    position:absolute;
    top:96px;
    left:760px;
    height:50px;
    width:50px;
}

.e330_1070{
    width:100%;
    margin:0 auto;
}

@media only screen and (max-width: 600px) {
    .e330_1010{
        width:350px;
        height:180px;
    }

    .e330_1030{
        width:100%;
    }

    .e330_1050{
        width:100%;
        height:210px;
    }
}

@media only screen and (min-width: 600px) {

    .e330_1030{
        width:100%;
    }

    .e330_1050{
        width:100%;
        height:210px;
    }
}

@media only screen and (min-width: 1200px) {

    .e330_1030{
        width:100%;
    }

    .e330_1050{
        width:100%;
        height:210px;
    }
}
</style>