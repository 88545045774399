<template>
    <div class="e520_1000" @click="showDrone">

        <PhotoView  class="e520_1070 noselect" :config="droneImageConfig" :key="droneImageConfig.image" />

        <div class="e520_1015">
            <div class="e520_1020">
                <div class="e520_1030">{{config.name}}</div>
                <div class="e520_1040">{{config.make + " " + config.model}}</div>
            </div>

            <div class="e520_1025">
                <div class="e520_1050">{{config.flight_time}}</div>
                <div class="e520_1060">MINS</div>
            </div>
        </div>

        <div class="e520_1080">
            <ButtonActiveSm v-if="config.remove" class="e520_1085" :config="removeDroneBtn" @click="removeDrone" />
            <ButtonActiveSm v-else-if="config.select" class="e520_1085" :config="selectDroneBtn" @click="selectDrone" />
            <ButtonActiveSm v-else-if="config.unselect" class="e520_1085" :config="deSelectDroneBtn" @click="deSelectDrone" />
            <ButtonActiveSm v-else-if="config.add" class="e520_1085" :config="addDroneBtn" @click="addDrone" />
        </div>
    </div>
</template>

<script>
import PhotoView from './PhotoView.vue'
import ButtonActiveSm from './ButtonActiveSm.vue'

export default {
  name: 'UserDrone',
  emits: ["showDrone", "add", "remove", "select", "deselect"],

  components: {
    PhotoView,
    ButtonActiveSm,
  },

  props: {
    config: {
      type: Object,
    },
  },

  computed: {

    getImg: function(){

        return {
            "background-image":'url(' + this.image + ')',
        }
    },
  },

  data(){
        return {
            droneImageConfig:{image:'', path:'private/drone/thumbnail/', edit:false},

            addDroneBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Drone", width:"80px"},
            removeDroneBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Remove", width:"80px"},
            selectDroneBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Select", width:"80px"},
            deSelectDroneBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"De-Select", width:"100px"},
        }
  },

  async created(){

    this.droneImageConfig.image = this.config.image;
  },

  methods: {

    showDrone(){

        this.$emit('showDrone', this.config)
    },

    addDrone(){

        this.$emit('add', this.config.image)
    },

    selectDrone(){

        this.$emit('select', this.config)
    },

    deSelectDrone(){

        this.$emit('deselect', this.config.image)
    },

    removeDrone(){

        this.$emit('remove', this.config.image)
    }
  }
}

</script>

<style scoped>
.e520_1000{
    width:250px;
    font-family:'Open Sans';
    font-weight:400;
    font-size:12px;
    padding:5px;
    border:1px solid rgba(200, 200, 200, 1);
    background-color:rgba(255, 255, 255, 1);
    cursor:pointer;
    margin:5px;
    display:inline-block;
}

.e520_1015{
    margin:auto;
    width:200px;
}

.e520_1020{
    display:inline-block;
    width:69%;
}

.e520_1025{
    display:inline-block;
    width:30%;
}

.e520_1030{
    width:100%;
    font-weight:bold;
    text-align:left;
    
}

.e520_1040{
    width:100%;
    font-weight:normal;
    padding-top:5px;
    text-align:left;
}

.e520_1050{
    width:100%;
    font-weight:normal;
    font-size:20px;
}

.e520_1060{
    width:100%;
    font-weight:normal;
    font-size:10px;
}

.e520_1070{
  width:100%;
  height:165px;
  display:inline-block;
  cursor:pointer;
  margin:0 auto;
}

.e520_1080{
    width:80px;
    margin:0 auto;
    position:relative;
}

.e520_1085{
    margin:10px 0;
}


</style>