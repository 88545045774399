<template>
	<div id=header class="e269_1000">

		<div class=e269_1040>

			<div class=e269_1050>

				<div class=e269_1060>

					<div  class="e269_1070" @click="home"></div>

					<div class=e269_1080>

						<div v-if="type===0">

							<div id="269_1010" class="e269_1010" @mouseleave="clear" >
								<div class="e269_1020" v-for="option in industries" :key="option" @click="vertical_select(option.id)">{{option.title}}</div>
							</div>

							<div id="269_1030" class="e269_1030" @mouseleave="clear">
								<div class="e269_1020 " v-for="option in services" :key="option" @click="service_select(option.id)">{{option.title}}</div>
							</div>

							<div class=e269_1090  @click="vertical" @mouseenter="vertical_enter" @mouseleave="vertical_leave">
								<span id="t1" class="e269_1100 noselect" >Industries</span>

								<div id="ch1" class="e269_1110" @click="vertical"></div>

							</div>
							<div class=e269_1120 @click="service" @mouseenter="service_enter" @mouseleave="service_leave">
								<span id="t2" class="e269_1130 noselect" >Service</span>

								<div id="ch2" class="e269_1140" @click="service"></div>

							</div>
							<div class=e269_1150>
								<span  class="e269_1160 noselect" @click="contact">Contact Us</span>
							</div>
							<div class=e269_1170>
								<span  class="e269_1180 noselect" @click="quote">Free Quotation</span>
							</div>
							<div class=e269_1182>
								<span  class="e269_1183 noselect" @click="news">Global Drone News</span>
							</div>
						</div>

						<div v-else-if="type===1">
							
							<div class=e269_1090>
								<span  class="e269_1160 noselect" @click="home">Home</span>
							</div>

							<div class=e269_1121>
								<span  class="e269_1183 noselect" @click="showLogs">Pilots</span>
							</div>
						</div>

						<div v-else-if="type===2">
							
							<div class=e269_1090>
								<span  class="e269_1160 noselect" @click="home">Home</span>
							</div>

							<div class=e269_1121>
								<span  class="e269_1183 noselect" @click="news">Flight Logs</span>
							</div>
						</div>

					</div>
				</div>

				<div v-if="authUser">
					<div class=e269_1191>

						<span  class="e269_1195 noselect" @click="login">Dashboard</span>

						<div class=e269_1196 @click="profile"></div>
					</div>
					
				</div>

				<div v-else class=e269_1190>
					<div class=e269_1200>
						<ButtonActive :config="signUp" :key="signUp" @click="signup"/>
					</div>
					<div class=e269_1210>
						<ButtonActive :config="signIn" @click="login"/>
					</div>
				</div>

				<div class="e269_1220" @click="showResponsiveMenu"></div>
			</div>

		</div>
	</div>

	<div v-if="ResponsiveMenu" :key="ResponsiveMenu" >
        <ResponsiveMenu :config="config" :type="type" @login="login" @contact="contact" @signup="signup" @quote="quote" @home="home" @vertical="vertical_select" @service="service_select" @profile="profile" @close="closeResponsiveMenu" @news="news"/>
    </div>

</template>

<script>
import ButtonActive from './ButtonActive.vue'
import ResponsiveMenu from './ResponsiveMenu.vue'
import { Auth } from 'aws-amplify';

export default {
  name: 'DtHeader',
  emits: ["login", "contact", "signup", "home", "vertical", "service", "quote", "profile", "showResponsiveMenu", "news"],

  components: {
    ButtonActive,
	ResponsiveMenu
  },

  props: {
	
	headerBackground:{
		type:String,
	},

	type:{
		type:Number,
		default:0
	},

	config:{
		type: Object
	},
  },

  watch: {

		headerBackground: function(){

			this.background = this.headerBackground;

			if(this.background == 'transparent'){
				
				//document.getElementById("header").style.borderBottom = "none";

			} else {

				//document.getElementById("header").style.borderBottom = "1px solid #A0A0A0";
			}

			return this.headerBackground;
		},

	},

  data(){
        return {
			verticals:null,
			industries:null,
			services:null,
			background:'white',
			authUser:false,
			ResponsiveMenu:false,

            signUp:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1", text:"Sign up", width:"140px"},
            signIn:{color:"#ffffff", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1", text:"Log in", width:"140px"},
			cho:require('../assets/chevron-down-24_o.png'),
			chb:require('../assets/chevron-down-24.png'),
        }
  },

  created(){

		this.background = this.headerBackground;

		this.industries = this.config.industries;

		this.verticals = this.config.verticals;

		this.services = this.config.services;

		let _this = this;

		Auth.currentAuthenticatedUser()
		//.then(user => {
		.then(() => {

			_this.signUp.disabled = true;

			_this.authUser = true;

			//this.props.navigation.navigate("App");
		})
		//.catch(err => {
		.catch(() => {

			//console.log("ERROR", err);
		});
  },

  methods: {

	close(){

		this.closeResponsiveMenu();
	},

	showLogs(){

		this.$emit('showLogs');
	},

	home(){

		this.closeResponsiveMenu();

		this.$emit('home');
	},

    login(){

		this.$emit('login');
    },

	contact(){

		this.closeResponsiveMenu();

		this.$emit('contact');
	},

	quote(){

		this.closeResponsiveMenu();

		this.$emit('quote');
	},

	news(){

		this.closeResponsiveMenu();

		this.$emit('news');
	},

	profile(){

		this.closeResponsiveMenu();

		this.$emit('profile');
	},

	signup(){

		this.$emit('signup');
	},

	vertical_enter(){

		document.getElementById("t1").style.color = "rgba(240, 66, 0, 1)";
		document.getElementById("ch1").style.backgroundImage = "url('" + this.cho + "')";
	},

	vertical_leave(){

		document.getElementById("t1").style.color = "rgba(28, 28, 28, 1)";
		document.getElementById("ch1").style.backgroundImage = "url('" + this.chb + "')";
	},

	vertical_select(id){

		this.closeResponsiveMenu();

		this.$emit('vertical', id);
	},

	service_select(id){

		this.closeResponsiveMenu();

		this.$emit('service', id);
	},

	closeResponsiveMenu(){
      
      this.ResponsiveMenu = false;
    },

    showResponsiveMenu(){

      this.ResponsiveMenu = true;
    },

	service_enter(){

		document.getElementById("t2").style.color = "rgba(240, 66, 0, 1)";
		document.getElementById("ch2").style.backgroundImage = "url('" + this.cho + "')";
	},

	service_leave(){

		document.getElementById("t2").style.color = "rgba(28, 28, 28, 1)";
		document.getElementById("ch2").style.backgroundImage = "url('" + this.chb + "')";
	},

	vertical(){

		document.getElementById("269_1010").style.display = "block";
		document.getElementById("269_1030").style.display = "none";
	},

	clear(){

		document.getElementById("269_1010").style.display = "none";
		document.getElementById("269_1030").style.display = "none";
	},

	service(){
		document.getElementById("269_1010").style.display = "none";
		document.getElementById("269_1030").style.display = "block";
		document.getElementById("269_1030").style.zIndex = "1000";
	}
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.e269_1000{
	height:88px;
	display:block;
	position:fixed;
	width:100%;
	top:0px;
	left:0px;
	background-color:v-bind(background);
	z-index:100;
}

.e269_7026 { 
	background-color:v-bind(background); /*rgba(255, 255, 255, 1);*/
	width:100%;
	height:88px;
	position:fixed;
	top:0px;
	left:calc((100% - 1440px)/2);
	z-index:2;
}

.e269_1010{
	position:absolute;
	top:40px;
	left:0px;
	width:230px;
	display:none;
	z-index:50;
}

.e269_1030{
	position:absolute;
	top:40px;
	left:150px;
	width:200px;
	display:none;
	z-index:50;
}

.e269_1020{
	background-color:rgba(240, 66, 0, 1);
	color:rgba(255, 255, 255, 1);
	width:230px;
	padding:10px 10px;
	position:relative;
	font-family:'Open Sans';
	font-weight:400;
	text-align:left;
	font-size:16px;
	cursor:pointer;
}

.e269_1020:hover{
	background-color:rgba(255, 255, 255, 1);
	color:rgba(240, 66, 0, 1);
}

.e269_1220{
	display:none;
	width:60px;
	height:60px;
	position:absolute;
	left:calc(100% - 70px);
	top:0px;
	background-image:url(../assets/hamburger.png);
	background-repeat:no-repeat;
	background-size:cover;
	cursor:pointer;
}
.e269_1040 { 
	width:100%;
	height:60px;
	display:block;
	margin-top:14px;
}
.e269_1050 { 
	width:100%;
	height:60px;
	position:relative;
	margin:0 auto;
}
.e269_1060 { 
	width:100%;
	height:60px;
	position:absolute;
	left:0px;
	top:0px;
}
.e269_1070 { 
	width:50px;
    height:50px;
    position:absolute;
    left:24px;
    top:0px;
    background-image:url(../assets/logo_vertical_1.png);
    background-repeat:no-repeat;
    background-size:cover;
}
.e269_1080 { 
	width:520px;
	height:24px;
	position:absolute;
	left:100px;
	top:18px;
}
.e269_1090 { 
	width:113px;
	height:24px;
	position:absolute;
	left:0px;
	top:0px;
}
.e269_1100 { 
	color:rgba(28, 28, 28, 1);
	width:81px;
	height:22px;
	position:absolute;
	left:0px;
	top:1px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}


.e269_1090_166_2877 { 
	transform: rotate(90.00000000000001deg);
	width:24px;
	height:24px;
	position:absolute;
	left:89px;
	top:0px;
}
.e269_1110 { 
	background-image:url('../assets/chevron-down-24.png');
	background-size:cover;
	background-repeat:no-repeat;
	width:16px;
	height:8px;
	position:absolute;
	left:90px;
	top:10px;
}

.e269_1110:hover { 
	background-image:url('../assets/chevron-down-24_o.png');
	cursor:pointer;
}
.e269_1120 { 
	width:89px;
	height:24px;
	position:absolute;
	left:149px;
	top:0px;
}

.e269_1121 { 
	width:89px;
	height:24px;
	position:absolute;
	left:100px;
	top:0px;
}

.e269_1130 { 
	color:rgba(28, 28, 28, 1);
	width:57px;
	height:22px;
	position:absolute;
	left:0px;
	top:1px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
}


.e269_1140 { 
	background-image:url('../assets/chevron-down-24.png');
	background-size:cover;
	background-repeat:no-repeat;
	width:16px;
	height:8px;
	position:absolute;
	left:65px;
	top:10px;
}

.e269_1140:hover { 
	background-image:url('../assets/chevron-down-24_o.png');
	cursor:pointer;
}

.e269_1150 { 
	position:absolute;
	left:274px;
	top:1px;
}
.e269_1160 { 
	color:rgba(28, 28, 28, 1);
	width:100px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e269_1160:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e269_1170 { 
	width:150px;
	height:22px;
	position:absolute;
	left:400px;
	top:1px;
}
.e269_1180 { 
	color:rgba(28, 28, 28, 1);
	width:150px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e269_1180:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e269_1182 { 
	position:absolute;
	left:550px;
	top:1px;
}
.e269_1183 { 
	color:rgba(28, 28, 28, 1);
	width:160px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e269_1183:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e269_1190 { 
	width:286px;
	height:58px;
	position:absolute;
	left:calc(100% - 300px);
	top:1px;
}

.e269_1191 { 
	width:286px;
	height:50px;
	position:absolute;
	left:calc(100% - 200px);
	top:9px;
}

.e269_1195 { 
	color:rgba(28, 28, 28, 1);
	width:86px;
	height:22px;
	position:absolute;
	left:0px;
	top:10px;
	font-family:'Open Sans';
	font-weight:bold;
	text-align:left;
	font-size:16px;
}

.e269_1196 { 
	position:absolute;
	left:100px;
	top:0px;
	width:50px;
	height:50px;
	background-image:url('../assets/profile.png');
	background-size:cover;
	background-repeat:no-repeat;
	cursor:pointer;
}

.e269_1200 { 
	position:absolute;
	left:0px;
	top:0px;
}


.i269_1200 { 
	border:1px solid rgba(240, 66, 0, 1);
}
.e269_1200_76_2381 { 
	color:rgba(240, 66, 0, 1);
	width:70px;
	height:26px;
	position:absolute;
	left:32px;
	top:12px;
	font-family:'Open Sans';
	text-align:center;
	font-size:20px;
}

.e269_1210 { 
	position:absolute;
	left:150px;
	top:0px;
}

.e269_1210_166_2858 { 
	color:rgba(240, 66, 0, 1);
	width:62px;
	height:26px;
	position:absolute;
	left:32px;
	top:12px;
	font-family:'Open Sans';
	text-align:center;
	font-size:20px;
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.e269_1220{
		width:40px;
		height:40px;
		top:10px;
		display:block;
	}

	.e269_1080{
		display:none;
	}

	.e269_1190{
		display:none;
	}

	.e269_1191{
		display:none;
	}
}

@media only screen and (min-width: 600px){
	.e269_1220{
		width:40px;
		height:40px;
		left:calc(100% - 70px);
		top:10px;
		display:block;
	}

	.e269_1080{
		display:none;
	}

	.e269_1190{
		display:none;
	}

	.e269_1191{
		display:none;
	}
}

@media only screen and (min-width: 1030px) {

	.e269_1080{
		display:block;
	}

	.e269_1190{
		display:block;
	}

	.e269_1191{
		display:block;
	}

	.e269_1220{
		display:none;
	}
}

@media only screen and (min-width: 1200px) {

	.e269_1080{
		display:block;
	}

	.e269_1190{
		display:block;
	}

	.e269_1191{
		display:block;
	}

	.e269_1220{
		display:none;
	}
}

</style>
