<template>
  
    <div class=ei271_10149_168_4087>
      <div class=ei271_10149_168_4088>
        <div class=ei271_10149_168_4089>
          <div  class="ei271_10149_168_4090"></div>
          <div class=ei271_10149_168_4093>
            <div  class="ei271_10149_168_4094"></div>
            <div class=ei271_10149_271_9316>
              <span  class="ei271_10149_168_4095 noselect">©2020 Drone Technology </span>
              <div class=ei271_10149_168_4096>
                <div class=ei271_10149_168_4097><div class=ei271_1010></div></div>
                <div class=ei271_10149_168_4098><div class=ei271_1020></div></div>
                <div class=ei271_10149_168_4099><div class=ei271_1030></div></div>
                <div class=ei271_10149_168_4100><div class=ei271_1040></div></div>
              </div>
            </div>
          </div>
        </div>
      
		<div class=ei271_10149_271_8415>
			<div class=ei271_10149_271_6773>
				<div  class="ei271_10149_208_20165"></div>
				<span  class="ei271_10149_168_4092 noselect">Drone Tech Pte. Ltd. is a Singapore registered company started by industry professionals who are passionate about using the latest in drone technology to provide innovative, affordable aerial sensing, survey and inpection solutions. By leveraging the latest in drone technology, sensor miniaturisation and cloud computing, we can deliver fast turnaround of even the most challenging projects giving our clients the data they need to make informed decisions.</span>
			</div>
			<div class=ei271_10149_271_7634>
				<div class=ei271_10149_168_4101>
					<span  class="ei271_10149_168_4102">Commercial Real Estate</span>
					<span  class="ei271_10149_168_4103">Industries</span>
					<span  class="ei271_10149_168_4104">Heavy Industry</span>
					<span  class="ei271_10149_168_4105">Oil & Gas</span>
					<span  class="ei271_10149_168_4106">Environmental Monitoring</span>
				</div>
				<div class=ei271_10149_168_4107>
					<span  class="ei271_10149_168_4108">Gas Measurement</span>
					<span  class="ei271_10149_168_4109">Services</span>
					<span  class="ei271_10149_168_4110">Inspection & Survey</span>
					<span  class="ei271_10149_168_4111">3D Model Reconstruction</span>
					<span  class="ei271_10149_168_4112">Radiation Measurement</span>
					<span  class="ei271_10149_168_4113">Aerial Photography</span>
					<span  class="ei271_10149_168_4114">Digital Twin & Simulation</span>
				</div>
				<div class=ei271_10149_168_4115>
					<span  class="ei271_10149_168_4116" @click="contact">Contact Us</span>
				</div>
					<div class=ei271_10149_168_4120>
						<span  class="ei271_10149_168_4121" @click="quote">Free Quotation</span>
					</div>
				</div>
			</div>
		</div>
    </div>

</template>

<script>
export default {
	name: 'DtFooter',
	emits:['contact', 'quote'],
	props: {
		msg: String
	},

	methods: {

		contact(){

			this.$emit('contact');
		},

		quote(){

			this.$emit('quote');
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.ei271_10149_168_4087 { 
	width:100%;
	height:480px;
	position:relative;
	background-color:rgba(236, 240, 246, 1);
}
.ei271_10149_168_4088 { 
	height:480px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.ei271_10149_168_4089 { 
	height:480px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_10149_168_4090 { 
	background-color:rgba(236, 240, 246, 1);
	height:480px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_10149_168_4093 {
	height:60px;
	position:absolute;
	left:0px;
	top:420px;
}
.ei271_10149_168_4094 { 
	height:60px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_10149_271_9316 { 
	width:1256px;
	height:24px;
	position:absolute;
	left:70px;
	top:18px;
}
.ei271_10149_168_4095 { 
	color:rgba(130, 130, 130, 1);
	width:176px;
	height:18px;
	position:absolute;
	left:0px;
	top:3px;
	font-family:'Open Sans';
	text-align:center;
	font-size:13px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}
.ei271_10149_168_4096 { 
	width:130px;
	height:24px;
	position:absolute;
	left:1126px;
	top:0px;
}
.ei271_10149_168_4097 { 
	width:24px;
	height:24px;
	position:absolute;
	left:0px;
	top:0px;
}

.ei271_1010{
	margin:2px;
	width:20px;
	height:20px;
	background-image:url(../assets/Facebook.png);
	background-repeat:no-repeat;
	background-size:contain;
}

.ei271_10149_168_4098 { 
	width:24.685714721679688px;
	height:24px;
	position:absolute;
	left:35px;
	top:0px;
}

.ei271_1020{
	margin:2px;
	width:20px;
	height:20px;
	background-image:url(../assets/Instagram.png);
	background-repeat:no-repeat;
	background-size:contain;
}
.ei271_10149_168_4099 { 
	width:24.685714721679688px;
	height:24px;
	position:absolute;
	left:67px;
	top:0px;
}

.ei271_1030{
	margin:2px;
	width:20px;
	height:20px;
	background-image:url(../assets/twitter.png);
	background-repeat:no-repeat;
	background-size:contain;
}

.ei271_10149_168_4100 { 
	width:24.685714721679688px;
	height:24px;
	position:absolute;
	left:105px;
	top:0px;
}

.ei271_1040{
	margin:2px;
	width:20px;
	height:20px;
	background-image:url(../assets/Youtube.png);
	background-repeat:no-repeat;
	background-size:contain;
}

.ei271_10149_271_8415 { 
	width:1220.7139892578125px;
	height:315px;
	position:absolute;
	left:81px;
	top:71px;
}
.ei271_10149_271_6773 { 
	width:567px;
	height:315px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_10149_208_20165 { 
	width:287.33563232421875px;
	height:60px;
	position:absolute;
	left:0px;
	top:0px;
	background-image:url(../assets/horizontal_logo_black_2.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.ei271_10149_168_4092 { 
	color:rgba(88.00000235438347, 87.00000241398811, 87.00000241398811, 1);
	width:567px;
	height:231px;
	position:absolute;
	left:0px;
	top:84px;
	font-family:'Open Sans';
	text-align:left;
	font-size:18px;
	letter-spacing:0;
	line-height:px;
}
.ei271_10149_271_7634 { 
	width:600px;
	height:214px;
	position:absolute;
	left:655px;
	top:0px;
}
.ei271_10149_168_4101 { 
	width:200px;
	height:150px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei271_10149_168_4102 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:32px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:0;
	line-height:px;
}
.ei271_10149_168_4103 { 
	color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:164.57142639160156px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}

.ei271_10149_168_4103:hover{
	color:rgba(28, 28, 28, 1);
	cursor:pointer;
}

.ei271_10149_168_4104 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:64px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:0;
	line-height:px;
}
.ei271_10149_168_4105 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:96px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4106 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:128px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4107 { 
	width:200px;
	height:214px;
	position:absolute;
	left:220px;
	top:0px;
}
.ei271_10149_168_4108 { 
	color:rgba(88.00000235438347, 87.00000241398811, 87.00000241398811, 1);
	width:165px;
	height:22px;
	position:absolute;
	left:0px;
	top:32px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4109 { 
	color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:164.57142639160156px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}

.ei271_10149_168_4109:hover{
	color:rgba(28, 28, 28, 1);
	cursor:pointer;
}

.ei271_10149_168_4110 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:64px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4111 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:96px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4112 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:128px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4113 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:192px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4114 { 
	color:rgba(88, 87, 87, 1);
	width:200px;
	height:22px;
	position:absolute;
	left:0px;
	top:160px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}
.ei271_10149_168_4115 { 
	width:164px;
	height:182px;
	position:absolute;
	left:440px;
	top:0px;
}
.ei271_10149_168_4116 { 
	color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:164.57142639160156px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}

.ei271_10149_168_4116:hover{
	color:rgba(28, 28, 28, 1);
	cursor:pointer;
}

.ei271_10149_168_4120 { 
	width:164px;
	height:182px;
	position:absolute;
	left:440px;
	top:32px;
}

.ei271_10149_168_4121 { 
	color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:164.57142639160156px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:16px;
	letter-spacing:-0.10000000149011612;
	line-height:px;
}

.ei271_10149_168_4121:hover{
	color:rgba(28, 28, 28, 1);
	cursor:pointer;
}

@media only screen and (max-width: 600px) {

	.ei271_10149_168_4088 { 
		left:calc((100% - 350px)/2);
		top:0px;
	}

	.ei271_10149_271_8415 { 
		width:350px;
		left:0px;
		top:50px;
	}

	.ei271_10149_271_6773 { 
		width:100%;
	}

	.ei271_10149_208_20165 { 
		width:287px;
		height:60px;
		position:absolute;
		left:calc((100% - 287px)/2);
		top:0px;
	}

	.ei271_10149_271_7634{
		display:none;
	}

	.ei271_10149_168_4087 { 
		height:480px;
	}

	.ei271_10149_168_4093 {
		top:410px;
	}

	.ei271_10149_168_4092 { 
		width:100%;
		font-size:16px;
		text-align:center;
	}

	.ei271_10149_271_9316 { 
		width:350px;
		left:0px;
		top:0px;
	}

	.ei271_10149_168_4095 { 
		width:200px;
		left:calc((100% - 200px)/2);
		top:0px;
		font-size:14px;
	}

	.ei271_10149_168_4096 { 
		width:130px;
		left:calc((100% - 130px)/2);
		top:30px;
	}
}

@media only screen and (min-width: 600px) {

	.ei271_10149_168_4087 { 
		height:480px;
	}
	
	.ei271_10149_168_4088 { 
		left:calc((100% - 500px)/2);
		top:0px;
	}

	.ei271_10149_271_6773 { 
		width:100%;
		height:315px;
		position:absolute;
		left:0px;
		top:0px;
	}

	.ei271_10149_271_8415 { 
		width:500px;
		height:315px;
		position:absolute;
		left:0px;
		top:50px;
	}

	.ei271_10149_208_20165 { 
		width:287px;
		height:60px;
		position:absolute;
		left:calc((100% - 287px)/2);
		top:0px;
	}

	.ei271_10149_271_7634{
		display:none;
	}


	.ei271_10149_168_4092 { 
		width:100%;
		height:231px;
		top:84px;
		font-size:16px;
		text-align:center;
	}

	.ei271_10149_168_4093 {
		top:350px;
	}

	.ei271_10149_271_9316 { 
		width:500px;
		height:24px;
		position:absolute;
		left:0px;
		top:0px;
	}

	.ei271_10149_168_4095 { 
		width:200px;
		height:18px;
		left:calc((100% - 200px)/2);
		top:0px;
		font-size:14px;
	}

	.ei271_10149_168_4096 { 
		width:130px;
		height:24px;
		position:absolute;
		left:calc((100% - 130px)/2);
		top:30px;
	}
}

@media only screen and (min-width: 1200px) {
	.ei271_10149_168_4088 { 
		left:calc((100% - 1440px)/2);
		top:0px;
	}

	.ei271_10149_271_8415 { 
		width:1220px;
		left:81px;
		top:71px;
	}
	.ei271_10149_271_6773 { 
		width:567px;
	}
	.ei271_10149_208_20165 { 
		height:60px;
		position:absolute;
		left:0px;
		top:0px;
	}

	.ei271_10149_271_7634 { 
		display:block;
	}

	.ei271_10149_168_4093 {
		top:380px;
	}

	.ei271_10149_168_4092 { 
		width:567px;
		font-size:18px;
		text-align:left;
	}

	.ei271_10149_271_9316 { 
		width:1256px;
		left:70px;
		top:18px;
	}

	.ei271_10149_168_4095 { 
		width:176px;
		top:3px;
		left:0px;
		font-size:13px;
	}

	.ei271_10149_168_4096 { 
		width:130px;
		left:1126px;
		top:0px;
	}
}

</style>
