<template>
    <div class="e130_1000">

        <div class="e130_1010" @click="close"></div>

        <div v-if="flightInProgress" class="e130_1020">

            <div class="e130_1090">Flight In Progress</div>

            <div class="e130_1040">

                <div class="e130_1050">{{strInterval}}</div>

                <div class="e130_1060">H   H   :   M   M   :   S   S</div>

                <div class="e130_1070">
                    <div class="e130_1080">
                        <ButtonActive :config="stopLog" @click="stopFlightLog" />
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="e130_1020">

            <div class="e130_1090">{{title}}</div>

            <div class="e130_1102">

                <div id="droneImg" class="e130_1134">
                    <PhotoView  class="e130_1190" :config="droneImageConfig" :key="droneImageConfig.image" />
                </div>
                <!--<div id="droneImg" class="e130_1134">
                    <div class="e130_1190" :style="getImg" @click="showDroneList" ></div>
                </div>-->
                <div id=dronex class="e130_1133">
                    <SelectField :config="modelSelect" @change="droneChange" />
                </div>
            </div>

            <div class="e130_1100">

                <div id="type" class="e130_1131">
                    <SelectField :config="typeConfig" @change="typeChange" />
                </div>

                <div id=contractx class="e130_1132">
                    <SelectField :config="contractConfig" @change="contractChange" />
                </div>

                
            </div>

            <div class="e130_1100">
                <div class="e130_1110">
                    <InputField :config="dateConfig" />
                </div>

                <div class="e130_1120">
                    <InputField :config="durConfig" />
                </div>
            </div>

            <div class="e130_1100"><InputField :config="locConfig" /></div>

            <div class="e130_1100">

                <div class="e130_1130">
                    <InputField :config="latConfig" />
                </div>

                <div class="e130_1150">
                    <InputField :config="longConfig" />
                </div>
            </div>

            <div class="e130_1100">

                <div class="e130_1130">
                    <InputField :config="altConfig" />
                </div>

                <div class="e130_1150">
                    <InputField :config="aenConfig" />
                </div>
            </div>

            <div class="e130_1160"><MapView :config="config" @metar="metar" /></div>

            <div class="e130_1170"><TextField :config="metarConfig" /></div>

            <div class="e130_1170"><TextField :config="tafConfig" /></div>

            <div class="e130_1180">Pre-Flight Checklist</div>

            <div class="checkbox-form">
                <div class="answers">
                    <label
                        v-for="(option, i) of options"
                        :key="i"
                        class="item"
                    >
                    <CheckBox :option="option" @input="cbValue"/>
                    </label>
                </div>
            </div>

            <div id="spin" class="e130_1170"><TextField :config="textConfig" /></div>

            <div class="e130_1040">

                <div v-if="isNew" class="e130_1080">
                    <ButtonActive :config="startLog" @click="flightLog" />
                </div>

                <div v-else class="e130_1080">
                    <ButtonActive :config="updateLog" @click="updateFlightLog" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'
import InputField from './InputField.vue'
import TextField from './TextField.vue'
import MapView from './MapView.vue'
import CheckBox from './CheckBox.vue'
import SelectField from './SelectField.vue'
import PhotoView from './PhotoView.vue'

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';
//import * as AWS from 'aws-sdk';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns'
import { ms } from 'date-fns/locale' // Malaysia locale

import awsconfig from '../aws-exports';

API.configure(awsconfig);

export default {
    name: 'NewFlightLog',
    emits: ['close'],
    components: {
        ButtonActive,
        InputField,
        TextField,
        SelectField,
        MapView,
        CheckBox,
        PhotoView,
    },


    props: {
        config:{
            type:Object,
        },
        user:{
            type:Object,
        },
    },

    computed: {

    },

    data(){
            return {
                headerBackground: 'rgb(255, 255, 255, 0.4)',

                options:[
                    {label:'Props Undamaged & Secure', id:1, checked:false, disabled:false},
                    {label:'Batteries Charged & Secure', id:2, checked:false, disabled:false},
                    {label:'Airframe Undamaged', id:3, checked:false, disabled:false},
                    {label:'Prop Guards Secure', id:4, checked:false, disabled:false},
                    {label:'Transmitter ON', id:5, checked:false, disabled:false},
                    {label:'Aircraft Power ON', id:6, checked:false, disabled:false},
                    {label:'Software / Firmware Update', id:7, checked:false, disabled:false},
                    {label:'Transmitter Configuration OK', id:8, checked:false, disabled:false},
                    {label:'Transmitter Controls OK', id:9, checked:false, disabled:false},
                    {label:'GPS Satellites OK', id:10, checked:false, disabled:false},
                    {label:'Obstacle Detection ON', id:11, checked:false, disabled:false},
                    {label:'Transmitter Power OK', id:12, checked:false, disabled:false},
                ],

                cbVal:0,
                checklist:0,
                isNew:true,
                flightInProgress:this.flightInProgress,
                startTime:0,
                timer:0,
                interval:0,
                drone:0,
                flightType:1,
                contract:0,
                type:0,
                title:'',
                b64:require('../assets/drone_blank.png'),
                image:null,
                strInterval:'00:00:00',
                RECREATION:this._RECREATION,
                EDUCATION:this._EDUCATION,
                BUSINESS:this._BUSINESS,
                droneImageConfig:{image:'', path:'private/drone/image/', edit:false},


                dateConfig:{id:"date", type:"input", regx:this._DATE, name:"date", disabled:true, title:"Start Date/Time", placeholder:"", value:this.config.user_start, required:false, dataTest:""},
                durConfig:{id:"duration", type:"input", regx:this._TEXT, name:"duration", disabled:true, title:"Duration (HH:MM:SS)", placeholder:"", value:this.config.duration, required:false, dataTest:""},
                locConfig:{id:"loc", type:"input", regx:this._LOCATION, name:"loc", disabled:true, title:"Location", placeholder:"", value:this.config.location, required:false, dataTest:""},
                latConfig:{id:"lat", type:"input", regx:this._TEXT, name:"lat", disabled:true, title:"Latitude", placeholder:"", value:this.config.latitude, required:false, dataTest:""},
                longConfig:{id:"long", type:"input", regx:this._TEXT, name:"long", disabled:true, title:"Longitude", placeholder:"", value:this.config.longitude, required:false, dataTest:""},
                textConfig:{id:"notes", type:"input", name:"notes", disabled:false, title:"Notes", rows:3, placeholder:"", required:false, dataTest:""},
                metarConfig:{id:"metar", type:"input", name:"metar", disabled:true, title:"METAR", rows:3,  placeholder:this.config.metar, required:false, dataTest:""},
                tafConfig:{id:"taf", type:"input", name:"taf", disabled:true, title:"TAF", rows:3, placeholder:this.config.taf, required:false, dataTest:""},
                altConfig:{id:"alt", type:"input", regx:this._TEXT, name:"alt", disabled:true, title:"Altitude above MSL", placeholder:"", value:this.config.altitude, required:false, dataTest:""},
                aenConfig:{id:"aen", type:"input", regx:this._TEXT, name:"aen", disabled:false, title:"Windspeed (site)", placeholder:"", value:this.config.windspeed, required:false, dataTest:""},

                startLog:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Start", width:"132px"},
                updateLog:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Update", width:"132px"},
                stopLog:{color:"#FFFFFF", background:"#0042F0", border:"#0042F0", disabled:false, highlightBorder:"#0042F0", opacity:"1.0", text:"Stop", width:"132px"},

                typeConfig:{id:"reason", name:"reason", title:"Reason", value:0, required:true, dataTest:"", options: [
                    { text: 'Education', value: this._RECREATION },
                    { text: 'Recreation', value: this._EDUCATION },
                    { text: 'Contract', value: this._CONTRACT },
                    { text: 'Training', value: this._TRAINING }
                ],},

                contractConfig:{id:"contract", name:"contract", title:"Contract", value:0, dataTest:"", options: [
                    { text: 'Select ...', value: 0 },
                    { text: 'Other', value: 0 }
                ],},

                droneConfig:{id:"drone", name:"drone", title:"Drone", value:0, dataTest:"", options: [
                    { text: 'Select ...', value: 0 },
                    { text: 'Other', value: 0 }
                ],},

                userDrones:[],
                modelSelect:{id:"model", name:"company", title:"Model", value:0, dataTest:"", required:true, options: [
                { text: 'Select ...', value: 0, selected:true }
            ],},
            }
    },

    async created(){

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });

        let n = 1;

        for(let opt of this.options){

            if(this.config.data.checklist&n) opt.checked = true;

            if(this.config.data.user_start) opt.disabled = true;

            n *= 2;
        }
        this.checklist = this.config.data.checklist;

        this.locConfig.value =  this.config.data.location;

        this.altConfig.value =  this.config.data.altitude;

        this.aenConfig.value =  this.config.data.windspeed;

        this.title = this.config.title;

        if(this.config.data.id){
            
            this.dateConfig.disabled = true;
            this.durConfig.disabled = true;
            this.locConfig.disabled = true;
            this.latConfig.disabled = true;
            this.longConfig.disabled = true;
            this.metarConfig.disabled = true;
            this.tafConfig.disabled = true;
            this.altConfig.disabled = true;
            this.aenConfig.disabled = true;

            this.textConfig.value = this.config.data.notes;

            this.typeConfig.disabled = true;

            this.type = this.config.data.type;

            this.drone = this.config.data.drone;

            this.typeConfig.value = this.type;

            this.isNew = false;
        }

        this.contractConfig.options = [
            { text: 'Select ...', value: 0 }
        ];

        /*this.config.companies.forEach(company => {

            this.companyConfig.options.push({text:company.name, value:company.id});
        });*/

        this.contractConfig.options.push({ text: 'Other', value: 0 });

        this.droneOpt = {id:0, name:'', image:'', b64:this.b64, make:0, model:0, serial:'', flight_time:0, previous_hrs:0};

        const apiName = 'dtApiGateway';
        const path = '/getDrones';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };
        
        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        user = this.user;

        let info = await API.post(apiName, path, data);

        user.make = info.make;

        user.model = info.model;

        user.drones.forEach(drone => {

            let make = user.make.filter(item => item.id === drone.make);

            let model = user.model.filter(item => item.id === drone.model);

            drone.b64 = (drone.b64)?(drone.b64.length)?drone.b64:this.blank:this.blank;

            let d = {id:drone.id, name:drone.name, image:drone.image, b64:drone.b64, make:make[0], model:model[0], serial:drone.serial, flight_time:drone.flight_time, previous_hrs:drone.previous_hrs};

            this.userDrones.push(d);

            if(d.id === this.drone) {

                this.droneOpt = d;

                if(document.getElementById("droneImg")) document.getElementById("droneImg").style.display = "block";

                //this.b64 = d.b64;
                this.droneImageConfig.image = d.image;

                if(this.b64.length) document.getElementById("dronex").style.display = "none";
            }
        });

        this.modelSelect.options = [];

        this.modelSelect.options.push({ text: 'Select ...', value: 0});

        info.model.forEach(model => this.modelSelect.options.push({ text: model.name, value: model.id, image:model.image }));

        spinner.stop();
    },

    mounted(){

        document.getElementById("duration").value = new Date(this.config.data.duration * 1000).toISOString().substr(11, 8);

        document.getElementById("date").value = format(new Date(), "dd/MM/yy HH:mm:ss");

        document.getElementById("type").style.display = "block";

        if(this.isNew) document.getElementById("dronex").style.display = "block";

        document.getElementById("notes").value = this.config.data.notes;

        this.typeChange(this.type);
    },

    methods: {

        seconds(){

            this.interval = (new Date() - this.startTime) / 1000;

            this.strInterval = new Date(this.interval * 1000).toISOString().substr(11, 8);
        },

        cbValue(val){

            if(val.checked){

                this.cbVal |= Math.pow(2, (val.id - 1));

            } else {

                this.cbVal &= ~(Math.pow(2, (val.id - 1)));
            }
        },

        typeChange(type){

            this.flightType = parseInt(type.value);

            if(this.flightType === this._CONTRACT){

                document.getElementById("contractx").style.display = "block";

            } else {

                document.getElementById("contractx").style.display = "none";
            }
        },

        contractChange(contract){

            this.contract = parseInt(contract.value);
        },

        showDroneList(){

            document.getElementById("dronex").style.display = "block";

            document.getElementById("droneImg").style.display = "none";
        },

        async droneChange(drone){

            document.getElementById("droneImg").style.display = "block";

            this.droneImageConfig.image = this.userDrones.filter(item => item.model.id === parseInt(drone.value))[0].image;
        },

        close(){

            this.$emit('close');
        },

        metar(report){

            document.getElementById("metar").value = report.metar;

            document.getElementById("taf").value = report.taf;
        },

        async updateFlightLog(){

            this.flightInProgress = false;

            const apiName = 'dtApiGateway';
            const path = '/updateFlightLog';

            let user = await Auth.currentAuthenticatedUser()

            const data = {
                body: {
                    "id": this.config.data.id,
                    "notes":document.getElementById("notes").value, 
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            //var target = document.getElementById('spin');

            //var spinner = new Spinner(this.spinnerOpts).spin(target);

            //spinner.spin(target);

            await API.post(apiName, path, data);

            //spinner.stop();

            this.close();
        },

        async stopFlightLog(){

            this.flightInProgress = false;

            const apiName = 'dtApiGateway';
            const path = '/updateFlightLog';

            let user = await Auth.currentAuthenticatedUser()

            const data = {
                body: {
                    "user_end": format(new Date(), 'dd/MM/yy hh:mm:ss'),
                    "duration":this.interval, 
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            //var target = document.getElementById('spin');

            //var spinner = new Spinner(this.spinnerOpts).spin(target);

            //spinner.spin(target);

            await API.post(apiName, path, data);

            //spinner.stop();

            clearInterval(this.timer);

            this.close();
        },

        async flightLog(){

            const apiName = 'dtApiGateway';
            const path = '/addFlightLog';

            /*const myInit = {
                body: {},
                headers: {
                "x-api-key":"CAeQNg9OQI8FARgTYX2GWaiPkRczKiN9785jYyn4"
                }
            };*/

            //var sessionToken = AWS.config.credentials.sessionToken;
            //console.log("session token: " + sessionToken);
            
            let user = await Auth.currentAuthenticatedUser();

            this.startTime = new Date();
            
            //console.log("USER token: ", user.signInUserSession.idToken.jwtToken);

            const data = {
                body: {
                "user_start": format(new Date(), 'dd/MM/yy hh:mm:ss', { locale: ms }),
                "user_end": format(new Date(), 'dd/MM/yy hh:mm:ss', { locale: ms }),
                "type":this.flightType,
                "drone":this.drone,
                "contract":(document.getElementById("contract"))?document.getElementById("contract").value:0, 
                "location":document.getElementById("loc").value, 
                "latitude":document.getElementById("lat").value, 
                "longitude":document.getElementById("long").value, 
                "windspeed":document.getElementById("aen").value, 
                "altitude":document.getElementById("alt").value, 
                "metar":document.getElementById("metar").value, 
                "taf":document.getElementById("taf").value, 
                "checklist":this.cbVal,
                "notes":document.getElementById("notes").value, 
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            await API.post(apiName, path, data);

            spinner.stop();

            this.flightInProgress = true;

            this.timer = setInterval(this.seconds, 1e3)
        }
    }
}

</script>

<style scoped>
.e130_1000{
    width:100%;
    position:relative;
}

.e130_1010{
    width:16px;
    height:16px;
    position:absolute;
    left:calc(100% - 40px);
    top:10px;
    background-image:url(../assets/close.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

.e130_1020{
    width:80%;
    margin:0 auto;
    min-width:300px;
    max-width:500px;
}

.e130_1040{
    width:100%;
    position:relative;
    margin:0 auto;
    height:100px;
}

.e130_1050{
    position:relative;
    height:50px;
    margin: 50px auto;
    font-family: 'Open Sans';
    font-weight:400;
    font-size:48px;
}

.e130_1060{
    position:relative;
    margin: 20px auto;
    font-family: 'Open Sans';
    font-weight:400;
    font-size:20px;
    color:rgba(220, 220, 220, 1);
}

.e130_1070{
    position:relative;
    margin: 50px auto;
}

.e130_1080{
    position:absolute;
    top:96px;
    left:710px;
}

.e130_1090{
    margin:20px auto;
    color:rgba(28, 28, 28, 1);
    font-family:'Open Sans';
    font-size:48px;
    font-weight:700;
}

.e130_1100{
    width:100%;
    position:relative;
    margin:0 auto;
    height:105px;
}

.e130_1101{
    display:none;
    width:100%;
    position:relative;
    margin:0 auto;
    height:105px;
}

.e130_1102{
    width:100%;
    position:relative;
    margin:0 auto;
}

.e130_1110{
    width:55%;
    float:left;
    position:relative;
    height:105px;
}

.e130_1120{
    width:42%;
    float:right;
    position:relative;
    height:105px;
}

.e130_1130{
    width:42%;
    float:left;
    position:relative;
    height:105px;
}

.e130_1131{
    width:30%;
    float:left;
    position:relative;
    height:105px;
    margin-right:5%;
    display:none;
}

.e130_1132{
    width:60%;
    float:right;
    position:relative;
    height:105px;
    display:none;
}

.e130_1133{
    width:50%;
    position:relative;
    margin:0 auto;
    height:105px;
    display:none;
}

.e130_1134{
    display:none;
    margin:0 auto;
    width:250px;
}

.e130_1150{
    width:48%;
    float:right;
    position:relative;
    height:105px;
}

.e130_1140{
    display:none;
    width:48%;
    float:right;
    position:relative;
    height:105px;
}

.e130_1141{
    display:none;
    width:48%;
    float:right;
    position:relative;
    height:105px;
}

.e130_1170{
    width:100%;
    position:relative;
    margin:auto;
}



.e330_1040{
    position:absolute;
    top:0px;
    left:0px;
}



.e130_1160{
    width:100%;
    position:relative;
    margin:0 auto 20px auto;
    height:300px;
}

.e130_1180{
    width:100%;
    position:relative;
    margin:25px auto;
    font-family:'Open Sans';
    font-size:18px;
    font-weight:700;
}

.e130_1190{
  width:300px;
  height:200px;
  display:flex;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  cursor:pointer;
  margin:10px auto;
}


.spinner{
    position:absolute;
    top:96px;
    left:760px;
    height:50px;
    width:50px;
}

.checkbox-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.checkbox-form .answers {
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
}

.checkbox-form label {
	margin-left: 1em;
}

.checkbox-form .item {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 1em;
	height: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
}

.checkbox-form .item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-form .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #c2c2c2;
}

.checkbox-form .item:hover input ~ .checkmark {
	background-color: #949494;
}

.checkbox-form .item input:checked ~ .checkmark {
	background-color: #D8A22E;
}

.checkbox-form .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-form .item .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {

    .e130_1100{
        width:100%;
    }

    .e130_1090{
        font-size:24px;
        font-weight:700;
    }

    .e130_1080{
        top:10px;
        left:calc((100% - 132px)/2);
    }

}

@media only screen and (min-width: 600px) {

    .e130_1100{
        width:100%;
    }

    .e130_1170{
        width:100%;
    }

    .e130_1080{
        width:100%;
        height:500px;
    }

    .e130_1090{

    }

    .e130_1080{
        top:110px;
        left:calc((100% - 132px)/2);
    }

    .e130_1160{
        height:400px;
    }

}

@media only screen and (min-width: 1200px) {

    .e130_1100{
        width:100%;
    }

    .e130_1170{
        width:100%;
    }

    .e130_1090{

    }

    .e130_1080{
        top:110px;
        left:calc((100% - 132px)/2);
    }

    .e130_1160{
        height:600px;
    }

}
</style>