<template>

    <div class="e290_1000">

        <div class=e290_1010>

            <div class=e290_1020>

                <div class=e290_10130>

                    <div  class="e290_1040" @click="home"></div>

                    <div class=e290_1050>

                        <div class=e290_1070>
                            <span  class="e290_1060 noselect" @click="dashboard">Dashboard</span>
                        </div>

                        <div class=e290_1075>
                            <span  class="e290_1060 noselect" @click="showProjects">Projects</span>
                        </div>

                        <div class=e290_1080>
                            <span  class="e290_1060 noselect" @click="showContracts">Contracts</span>
                        </div>

                        <div class=e290_1085>
                            <span  class="e290_1060 noselect" @click="showBids">Bids</span>
                        </div>

                        <div class=e290_1090>
                            <span  class="e290_1060 noselect" @click="showPilots">Pilots</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class=e290_1195 @click="signOut">Logout</div>

        <div class=e290_1110 @click="profile"></div>

        <div class="e290_1120" @click="showResponsiveMenu"></div>
    </div>



    <div v-if="ResponsiveMenu" class=e290_1200 >

        <div class=e290_1210  @click="home"></div>
        <div class=e290_1220 @click="closeResponsiveMenu"></div>

        <div class=e290_1230>
            <div class=e290_1240>
                <div  class="e290_1250 noselect" @click="home">Home</div>
            </div>
        </div>

        <div class=e290_1230>
            <div class=e290_1241>
                <div  class="e290_1250 noselect" @click="dashboard">Dashboard</div>
            </div>
        </div>

        <div class=e290_1230>
            <div class=e290_1242>
                <div  class="e290_1250 noselect" @click="showProjects">Projects</div>
            </div>
        </div>

        <div class=e290_1230>
            <div class=e290_1243>
                <div  class="e290_1250 noselect" @click="showContracts">Contracts</div>
            </div>
        </div>

        <div class=e290_1230>
            <div class=e290_1244>
                <div  class="e290_1250 noselect" @click="showBids">Bids</div>
            </div>
        </div>

        <div class=e290_1230>
            <div class=e290_1245>
                <div  class="e290_1250 noselect" @click="showPilots">Pilots</div>
            </div>
        </div>

        <div class=e290_1190 @click="signOut">Logout</div>

		<div class=e290_1260 @click="profile"></div>

    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
  name: 'ResponsiveMenu',
  emits: ["home", "dashboard", "close", "profile", "showProjects", "showContracts", "showPilots", "showBids"],

  components: {

  },

  props: {

  },

  data(){
        return {
            ResponsiveMenu:false,
			background:'#ECF0F6',
        }
  },

  created(){

	
  },

  methods: {

    closeResponsiveMenu(){
      
      this.ResponsiveMenu = false;
    },

    showResponsiveMenu(){

      this.ResponsiveMenu = true;
    },

	home(){

        this.closeResponsiveMenu();

		this.$emit('home');
	},

    profile(){

        this. closeResponsiveMenu();

        this.$emit('profile');
    },

    close(){

        this.$emit('close');
    },
    
    showProjects(){

        this.closeResponsiveMenu();

        this.$emit('showProjects');
    },

    showContracts(){

        this.closeResponsiveMenu();

        this.$emit('showContracts');
    },

    showBids(){

        this.closeResponsiveMenu();

        this.$emit('showBids');
    },

    showPilots(){

        this.closeResponsiveMenu();

        this.$emit('showPilots');
    },

    dashboard(){

        this.closeResponsiveMenu();

        this.$emit('dashboard');
    },

    async signOut() {

        try {

            await Auth.signOut();

            this.$emit('home');

        } catch (error) {

            console.log('error signing out: ', error);
        }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.e290_1000{
    margin:14px;
	height:60px;
	width:100%;
	background-color:rgba(255, 255, 255, 1);
	z-index:100;
	/*border-bottom:1px solid #282828;*/
}


.e290_1010{
    width:100%;
    z-index:1000;
}

.e290_1020 { 
	width:100%;
	height:60px;
	position:relative;
	margin:0 auto;
}
.e290_1030 { 
	width:100%;
	height:60px;
	position:absolute;
	left:0px;
	top:0px;
}
.e290_1040 { 
	width:50px;
	height:50px;
	position:absolute;
	left:10px;
	top:5px;
	background-image:url(../assets/logo_vertical_1.png);
	background-repeat:no-repeat;
	background-size:cover;
	cursor:pointer;
}

.e290_1050 { 
	width:520px;
	height:24px;
	position:absolute;
	left:100px;
	top:18px;
}

.e290_1060 { 
	color:rgba(28, 28, 28, 1);
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	font-size:16px;
}

.e290_1060:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_1070 { 
	position:absolute;
	left:0px;
	top:0px;
}

.e290_1075 { 
	position:absolute;
	left:120px;
	top:0px;
}

.e290_1080 { 
	position:absolute;
	left:210px;
	top:0px;
}

.e290_1085 { 
	position:absolute;
	left:320px;
	top:0px;
}

.e290_1090 { 
	position:absolute;
	left:380px;
	top:0px;
}

.e290_1100 { 
	position:absolute;
	left:calc(100% - 60px);
	top:7px;
}

.e290_1110 { 
	position:absolute;
	left:calc(100% - 70px);
	top:6px;
	width:50px;
	height:50px;
	background-image:url('../assets/profile.png');
	background-size:cover;
	background-repeat:no-repeat;
	cursor:pointer;
}

.e290_1120{
	display:none;
	width:60px;
	height:60px;
	position:absolute;
	left:calc(100% - 70px);
	top:0px;
	background-image:url(../assets/hamburger.png);
	background-repeat:no-repeat;
	background-size:cover;
	cursor:pointer;
}

.e290_1190 { 
	position:absolute;
    width:60px;
    height:20px;
	left:calc(100% - 75px);
	top:350px;
    font-family:'Open Sans';
	font-weight:700;
	font-size:16px;
	cursor:pointer;
	z-index:10;
}

.e290_1190:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_1195 { 
	position:absolute;
	left:calc(100% - 140px);
	top:17px;
	font-family:'Open Sans';
	font-weight:700;
	font-size:16px;
}

.e290_1195:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}


.e290_1200{
    width:100%;
    min-width:200px;
    height:400px;
    position:fixed;
    top:0px;
    left:0px;
    background-color:v-bind(background);
    z-index:1000;   
}

.e290_1210{
    width:50px;
    height:50px;
    position:absolute;
    left:24px;
    top:19px;
    background-image:url(../assets/logo_vertical_1.png);
    background-repeat:no-repeat;
    background-size:cover;
}

.e290_1220{
    width:16px;
    height:16px;
    position:absolute;
    left:calc(100% - 30px);
    top:32px;
    background-image:url(../assets/close.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

.e290_1230 { 
	margin-left:30px;
}

.e290_1240 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:100px;
}

.e290_1241 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:150px;
}

.e290_1242 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:200px;
}

.e290_1243 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:250px;
}

.e290_1244 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:300px;
}

.e290_1245 { 
	width:110px;
	height:22px;
	position:absolute;
	left:0px;
	top:350px;
}

.e290_1250 { 
	color:rgba(28, 28, 28, 1);
	width:110px;
	height:22px;
    margin-left:20px;
	font-family:'Open Sans';
	font-weight:bold;
	text-align:left;
	font-size:16px;
}

.e290_1250:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}


.e290_1260 { 
	position:absolute;
	left:calc(100% - 100px);
	top:15px;
	width:50px;
	height:50px;
	background-image:url('../assets/profile.png');
	background-size:cover;
	background-repeat:no-repeat;
	cursor:pointer;
	z-index:10;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 630px) {

    .e290_1050{
        display:none;
    }

    .e290_1110{
        display:none;
    }

    .e290_1195{
        display:none;
    }

    .e290_1120{
        width:40px;
        height:40px;
        top:10px;
        display:block;
    }
}

@media only screen and (min-width: 600px){

}

@media only screen and (min-width: 980px) {

}

@media only screen and (min-width: 1200px) {

}

</style>
