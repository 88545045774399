<template>
    <div class=e310_1000>
        <div  class=e310_1010>
			<div class=e310_1020>

				<VerticalOffering v-for="option in config.verticals" :key="option" :config="option" @vertical="vertical"/>

				<div class=e310_1040>
					<div class=e310_1050>
						<div class=e310_1060>
						<div  class="e310_1070"></div>
						</div>
					</div>
					<div class=e310_1080>
						<div class=e310_1090>
							<div  class=e310_1100></div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </div>
</template>

<script>
import VerticalOffering from './VerticalOffering.vue'

export default {
    name: 'VerticalOfferingContainer',
    emits: ["vertical"],

    components: {
        VerticalOffering,
    },

    props: {
		config:{
			type:Object,
		}
    },

	data(){
        return {
		}
	},

    methods: {

		vertical(id){

			this.$emit('vertical', id);
		},
    }
}

</script>

<style scoped>
.e310_1000 { 
	height:700px;
	position:relative;
}
.e310_1010 { 
	width:1440px;
	background-color:rgba(255, 255, 255, 1);
	height:850px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.e310_1020 { 
	height:654px;
	width:100%;
	position:relative;
	margin:0 auto;
}

.e310_1040 { 
	height:44px;
	position:absolute;
	left:60px;
	top:133px;
}
.e310_1050 { 
	box-shadow:0px 0px 10px rgba(0, 0, 0, 0.15000000596046448);
	background-color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:44px;
	height:44px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:32px;
	border-top-right-radius:32px;
	border-bottom-left-radius:32px;
	border-bottom-right-radius:32px;
}
.e310_1060 { 
	/*transform: rotate(-180deg);*/
	width:24px;
	height:24px;
	position:absolute;
	left:14px;
	top:10px;
}
.e310_1070 { 
	background-image:url(../assets/chevron-left-24.png);
	background-repeat:no-repeat;
	background-size:cover;
	width:7.75px;
	height:14px;
	position:absolute;
	left:4.5px;
	top:5px;
}

.e310_1080 { 
	box-shadow:0px 0px 10px rgba(0, 0, 0, 0.15000000596046448);
	transform: rotate(180deg);
	background-color:rgba(240.00000089406967, 66.00000366568565, 0, 1);
	width:44px;
	height:44px;
	position:absolute;
	left:1278px;
	top:0px;
	border-top-left-radius:32px;
	border-top-right-radius:32px;
	border-bottom-left-radius:32px;
	border-bottom-right-radius:32px;
}
.e310_1090 { 
	width:24px;
	height:24px;
	position:absolute;
	left:34px;
	top:10px;
}

.e310_1100 { 
	background-image:url(../assets/chevron-left-24.png);
	background-repeat:no-repeat;
	background-size:cover;
	width:7.75px;
	height:14px;
	position:absolute;
	left:-16px;
	top:5px;
}

@media only screen and (max-width: 600px) {

}

</style>