<template>
    <div class="e340_1000">

        <div class="e340_1005">

            <div class="e340_1020 noselect">Flight logs</div>

            <div  v-for="option in flightLogs" :key="option"> 
            
                <div v-if="option.year">
                    <div :id=option.id class="e340_1100 noselect" @click="toggleView(option.id)"></div>
                    <div class="e340_1080">{{option.year}}</div>
                </div>
                <div v-else-if="option.month">
                    <div v-if="option.view">
                        <div :id=option.id class="e340_1100 noselect" @click="toggleView(option.id)"></div>
                        <div class="e340_1090">{{option.month}}</div>
                    </div>
                </div>
                <div class="e340_1120" v-else>
                    <div v-if="option.view">
                        <FlightLog class="e340_1110" :config="option" @showLog="showLog"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FlightLog from './FlightLog.vue'

import { format } from 'date-fns'
//import { ms } from 'date-fns/locale' // Malaysia locale

export default {
  name: 'FlightDashboard',
  emits: ["newFlightLog", "showLog"],

  components: {
    FlightLog,
  },

  props: {
    config: {
      type: Object,
    },
  },

  data(){
        return {
            headerBackground: 'rgb(255, 255, 255, 0.4)',
            flightLogs:[],
            companies:[],
            projects:[],
            newLog:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"New Flight Log", width:"220px"},
        }
  },

  async mounted(){
    
    this.flightLogs = [];

    let year = '0000';
    let month = 'mmm';
    let id = 0;

    for(let fl of this.config.logs){

        let item;

        let yr = format(new Date(fl.date_created), "yyyy");

        let mth = format(new Date(fl.date_created), "LLLL");

        if(!(yr === year)){

            item = {id:id++, toggle:false, view:false, year:null, month:null};

            year = yr;

            item.year = year;

            item.view = true;

            this.flightLogs.push(item);
        }

        if(!(mth === month)){

            item = {id:id++, toggle:false, view:false, year:null, month:null};

            month = mth;

            item.month = month;

            this.flightLogs.push(item);
        }

        item = {id:id++, toggle:false, view:false, year:null, month:null, location:null, duration:0, data:fl};

        item.location = fl.location;

        item.duration = fl.duration;

        item.time = format(new Date(fl.date_created), "dd-MM-yyyy HH:mm:ss");

        this.flightLogs.push(item);
    }
  },

  created(){

    this.background = this.backgroundColor;
  },

  methods: {

    toggleView(id){

        if(this.flightLogs[id].toggle){

            document.getElementById(id).style.transform = 'rotate(0deg)';

            this.flightLogs[id].toggle = false;

            if(this.flightLogs[id].year){

                while(!this.flightLogs[++id].year){

                    this.flightLogs[id].view = false;

                    if(id === this.flightLogs.length - 1) break;
                }

            } else {

                while(!this.flightLogs[++id].month){

                    this.flightLogs[id].view = false;

                    if(id === this.flightLogs.length - 1) break;
                }
            }
            

        } else {

            document.getElementById(id).style.transform = 'rotate(90deg)';

            this.flightLogs[id].toggle = true;

            if(this.flightLogs[id].year){

                while(!this.flightLogs[++id].year){

                    if(this.flightLogs[id].month) this.flightLogs[id].view = true;

                    if(id === this.flightLogs.length - 1) break;
                }

            } else {

                while(!this.flightLogs[++id].month){

                    this.flightLogs[id].view = true;

                    if(id === this.flightLogs.length - 1) break;
                }
            }
        }
    },

    showLog(data){

        this.$emit('showLog', {data:data.data})
    },

    newFlightLog(){

        this.$emit('newFlightLog');
    },
  }
}

</script>

<style scoped>
.e340_1000{
    width:100%;
    position:relative;
}

.e340_1005{
    width:70%;
    margin:0 auto;
    min-width:300px;
}

.e340_1020{
    width:100%;
    color:rgba(28, 28, 28, 1);
    font-family:'Open Sans';
    font-size:32px;
    font-weight:700;
}

.e340_1040{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e340_1045{
    width:200px;
    display:inline-block;
    position:relative;
    font-family:'Open Sans';
    font-size:18px;
    font-weight:700;
}

.e340_1051{
    width:100%;
    position:relative;
    margin:20px auto;
    height:100px;
}

.e340_1060{
    position:absolute;
    top:0px;
    left:calc((100% - 220px)/2);
}

.e340_1070{
    width:100%;
    position:relative;
    margin:0px auto 20px auto;
    height:300px;
}

.e340_1080{
    width:90%;
    display:inline-block;
    position:relative;
    text-align:left;
    font-size:22px;
    font-weight:bold;
}

.e340_1090{
    width:90%;
    display:inline-block;
    position:relative;
    text-align:left;
    font-size:18px;
    font-weight:bold;
}

.e340_1100{
    width:12px;
    height:12px;
    margin-right:10px;
    position:relative;
    display:inline-block;
    background-image:url(../assets/arrow_right.png);
    background-size:cover;
    background-repeat:none;
    cursor:pointer
}

.e340_1110{
    display:inline-block;
}

@media only screen and (max-width: 600px) {
    .e340_1010{
        width:350px;
        height:180px;
    }

    .e340_1030{
        width:100%;
    }
}

@media only screen and (min-width: 600px) {
    .e340_1010{
        width:600px;
        height:100px;
    }

    .e340_1030{
        width:100%;
    }

    .e340_1050{
        width:100%;
    }

    .e340_1070{
        height:400px;
    }

}

@media only screen and (min-width: 1200px) {
    .e340_1010{
        width:350px;
        height:100px;
    }

    .e340_1030{
        width:100%;
    }

    .e340_1050{
        width:100%;
    }

    .e340_1070{
        height:600px;
    }

}
</style>