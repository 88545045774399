<template>

	<div class="e76_1000">
		<div class=e76_1875>

			<div class="e76_1010"><div  class="e76_1876"></div></div>
		</div>

		<div class=e269_7055>

			<div  class="e269_7061 noselect">Professional Drone Solutions For Sensing, Survey and Inspection</div>
			<div  class="e269_7062 noselect">State of the art drone platforms for sensing, inspection and survey tasks. We specialise in providing fast and affordable services for facade inspection and gas emission measurement.</div>

			<div class=e269_7063>
				<ButtonActive :config="data" @click="quote" />
			</div>

			<div class=e269_7064>
				<div  class="e269_7065"></div>
				<div  class="e269_7066"></div>
				<div  class="e269_7067"></div>
				<div  class="e269_7068"></div>
			</div>

		</div>
	</div>

</template>

<script>

import ButtonActive from './ButtonActive.vue'

export default {
    name: 'MainSection',
    emits: ["quote"],

    components: {
        ButtonActive,
    },

    props: {

		headerBackground:{
			type:String,
		},

		config:{
			type:Object,
		}
    },

	watch: {

		headerBackground: function(){

			this.background = this.headerBackground;

			return this.headerBackground;
		},

	},

	created(){

		this.industries = this.config.industries;

		this.services = this.config.services;

		this.verticals = this.config.verticals;
	},

    data(){
        return {
			background:'transparent',
			industries:null,
			verticals:null,
			services:null,
			data:{color:"#FFFFFF", background:"#F04200", border:"#F04200", opacity:"1", text:"Free Quotation", width:"230px"},
        }
    },

    methods: {
		
		quote(){

			this.$emit('quote');
		},
    }
}

</script>

<style scoped>

.e76_1000{
	width:100%;
	background-color:rgba(142, 185, 219, 1);
	position:relative;
	margin:0 auto;
}
.e76_1875 { 
	width:100%;
	height:775px;
	position:relative;
	margin:0 auto;
}

.e76_1010{
	-webkit-transform: scale(1.0) rotate(0.01deg);
    transform: scale(1.0) rotate(0.01deg);
}
.e76_1876 { 
	width:2816px;
	height:805px;
	position:absolute;
	left:calc((100% - 2816px)/2);
	top:-30px;
	background-image:url(../assets/landing_img_02.png);
}

.e269_7055 { 
	z-index:1;
	width:955px;
	height:390px;
	position:absolute;
	left:calc((100% - 955px)/2);
	top:320px;
}

.e269_7061 { 
	color:rgba(255, 255, 255, 1);
	width:100%;
	height:110px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:center;
    font-weight:bold;
	font-size:48px;
	text-shadow: 0 0 4px rgba(0, 0, 0 , 0.4);
	letter-spacing:-1.2;
	line-height:1.2em;
	z-index:1;
}
.e269_7062 { 
	color:rgba(236, 240, 246, 1);
	width:95%;
	height:90px;
	position:absolute;
	left:18px;
	top:130px;
	font-family:'Open Sans';
	text-align:center;
	font-size:24px;
}
.e269_7063 { 
	width:208px;
	position:absolute;
	left:calc((100% - 230px)/2);
	top:270px;
	z-index:5;
}

.e269_7064 { 
	width:159px;
	height:20px;
	position:absolute;
	left:calc((100% - 159px)/2);
	top:370px;
}
.e269_7065 { 
	border-radius:500px;
	background-color:rgba(240, 66, 0, 1);
	width:20px;
	height:20px;
	position:absolute;
	left:0px;
	top:0px;
}
.e269_7066 { 
	border-radius:500px;
	background-color:rgba(255, 255, 255, 1);
	width:15px;
	height:15px;
	position:absolute;
	left:48px;
	top:2.5px;
}
.e269_7067 { 
	border-radius:500px;
	background-color:rgba(255, 255, 255, 1);
	width:15px;
	height:15px;
	position:absolute;
	left:96px;
	top:2.5px;
}
.e269_7068 { 
	border-radius:500px;
	background-color:rgba(255, 255, 255, 1);
	width:15px;
	height:15px;
	position:absolute;
	left:144px;
	top:2.5px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

	.e76_1875 { 
		height:578px;
	}

	.e76_1010{
		-webkit-transform: scale(0.6) rotate(0.01deg);
		transform: scale(0.6) rotate(0.01deg);
	}

	.e76_1876 { 
		width:1247px;
		height:994px;
		position:absolute;
		left:calc((100% - 1140px)/2);
		top:-30px;
		background-image:url(../assets/landing_img_03.png);
	}

	.e269_7055 { 
		width:350px;
		height:100px;
		position:absolute;
		left:calc((100% - 350px)/2);
		top:240px;
	}

	.e269_7061 { 
		height:130px;
		font-size:32px;
		line-height:1.0em;
	}
	.e269_7062 { 
		width:350px;
		left:calc((100% - 350px)/2);
		height:90px;
		top:110px;
		font-size:16px;
		line-height:1.2em;
	}

	.e269_7063 { 
		top:225px;
	}

	.e269_7064 { 
		top:300px;
	}
}

@media only screen and (min-width: 600px) {
	.e76_1875 { 
		height:578px;
	}

	.e76_1010{
		-webkit-transform: scale(0.8) rotate(0.01deg);
		transform: scale(0.8) rotate(0.01deg);
	}


	.e269_7055 { 
		width:600px;
		height:100px;
		position:absolute;
		left:calc((100% - 600px)/2);
		top:270px;
	}

	.e269_7061 { 
		height:130px;
		font-size:32px;
		line-height:1.0em;
	}
	.e269_7062 { 
		width:550px;
		left:calc((100% - 550px)/2);
		height:90px;
		top:110px;
		font-size:16px;
		line-height:1.2em;
	}

	.e269_7063 { 
		top:200px;
	}

	.e269_7064 { 
		top:280px;
	}
}

@media only screen and (min-width: 1200px) {

	.e76_1875 { 
		height:775px;
	}

	.e76_1010{
		-webkit-transform: scale(1.0) rotate(0.01deg);
		transform: scale(1.0) rotate(0.01deg);
	}
	.e76_1876 { 
		width:2816px;
		height:805px;
		left:calc((100% - 2816px)/2);
	}

	.e269_7055 { 
		z-index:1;
		width:955px;
		height:390px;
		left:calc((100% - 955px)/2);
		top:320px;
	}

	.e269_7061 { 
		height:110px;
		font-size:48px;
		line-height:1.2em;
		z-index:1;
	}
	.e269_7062 { 
		width:95%;
		left:18px;
		top:130px;
		font-size:24px;
	}
	.e269_7063 { 
		top:270px;
	}

	.e269_7064 { 
		top:370px;
	}
}

</style>