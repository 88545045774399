<template>
    <div class="e010_1000 noselect">

        <input v-if="config.edit" @change="fileChanged" class="e010_1020" type=file :id="id" accept="image/png, image/gif, image/jpeg" />

        <div :id="imgId" class="e010_1010 noselect" :style="getImg" @click="newImage"></div>
    </div>

</template>

<script>

import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

export default {
  name: "PhotoView",
  emits: ["imageChange"],

  components: {
      //Button
  },

  props: {
    config:{
      type:Object,
    }
  },

  computed: {

    getImg: function(){

          return {
              "background-image":'url(' + this.image + ')',
          }
      },
    },

  data(){
        return {
            id:'',
            imgId:'',
            image:require('../assets/blank_news_icon.png'),
        }
  },

  async created(){

    this.id = this.config.image;

    this.imgId = "img_" + this.config.image;

    await this.getImage();
  },

  methods: {

    newImage(){

        if(this.config.edit) document.getElementById(this.id).click();
    },

    fileChanged(event){

        var selectedFile = event.target.files[0];

        var reader = new FileReader();

        let _this = this;

        if(selectedFile.size < 3000000){

            reader.onload = function(event) {

                 _this.image = event.target.result;

                document.getElementById(this.imgId).style.backgroundImage = 'url("' + _this.image + '")';

                _this.$emit("imageChange", _this.image);
            };

        } else {

            this.userImage =  this.sizeWarning;
        }

        reader.readAsDataURL(selectedFile);
    },

    async getImage(){

        const apiName = 'dtApiGateway';
        const path = '/image';

        //let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                path:this.config.path,
                image:this.config.image
            },
            headers: {
                //"Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        let image = await API.post(apiName, path, data);

        if(image.length) this.image = image
    },
  }
}

</script>

<style scoped>

.e010_1000{
  display:flex;
  cursor:pointer;
}

.e010_1010{
  width:100%;
  height:100%;
  background-size: contain;
  background-repeat: no-repeat;
  cursor:pointer;
}

.e010_1020{
    display:none;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>