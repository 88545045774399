<template>
    <div class="button-active-large noselect" @click="clicked">
        <div class=".button-active-large button-active-large-text noselect">{{config.text}}</div>
    </div>
</template>

<script>

export default {
    name: 'ButtonActiveSm',
    emits: ['click'],

    components: {
        //Button
    },

    props: {

        config: {
            type: Object,
        },
    },

    data(){
        return {
            disabled:false,
            background:'#F04200',
            color:'#ffffff',
            opacity:1.0,
            width:'500px',
            border:'#F04200',
            highlightColor:'#ffffff',
            highlightBackground:'#F04200',
            highlightBorder:'#F04200',
            cursor:'default',
        }
    },

    created(){

        this.background = this.config.background;

        this.color = this.config.color;

        this.width = this.config.width;

        this.border = this.config.border;

        this.disabled = this.config.disabled;

        if(this.disabled) {

            this.opacity = 0.4;

            this.highlightColor = this.color;

            this.highlightBackground = this.background;

            this.highlightBorder = this.config.highlightBorder;

            this.cursor = 'default';

        } else {
            
            this.opacity = 1.0;

            this.highlightColor = this.background;

            this.highlightBackground = this.color;

            this.highlightBorder = this.config.highlightBorder;

            this.cursor = 'pointer';
        }
    },

    methods: {

        clicked(){

            if(!this.disabled) this.$emit('click');
        }

    }
}

</script>

<style scoped>
.button-active-large{
    width:v-bind(width);
    background-color:v-bind(background);
    color:v-bind(color);
    opacity:v-bind(opacity);
    height: 30px;
    padding: 6px 10px;
    text-align:center;
    border:1px solid v-bind(border);
    border-radius:18px;
    cursor:v-bind(cursor);
}

.button-active-large .button-active-large-text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;
}

.button-active-large:hover{
    background-color:v-bind(highlightBackground);
    color:v-bind(highlightColor);
    border:1px solid v-bind(highlightBorder);
}
</style>