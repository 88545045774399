<template>
    <div class=ei270_7504_168_3836>
        <div class=ei270_7504_168_3837>
        <div class=ei270_7504_168_3838>
            <div class=ei270_7504_270_7078>
            <div class=ei270_7504_270_7073>
                <div  class="ei270_7504_168_3840" :style="getImg"></div>
                <span  class="ei270_7504_168_3841 noselect">{{config.title}}</span>
                <span  class="ei270_7504_168_3842 noselect">{{config.text}}</span>
            </div>
            <div class=ei270_7504_168_3843>
                <ButtonActive :config="data" @click="vertical"/>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'

export default {
    name: 'VerticalOffering1',
    emits: ["vertical"],

    components: {
        ButtonActive
    },

	props: {
		config: Object,
	},

    computed: {

        getImg: function(){

            return {
                "background-image":'url(' + encodeURI(this.config.img_sm) + ')',
            }
        },


    },

	data(){
        return {
			id: 0,
			data:{color:"#FFFFFF", background:"#F04200", disabled:false, highlightBorder:"#F04200", text:"Learn More", width:"180px"},
        }
    },

	created(){

		this.id = this.config.id;
	},

    methods: {

		vertical(){

			this.$emit('vertical', this.id);
		},
    }
}

</script>

<style scoped>

.ei270_7504_168_3836 { 
	width:403px;
	height:653px;
    margin:0 16px;
    position:relative;
	display:inline-block;
}
.ei270_7504_168_3837 { 
	width:403.7938537597656px;
	height:653.82666015625px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_7504_168_3838 { 
	width:403.7938537597656px;
	height:653.82666015625px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_7504_270_7078 { 
	width:403.7938537597656px;
	height:653.82666015625px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_7504_270_7073 { 
	width:403.7938537597656px;
	height:595.82666015625px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_7504_168_3840 { 
	width:403px;
	height:310px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:12px;
	border-top-right-radius:12px;
	border-bottom-left-radius:12px;
	border-bottom-right-radius:12px;
	background-repeat:no-repeat;
	background-size:cover;
}
.ei270_7504_168_3841 { 
	color:rgba(28.000000230968, 28.000000230968, 28.000000230968, 1);
	width:403.7938537597656px;
	height:31px;
	position:absolute;
	left:0px;
	top:322px;
	font-family:'Open Sans';
	text-align:center;
    font-weight:700;
	font-size:24px;
	letter-spacing:0;
	line-height:px;
}
.ei270_7504_168_3842 { 
	color:rgba(51.00000075995922, 51.00000075995922, 51.00000075995922, 1);
	width:398.8880920410156px;
	height:230.82666015625px;
	position:absolute;
	left:2.452880859375px;
	top:365px;
	font-family:'Open Sans';
	text-align:center;
	font-size:16px;
	letter-spacing:0;
}
.ei270_7504_168_3843 { 
	position:absolute;
	left:115px;
	top:596px;
}
.ei270_7504_168_3843_76_2138 { 
	color:rgba(255, 255, 255, 1);
	width:115px;
	height:26px;
	position:absolute;
	left:32px;
	top:16px;
	font-family:'Open Sans';
	text-align:center;
	font-size:20px;
	letter-spacing:-0.20000000298023224;
	line-height:px;
}

</style>