<template>

    <div class="e520_1000" @click="showContracts">
        <div class="e520_1010">
            <div class="e520_1070">
                <div class="e520_1080" :style="getImg" ></div>
            </div>
        </div>

        <div class="e520_1015">
            <div class="e520_1030 noselect">{{config.name}}</div>
            <div class="e520_1040 noselect">{{date}}</div>
            <div class="e520_1045 noselect">{{config.location}}</div>
            <div class="e520_1048 noselect">{{config.description}}</div>
       
            <div class="e520_1100">
                <div class="e520_1090">
                    <ButtonActiveSm :config="addContractBtn" @click="newContract" />
                </div>
            </div>
        </div>
        <div class="e520_1110" @click="editProject" ></div>
    </div>
</template>

<script>

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns'

import ButtonActiveSm from './ButtonActiveSm.vue'

export default {
  name: "ProjectTile",
  emits: ["editProject", "showProject", "newContract", "showContracts"],

  components: {
    ButtonActiveSm,
  },

  props: {
    config: {
      type: Object,
    },
  },

  computed: {

    getImg: function(){

        return {
            "background-image":'url(' + this.image + ')',
        }
    },
  },

  data(){
        return {
            editProj:false,
            date:new Date(),
            image:require('../assets/project_blank.png'),

            addContractBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Contract", width:"100px"},
        }
  },

  async created(){

    let date = new Date(this.config.date_created)

    this.date = format(date, "dd-MM-yy");

    this.getImage();
  },

  methods: {

    newContract(){

        this.$emit("newContract", this.config);
    },

    editProject(){

        this.$emit("editProject", this.config);
    },

    async getImage(){

        const apiName = 'dtApiGateway';
        const path = '/image';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                path:'private/project/image/',
                image:this.config.image
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.image = await API.post(apiName, path, data);
    },


    showContracts(){

        this.$emit('showContracts', this.config)
    },
  }
}

</script>

<style scoped>
.e520_1000{
    width:90%;
    min-width:330px;
    max-width:600px;
    font-family:'Open Sans';
    font-weight:400;
    font-size:12px;
    padding:5px;
    border:1px solid rgba(200, 200, 200, 1);
    background-color:rgba(255, 255, 255, 1);
    cursor:pointer;
    margin:5px;
    display:flex;
    position:relative;
}

.e520_1010{
    display:inline-block;
    width:150px;
    vertical-align:top;
    margin-right:20px;
}

.e520_1015{
    display:inline-block;
    width:calc(100% - 170px);
    vertical-align:top;
    position:relative;
}

.e520_1020{
    margin-left:20px;
}

.e520_1025{
    display:inline-block;
    width:30%;
}

.e520_1030{
    width:100%;
    font-size:20px;
    font-weight:bold;
    text-align:left;
    white-space: nowrap;
}

.e520_1040{
    width:100%;
    font-weight:normal;
    padding-top:5px;
    text-align:left;
    white-space: nowrap;
}

.e520_1045{
    width:100%;
    font-weight:bold;
    padding-top:5px;
    text-align:left;
    overflow:hidden;
}

.e520_1048{
    width:100%;
    font-weight:normal;
    padding-top:5px;
    text-align:left;
}

.e520_1050{
    width:100%;
    font-weight:normal;
    font-size:20px;
}

.e520_1060{
    width:80px;
    height:30px;
    margin:10px auto;
    position:relative;
}

.e520_1070{
  display:inline-block;
  cursor:pointer;
}

.e520_1080{
  width:150px;
  height:150px;
  
  display:flex;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  cursor:pointer;
}

.e520_1090{
  position:absolute;
  top:10px;
  left:calc(100% - 100px);
  cursor:pointer;
}

.e520_1100{
    width:100%;
    height:40px;
    display:block;
    position:relative;
}

.e520_1110 { 
    width:25px;
    height:25px;
    position:absolute;
    top: 5px;
    left: calc(100% - 30px);
    background-image:url('../assets/edit.png');
    background-size:cover;
    background-repeat:no-repeat;
    cursor:pointer;
    opacity:1;
}

@media only screen and (max-width:600px) {

    .e520_1010{
        width:110px;
    }

    .e520_1015{
        width:calc(100% - 130px);
    }

    .e520_1030{
        font-size:18px;
    }

    .e520_1080{
        width:110px;
        height:110px;
    }

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>