<template>
<div :id="id" class="e070_1000">
    <div v-for="option in items" :key="option">
    
        <div v-if="option.date">
            <div class="e070_1070">
                <div class="e070_1080 noselect" >{{ option.date }}</div>
            </div>
        </div>

        <div v-if="option.view">
            <div class="e070_1060">
                <div class="e070_1065">
                    <div class="e070_1020 noselect">{{ option.message }}</div>
                    <div class="e070_1040 noselect">{{ option.time }}</div>
                </div>
            </div>
        </div>

        <div v-else>
            <div class="e070_1010">
                <div class="e070_1015">
                    <div class="e070_1030 noselect">{{ option.message }}</div>
                    <div class="e070_1050 noselect">{{ option.time }}</div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>

import { format } from 'date-fns'

export default {
  name: "MessageList",
  emits: [],

  components: {
      //Button
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            id:'',
            mid:'',
            items:[],
        }
  },

  created(){

    let r = (Math.random() + 1).toString(36).substring(7);

    this.id = "msgList_" + r;

    this.mid = "msg_" + r;

    let date = null;

    for(let message of this.config){

        let item = {};

        item.time = format(new Date(message.date_created), "HH:mm"); //"dd-MM-yyyy HH:mm:ss");
        item.message = message.message;
        item.view = message.vendor_view;

        let msgDate = format(new Date(message.date_created), "dd MMMM yyyy"); //"dd-MM-yyyy HH:mm:ss");

        if(date){

            if(msgDate != date){

                date = msgDate;

                item.date = date;
            }

        } else {

            date = msgDate;

            item.date = date;
        }

        this.items.push(item);
    }
  },

  mounted(){

    let msgList = document.getElementById(this.id);

    msgList.scrollTo(0, msgList.scrollHeight);
  },

  methods: {

  }
}

</script>

<style scoped>

.e070_1000{
    width:100%;
    height:calc(100% - 45px);
    background:rgba(240, 240, 240, 1);
    overflow-y:auto;
    margin:0 0 -5px 0;
}

.e070_1010{
    width:100%;
}

.e070_1015{
    width:90%;
    margin:2px 2px 2px auto;
    border:1px solid rgba(150, 150, 150, 1);
    border-radius:3px;
    background-color:rgba(220, 220, 240, 1);
}

.e070_1020{
    width:calc(100% - 40px);
    text-align:left;
    background-color:rgba(220, 240, 220, 1);
    font-family:'Open Sans';
    font-weight:normal;
    font-size:12px;
    padding:5px;
    white-space:normal;
    display:inline-block;
}

.e070_1030{
    width:calc(100% - 40px);
    text-align:left;
    background-color:rgba(220, 220, 240, 1);
    font-family:'Open Sans';
    font-weight:normal;
    font-size:12px;
    padding:5px;
    display:inline-block;
}

.e070_1040{
    width:40px;
    font-family:'Open Sans';
    font-weight:normal;
    font-size:10px;
    padding:3px;
    white-space:normal;
    display:inline-block;
    background-color:rgba(240, 220, 220, 1);
}

.e070_1050{
    width:40px;
    font-family:'Open Sans';
    font-weight:normal;
    font-size:10px;
    vertical-align:bottom;
    display:inline-block;
    background-color:rgba(220, 220, 240, 1);
}

.e070_1060{
    width:100%;
}

.e070_1065{
    width:90%;
    margin:2px auto 2px 2px;
    border:1px solid rgba(150, 150, 150, 1);
    border-radius:3px;
    background-color:rgba(220, 240, 220, 1);
}

.e070_1070{
    width:100%;
    background:rgba(240, 240, 240, 1);
}

.e070_1080{
    margin:0 auto;
    font-family:'Open Sans';
    font-weight:bold;
    font-size:12px;
    padding:5px 0;
    display:inline-block;
    background:rgba(240, 240, 240, 1);
}



@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>

