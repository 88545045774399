<template>

    <div class="e460_1000">

        <div class="e460_1010">
            <ButtonActive :config="newLog" @click="newFlightLog" />
        </div>

        <div class="e460_1020">
            <FlightHours :config="config" />
        </div>
        
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'
import FlightHours from './FlightHours.vue'

export default {
    name: 'PilotDashboard',
    emits: ["home", "profile", "newFlightLog"],

    components: {
        ButtonActive,
        FlightHours,
    },


    props: {
        config:{
            type:Object,
        }
    },

    created() {
 
    },

    updated() {
      
    },

    data(){
        return {
            headerBackground:'white',

            newLog:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"New Flight Log", width:"210px"},
            
        }
    },

    methods: {

        newFlightLog(){

            this.$emit('newFlightLog');
        },

        home(){

            this.$emit('home');
        },

        profile(){

            this.$emit('profile');
        },
    }
}

</script>

<style scoped>

h3{
  font-weight:800;
  font-family:'Open Sans';
  font-size:36px;
}

.e460_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e460_1010{
    width:230px;
    height:100px;
    margin-left:calc(100% - 230px);
    position:relative;
}

.e460_1020{
    width:90%;
    min-width:300px;
    margin:0 auto;
}

@media only screen and (max-width:600px) {

    .e460_1020{
        width:300px;
        margin:0 auto;
    }
}

@media only screen and (min-width:600px) {

    .e460_1020{
        min-width:600px;
        margin:0 auto;
    }
}

@media only screen and (min-width:1200px) {

.e460_1010{
    width:230px;
    height:100px;
    margin-left:calc(100% - 230px);
    position:relative;
}
}

</style>