<template>
    <div class=e270_6595>
        <div class=ei270_6595_168_3897>
        
			<div class=ei270_6595_168_3901>
				<div class=ei270_6595_168_3902>
					<div  class="ei270_6595_168_3903"></div>
				</div>
			</div>
			<div  class="ei270_6595_175_4643"></div>
			<div class=ei270_6595_175_4800>
				<div  class="ei270_6595_175_4801"></div>
				<div  class="ei270_6595_175_4802"></div>
				<div  class="ei270_6595_175_4803"></div>
				<div  class="ei270_6595_175_4804"></div>
				<div  class="ei270_6595_175_4805"></div>
			</div>
			<div  class="ei270_6595_175_4824" :style="getHash"></div>
			<div  class="ei270_6595_175_4881" :style="getHash"></div>
			<div class=ei270_6595_270_6573>
				<span  class="ei270_6595_270_6574 noselect">Affordable drone solutions for inspection, operations, management and planning. </span>
			</div>
		</div>
    </div>
</template>

<script>

export default {
    name: 'SubSection',
    emits: [],

    components: {

    },

    props: {

	config: {
			type: Object,
		},
	},

	computed: {

		img_hash: function(){

			let hashImg= this.config.hash;

			return {
				"background-image": "url(" + hashImg + ")",
			}
		},

		getHash: function(){

			return {

				"background-image":'url(' + encodeURI(this.config.hash) + ')',
			}
		}


	},

	data(){
		return {
			background:'#F04200',
			color:'#ffffff',
			hash:'../assets/hatch_white.png'
		}
	},


	created(){

		this.background = this.config.background;

        this.color = this.config.color;

		this.textColor = this.config.textColor;

		this.hash = this.config.hash;
	},

    methods: {

    }
}

</script>

<style scoped>
.e270_6595 { 
	width:100%;
	height:350px;
	position:relative;
	background-color:v-bind(background);
}

.e270_1010{
	margin:0 auto;
}
.ei270_6595_168_3897 { 
	height:350px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.ei270_6595_168_3898 { 
	background-color:v-bind(background);
	height:350px;
	position:absolute;
	left:0px;
	top:0px;
}

.ei270_6595_168_3901 { 
	height:350px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_6595_168_3902 { 
	height:350px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_6595_168_3903 { 
	background-color:v-bind(background);
	height:350px;
	position:absolute;
	left:0px;
	top:0px;
}
.ei270_6595_175_4643 { 
	transform: rotate(-45deg);
	width:240px;
	height:0px;
	position:absolute;
	left:-35px;
	top:150px;
    border-bottom:1px solid v-bind(color);
}

.ei270_6595_175_4800 { 
	width:1761px;
	height:747px;
	position:relative;
	/*left:-131px;
	top:-213px;*/
}
.ei270_6595_175_4801 { 
	transform: rotate(-45deg);
	width:120px;
	height:0px;
	position:absolute;
	left:0px;
	top:305px;
    border-bottom:1px solid v-bind(color);
}

.ei270_6595_175_4802 { 
	transform: rotate(-45deg);
	width:120px;
	height:0px;
	position:absolute;
	left:1335px;
	top:42px;
    border-bottom:1px solid v-bind(color);
}

.ei270_6595_175_4803 { 
	transform: rotate(-45deg);
	width:120px;
	height:0px;
	position:absolute;
	left:1337px;
	top:287px;
    border-bottom:1px solid v-bind(color);
}

.ei270_6595_175_4804 { 
	transform: rotate(-45deg);
	width:200px;
	height:0px;
	position:absolute;
	left:1219px;
	top:287px;
    border-bottom:1px solid v-bind(color);
}

.ei270_6595_175_4805 { 
	transform: rotate(-45deg);
	width:150px;
	height:0px;
	position:absolute;
	left:19px;
	top:52px;
    border-bottom:1px solid v-bind(color);
}
.i270_6595_175_4805 { 
	border:1px solid v-bind(color);
}

.ei270_6595_175_4824 { 
	width:64px;
	height:81px;
	position:absolute;
	left:1262px;
	top:1px;
    background-size: contain;
}

.ei270_6595_175_4881 { 
	width:64px;
	height:81px;
	position:absolute;
	left:148px;
	top:274px;
    background-size: contain;
}
.ei270_6595_270_6573 { 
	width:1440px;
	height:120px;
	position:absolute;
	left:0px;
	top:115px;
}
.ei270_6595_270_6574 { 
	color:v-bind(textColor);
	height:120px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:center;
	font-weight:600;
	font-size:48px;
	letter-spacing:-1.2000000476837158;
	line-height:px;
}

@media only screen and (max-width: 600px) {
	.ei270_6595_168_3897 { 
		height:330px;
		left:calc((100% - 330px)/2);
	}
	.ei270_6595_270_6573 { 
		width:330px;
		top:90px;
	}
	.ei270_6595_270_6574 { 
		height:120px;
		font-size:28px;
	}

	.ei270_6595_175_4824 { 
		width:15px;
		left:320px;
	}

	.ei270_6595_175_4881 { 
		width:15px;
		left:0px;
	}
}


@media only screen and (min-width: 600px) {
	.ei270_6595_168_3897 { 
		height:330px;
		left:calc((100% - 500px)/2);
	}
	.ei270_6595_270_6573 { 
		width:500px;
		top:90px;
	}
	.ei270_6595_270_6574 { 
		height:120px;
		font-size:28px;
	}

	.ei270_6595_175_4824 { 
		width:64px;
		left:520px;
	}

	.ei270_6595_175_4881 { 
		width:64px;
		left:100px;
	}
}

@media only screen and (min-width: 1200px) {
	
	.ei270_6595_168_3897 { 
		height:350px;
		left:calc((100% - 1440px)/2);
	}
	
	.ei270_6595_270_6573 { 
		width:1440px;
		top:115px;
	}
	.ei270_6595_270_6574 { 
		height:120px;
		font-size:48px;
	}

	.ei270_6595_175_4824 { 
		width:64px;
		left:1262px;
	}

	.ei270_6595_175_4881 { 
		width:64px;
		left:148px;
	}
}
</style>