<template>
    <div v-if="config" class="e490_1000">
        <div class="e490_1010" v-for="log in config" :key="log">
            <FlightHoursLog :config="log" />    
        </div>
    </div>
</template>

<script>
import FlightHoursLog from './FlightHoursLog.vue'

export default {
  name: 'FlightHoursTable',
  emits: [],

  components: {
     FlightHoursLog
  },

  props: {
    config: {
      type: Object
    },
  },

  data(){
        return {
        }
  },

  created(){

  },

  methods: {

  }
}

</script>

<style scoped>

.e490_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e490_1010{
    float:left;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>