<template>
    <div class="container">

        <div class="title">{{config.title}}<span v-if="required" class="asterix">*</span></div>
        
        <input :id="id" :type="type" :key="redraw" @keyup="quickCheck" @blur="check" :placeholder="placeholder" :disabled="disabled" :name="name" :value="value" class="input" :data-test="dataTest" />

        <div :id="idError" class="err">Error</div>

    </div>
</template>

<script>

const axios = require("axios");

export default {
    name: 'InputField',
    emits: ["verify"],

    components: {

    },


    props: {
        config: Object,
    },

    data(){
        return {
            id:0,
            redraw:0,
            type:'input',
            placeholder:'',
            name:'',
            dataTest:'',
            verified:false,
            matches:null,
            required:false,
            value:'',
            disabled:false,
            align:'left',
            //id:'unassigned',
            //type:'text',
            //signUp:{color:"#F04200", background:"#FFFFFF", opacity:"1", text:"Sign up", width:'100px'},
        }
    },

    watch:{

    'config.error'(){

            if (this.config.error) {

                document.getElementById(this.id).style.borderColor = "red";

                document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                document.getElementById(this.idError).style.display = "block";

                document.getElementById(this.idError).innerHTML = "Value Required";

            } else {

                document.getElementById(this.id).style.borderColor = "#949494";

                document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                document.getElementById(this.idError).style.display = "none";
            }
        }
    },

    created(){

        this.id = this.config.id;

        this.idError = "err" + this.id;

        this.type = this.config.type;

        this.placeholder = this.config.placeholder;

        this.name = this.config.name;

        this.dataTest = this.config.dataTest;

        this.required = this.config.required;

        this.value = this.config.value;

        if(this.config.disabled) this.disabled = this.config.disabled;

        if(this.config.align) this.align = this.config.align;
    },

    methods: {

        validateEmail(email){

            return email.match(

                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },

        quickCheck(){

            let text = document.getElementById(this.id).value;

            switch(this.config.regx){

                case this._TEXT:

                    this.$emit('verify', {id:this.id, state:true, value:''});

                break;

                case this._EMAIL:

                    if (this.validateEmail(text)) {

                        document.getElementById(this.id).style.borderColor = "#949494";

                        document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                        document.getElementById(this.idError).style.display = "none";

                    } else {

                        document.getElementById(this.id).style.borderColor = "red";

                        document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                        document.getElementById(this.idError).style.display = "block";

                        let ch = text.substring(text.length - 1);

                        switch(this.config.title){

                            case 'Email':

                                document.getElementById(this.idError).innerHTML = "Invalid email format";

                                break;

                            default:

                                document.getElementById(this.idError).innerHTML = "Character '" + ch + "' is not allowed";
                        }
                    }
                    
                break;

                case this._NAME:
                    {
                        let match = text.match(/^(?=.*[A-Z0-9])[\w.&\-'/$ ]+$/i);

                        if (match) {

                            this.matches = match[0];

                            document.getElementById(this.id).style.borderColor = "#949494";

                            document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                            document.getElementById(this.idError).style.display = "none";

                            this.$emit('verify', {id:this.id, state:true, value:document.getElementById(this.id).value});

                        } else {

                            let ch = this.getDifference(this.matches, text);

                            if(ch){

                                document.getElementById(this.id).style.borderColor = "red";

                                document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                                document.getElementById(this.idError).style.display = "block";

                                document.getElementById(this.idError).innerHTML = "Character '" + ch + "' is not allowed";
                            }

                            this.$emit('verify', {id:this.id, state:false, value:''});
                        }
                    }
                    break;

                case this._LOCATION:
                    {

                        //AIzaSyA45jd4KEQSnivdctilskZj-Ix0yn0_OmY
                    }
            }
        },

        getDifference(s, t){

            s = [...s].sort();

            t = [...t].sort();

            return t.find((char, i) => char !== s[i]);
        },

        check(){

            let text = document.getElementById(this.id).value;

            switch(this.config.regx){

                case this._EMAIL:

                    if(text.length){

                        if (this.validateEmail(text)) {

                            /* 
                            Name: E-mail Check Invalid or Disposable Domain
                            https://rapidapi.com/Top-Rated/api/e-mail-check-invalid-or-disposable-domain/pricing

                            Plans:
                            Basic:  $0.00 / mo      1,000 / month
                            Pro:    $50.00 / mo     200,000 / month
                            Ultra:  $99.00 / mo     500,000 / month
                            Mega:   $195.00 / mo    1,000,000 / month
                            */

                            const options = {
                                method: 'GET',
                                url: 'https://mailcheck.p.rapidapi.com/',
                                params: {email: text},
                                headers: {
                                    'X-RapidAPI-Key': '7c5d9db5d6msh1987a29ec547a13p109199jsna9bb20c0e9fe',
                                    'X-RapidAPI-Host': 'mailcheck.p.rapidapi.com'
                                }
                            };

                            let _this = this;

                            axios.request(options).then(function (response) {

                                console.log(response.data);

                                _this.verified = false;

                                if(!response.data.valid || response.data.disposable){

                                    document.getElementById(_this.id).style.borderColor = "red";

                                    document.getElementById(_this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                                    document.getElementById(_this.idError).style.display = "block";

                                    document.getElementById(_this.idError).innerHTML = "Email Address Invalid";

                                } else {

                                    document.getElementById(_this.id).style.borderColor = "green";

                                    document.getElementById(_this.id).style.backgroundColor = "rgba(0, 255, 0, 0.1)";

                                    document.getElementById(_this.idError).style.display = "none";

                                    _this.verified = true;
                                }

                                _this.$emit('verify', {id:_this.id, state:_this.verified, value:document.getElementById(_this.id).value});

                            }).catch(function (error) {

                                console.error(error);
                            });

                        } else {

                            this.verified = false;

                            document.getElementById(this.id).style.borderColor = "red";

                            document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                            document.getElementById(this.idError).style.display = "block";

                            let ch = text.substring(text.length - 1);

                            switch(this.config.title){

                                case 'Email':

                                    document.getElementById(this.idError).innerHTML = "Invalid email format";

                                    break;

                                default:
                                    document.getElementById(this.idError).innerHTML = "Character '" + ch + "' is not allowed";
                            }
                        }
                        
                    } else {

                        document.getElementById(this.id).style.borderColor = "#949494";

                        document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                        document.getElementById(this.idError).style.display = "none";

                        this.$emit('verify', {id:this.id, state:false});
                    }

                    break;

                case this._NAME:
                    {
                        let match = text.match(/^(?=.*[A-Z0-9])[\w.&\-'/$ ]+$/i);

                        if (match) {

                            this.matches = match[0];

                            document.getElementById(this.id).style.borderColor = "#949494";

                            document.getElementById(this.id).style.backgroundColor = "rgba(255, 255, 255, 1)";

                            document.getElementById(this.idError).style.display = "none";

                            this.$emit('verify', {id:this.id, state:true, value:document.getElementById(this.id).value});

                        } else {

                            let ch = this.getDifference(this.matches, text);

                            if(ch){

                                document.getElementById(this.id).style.borderColor = "red";

                                document.getElementById(this.id).style.backgroundColor = "rgba(255, 2, 0, 0.1)";

                                document.getElementById(this.idError).style.display = "block";

                                document.getElementById(this.idError).innerHTML = "Character '" + ch + "' is not allowed";
                            }

                            this.$emit('verify', {id:this.id, state:false, value:''});
                        }
                    }

                    break;
            }
        },
    }
}

</script>

<style scoped>

.container {
    width:100%;
    position:relative;
    font-family:'Open Sans';
    font-size:16px;
    font-weight:400;
}

.title {
    position:absolute;
    top:0px;
    left:0px;
    font-size:12px;
    font-weight:500;
    white-space: nowrap;
}

.asterix{
    color:rgba(255, 0, 0, 1);
    font-size:12px;
    font-weight:500;
    margin-left:5px;
}
.input {
    position:absolute;
    text-align:v-bind(align);
    top:20px;
    left:0px;
    width:100%;
    height:59px;
    border:1px solid #949494;
    border-radius:4px;
    padding:20px 12px;
    outline: none;
}

.err {
    width:300px;
    position:absolute;
    top:82px;
    left:0px;
    font-family:'Open Sans';
    font-size:12px;
    font-weight:400;
    color:rgba(255, 0, 0, 1);
    display:none;
    text-align:left;
}

@media only screen and (max-width: 600px) {
    .container {
        width:100%;
    }
}

@media only screen and (min-width: 600px) {
    .container {
        width:100%;
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        width:100%;
    }
}

</style>