<template>
    <div id="spin" class="e440_1000">

        <div class="e440_1010">Select Pilot</div>
        <div class="e440_1020">

            <div v-if="!selectedPilot" class="e440_1030">
                <PilotTile  v-for="pilot of pilots" :key="pilot" :config="pilot" @select="selectPilot"/>
            </div>
            <div v-else class="e440_1030">
                <PilotTile  :config="selectedPilot" @deselect="deSelectPilot"/>
            </div>
        </div>

        <div class="e440_1010">Select Drone</div>
        <div class="e440_1020">

            <div v-if="!selectedDrone" class="e440_1030">
                <UserDrone v-for="drone of drones" :key="drone" :config="drone" @select="selectDrone" />
            </div>

            <div v-else class="e440_1030">
                <UserDrone  :config="selectedDrone" @deselect="deSelectDrone"/>
            </div>
        </div>

        <div class="e440_1010">Describe Solution</div>
        <div class="e440_1040">
            <TextField :config="textConfig" @verify="verify" />
            <div class="e440_1100">
                <div id="curr" class="e440_1110">
                    <SelectField :config="currencyConfig" @change="currencyChange" />
                </div>
                <div class="e440_1120">
                    <InputField :config="quoteConfig" />
                </div>
            </div>
        </div>

        <div class="e440_1050">

            <div class="e440_1060">
                <div class="e440_1070"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>

            <div class="e440_1060">
                <div class="e440_1070"><ButtonActive :config="addBtn" @click="addBid" /></div>
            </div>
            </div>

            <div class="e440_1080">

            <div class="e440_1060">
                <div class="e440_1070"><ButtonActive :config="addBtn" @click="addBid" /></div>
            </div>

            <div class="e440_1060">
                <div class="e440_1070"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>
        </div>
    </div>
</template>

<script>

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

import PilotTile from './PilotTile.vue'
import UserDrone from './UserDrone.vue'
import TextField from './TextField.vue'
import InputField from './InputField.vue'
import SelectField from './SelectField.vue'
import ButtonActive from './ButtonActive.vue'

export default {
  name: "AddBidForm",
  emits: ["close"],

  components: {
    PilotTile,
    UserDrone,
    TextField,
    ButtonActive,
    InputField,
    SelectField,
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            currencyList:require('../assets/currency-list.json'),
            pilots:null,
            drones:null,
            selectedPilot:null,
            selectedDrone:null,
            textConfig:{id:"description", type:"input", name:"description", disabled:false, title:"Description", rows:3, placeholder:"", value:"123", required:false, dataTest:""},
            cancelBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Cancel", width:"200px"},
            addBtn:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Submit Bid", width:"200px"}, 
            currencyConfig:{id:"currency", name:"currency", title:"Currency", value:0, dataTest:"", options: [
                { text: 'Select ...', value: 0 },
            ],},
            quoteConfig:{id:"quote", type:"input", regx:this._TEXT, name:"quote", disabled:false, title:"Quotation Amount", placeholder:"", value:this.config.value, required:false, dataTest:""},
            
        }
  },

  created(){

    this.currencyList.forEach(currency =>{

        this.currencyConfig.options.push({text:currency.name + " " + currency.symbol, value:currency.code})
    });

    this.getPilots();
  },

  methods: {

    close(){

        this.$emit("close");
    },

    verify(){

        //console.log(element);
    },

    currencyChange(){

        //console.log(data);

    },

    selectPilot(pilot){
        console.log(pilot);
        this.selectedPilot = pilot;

        this.selectedPilot.select = false;
        this.selectedPilot.remove = false;
        this.selectedPilot.unselect = true;
    },

    deSelectPilot(){

        this.selectedPilot.unselect = false;
        this.selectedPilot.select = true;

        this.selectedPilot = null;
    },

    selectDrone(drone){

        this.selectedDrone = drone;

        this.selectedDrone.select = false;
        this.selectedDrone.remove = false;
        this.selectedDrone.unselect = true;
    },

    deSelectDrone(){

        this.selectedDrone.unselect = false;
        this.selectedDrone.select = true;

        this.selectedDrone = null;
    },

    async addBid(){

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/bids';

        let info = {
            cid:this.config.id,
            drone:this.selectedDrone.image,
            pilot:this.selectedPilot.image,
            description:document.getElementById('description').value,
            quote:document.getElementById('quote').value,
            currency:document.getElementById('currency').value,
            status:1
        }

        let user = await Auth.currentAuthenticatedUser();

        let data = {
            body: {
                add:info
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        console.log(data);

        await API.post(apiName, path, data);

        spinner.stop();

        this.close();
    },

    async getPilots(){

        const apiName = 'dtApiGateway';
        const path = '/pilot';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        this.pilots = await API.post(apiName, path, data);

        this.drones = [];

        for(let pilot of this.pilots){

            pilot.select = true;
            pilot.remove = false;

            pilot.drones.forEach(drone => {
                
                drone.select = true;
                drone.remove = false;

                this.drones.push(drone)
            });
        }
        console.log(this.pilots);

        spinner.stop();
    }
  }
}

</script>

<style scoped>
.e440_1000{
    width:100%;
}

.e440_1010{
    width:100%;
    margin:10px;
    font-family:'Open Sans';
    font-weight:bold;
    font-size:18px;
    text-align:left;
}

.e440_1020{
    width:100%;
    text-align:left;
}

.e440_1030{
    margin:5px;
    display:inline-block;
}

.e440_1040{
    width:100%;
    position:relative;
    margin:0 auto;
}

.e440_1045{
    width:100%;
    position:relative;
    margin:0 auto;
}

.e440_1050{
    width:100%;
    position:relative;
    margin:0 auto;
    height:105px;
}

.e440_1060{
    width:49%;
    display:inline-block;
    position:relative;
    height:60px;
    margin:0 auto;
}

.e440_1070{
    width:200px;
    margin:0 auto;
}

.e440_1080{
    width:100%;
    position:relative;
    margin:0 auto;
    height:105px;
}

.e440_1100{
    width:100%;
    position:relative;
    margin:0 auto;
    height:105px;
}

.e440_1110{
    width:42%;
    float:left;
    position:relative;
    height:105px;
}

.e440_1120{
    width:42%;
    float:right;
    position:relative;
    height:105px;
}

@media only screen and (max-width:600px) {

.e440_1060{
    width:100%;
    display:block;
    margin:10px auto;
}

.e440_1050{
    display:none;
}

.e440_1080{
    display:block;
}
}

@media only screen and (min-width:600px) {

.e440_1050{
    display:block;
}

.e440_1080{
    display:none;
}
}

@media only screen and (min-width:1200px) {

.e440_1050{
    display:block;
}

.e440_1080{
    display:none;
}
}
</style>