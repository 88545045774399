<template>

  <div class="e212_1010">

    <div class="e212_1020" :style="getImg">

      <div class="e212_1030">

        <div class="e212_1040">{{title}}</div>

        <div class="e212_1050 noselect">{{text}}</div>
      
        <div class=e212_1060>
            <div class=e212_1070>
              <ButtonActive :config="data" @click="quote"/>
            </div>
        </div>
      </div>


    </div>

    <div  class=e212_1080>
      <SubSection :config="subsec" />
    </div>

    <div v-for="option in featureList" :key="option">

      <div v-if="option.align">
        <FeatureDetailRight :config="option" @service="service(option.id)"/>
      </div>

      <div v-else>
        <FeatureDetailLeft :config="option" @service="service(option.id)"/>
      </div>

    </div>

    <GetStarted :config="getStarted" @contact="contact" @quote="quote"/>

  </div>

  <div id=pageTop class="e273_6700 noselect" @click="pageTop"></div>

</template>

<script>
import ButtonActive from './ButtonActive.vue'
import SubSection from './SubSection.vue'
import FeatureDetailLeft from './FeatureDetailLeft.vue'
import FeatureDetailRight from './FeatureDetailRight.vue'
import GetStarted from './GetStarted.vue'

export default {
  name: 'VerticalDetail',
  emits:["nav", "contact", "quote"],
  components: {
    ButtonActive,
    SubSection,
    FeatureDetailLeft,
    FeatureDetailRight,
    GetStarted,
  },

  props: {

     config: {
      type: Object
    }
  },

  data(){
    return {
      id:0,
			headerBackground: 'white',
      title:'',
      text:'',
      featureList:[],
			data:{color:"#FFFFFF", background:"#F04200", opacity:"1", text:"Ask for a quote", width:"208px"},
      getStarted:{
        color:"#FFFFFF", 
        background:"#ffffff", 
        textColor:'#000000',
        ask1:{color:"#ffffff", background:"#F04200", border:"#F04200", disabled:false, text:"Ask for a quote", width:'233px'},
        ask2:{color:"#F04200", background:"#eeeeee", border:"#eeeeee", disabled:false, text:"General Enquiries", width:'233px'},
      },
      subsec:{color:"#F04200", background:"#FFFFFF", textColor:'#000000', hash:require('../assets/hatch_orange.png')},
    }
  },

  computed: {

    getImg: function(){
        return {
          "background-image":'url(.' + encodeURI(this.config.verticals[this.config.id].img) + ')',
        }
    },

    
  },

  created(){

    window.addEventListener('scroll', this.handleScroll);

    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'instant'
    });

    this.id = this.config.id;

    this.title = this.config.verticals[this.config.id].title;

    this.text = this.config.verticals[this.config.id].text;

    let align = false;

    for(let service of this.config.verticals[this.id].services){

      this.featureList.push({id:service, align:align, feature:this.config.services[service]});

      align = !align;
    }
  },

  beforeUnmount(){

    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {

    nav(item){

      this.$emit('nav', item);
    },

    handleScroll(){

      if(window.scrollY > 50) {

        let opacity;

        if(window.innerWidth > 980) opacity = 1.0;
        else opacity = 0.4;

        this.headerBackground = 'rgba(255, 255, 255, ' + opacity + ')';

        document.getElementById("pageTop").style.display = "block";

      } else {

        this.headerBackground = 'transparent';

        document.getElementById("pageTop").style.display = "none";

      }
    },

    contact(){

      this.$emit('contact');
    },

    quote(){

      this.$emit('quote');
    },

    pageTop(){
      window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
      });
    },
  }
}
</script>

<style scoped>

.e212_1000{
  width:100%;
  margin:0 auto;
}

.e213_1010{
  height:88px;
  background-color:rgba(255, 255, 255, 1.0);
}

.e212_1010{
  width:100%;
  margin:0 auto;
}

.e212_1020{
  position:relative;
  width:100%;
  height:650px;
	background-repeat:no-repeat;
	background-size:cover;
}

.e212_1030{
  position:absolute;
  top:250px;
  left:calc((100% - 920px)/2);
  width:920px;
  color:rgba(255, 255, 255, 1);
  font-family:'Open Sans';
  font-weight:700;
}

.e212_1040{
  margin:0 auto;
  width:100%;
  font-size:48px;
  text-shadow: 0 0 4px rgba(0, 0, 0 , 0.4);
}

.e212_1050{
  margin:16px auto 0 auto;
  width:100%;
  font-size:24px;
  text-align:justify;
}

.e212_1060{
  margin-top:40px;
  width:100%;
  z-index:5;
}

.e212_1070{
  display:inline-block;
  margin:0 auto;
  
}

.e212_1080{
  width:100%;
  height:350px;
  margin:0 auto;
}

.e273_6700 { 
  display:none;
  background-color:rgba(240, 66, 0, 1);
  border-radius:6px;
	width:60px;
	height:60px;
	position:fixed;
	top: 50%;
  left: calc(100% - 30px);
  /* bring your own prefixes */
  transform: translate(-50%, -100%);
  background-image:url('../assets/arrowUp.png');
  background-size:center;
  background-repeat:no-repeat;
  cursor:pointer;
  opacity:0.6;
  z-index:5000;
}

@media only screen and (max-width: 600px) {

  .e213_1010{
    width:420px;
  }

  .e212_1030{
    top:100px;
    left:calc((100% - 380px)/2);
    width:380px;
  }

  .e212_1040{
    font-size:40px;
  }

  .e212_1050{
    margin:16px auto 0 auto;
    font-size:18px;
    font-weight:400;
    width:80%;
    text-align:center;
  }

  .e212_1080{
    width:50%;
  }
}

@media only screen and (min-width: 600px) {

  .e213_1010{
    width:100%;
  }

  .e212_1030{
    top:100px;
    left:calc((100% - 600px)/2);
    width:600px;
  }

  .e212_1040{
    font-size:40px;
  }

  .e212_1050{
    margin:16px auto 0 auto;
    font-size:18px;
    font-weight:400;
    width:80%;
    text-align:center;
  }

  .e212_1080{
    width:50%;
    height:350px;
  }
}

@media only screen and (min-width: 1200px) {

  .e213_1010{
    width:100%;
  }

  .e212_1030{
    top:250px;
    left:calc((100% - 920px)/2);
    width:920px;
  }

  .e212_1040{
    font-size:48px;
  }

  .e212_1050{
    width:100%;
    font-size:24px;
    text-align:justify;
  }

  .e212_1080{
    width:100%;
  }

}

</style>