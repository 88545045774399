<template>
    <div class="e710_1000">

        <div class="e710_1010">{{title}}</div>

        <div class="e710_1030">
            <div class="e710_1040" @click="newProfileImage" >
                <input @change="file_changed" class="e710_1060" type=file id=profile accept="image/png, image/gif, image/jpeg" />
                <div id=pimg class="e710_1050" :style="getImg"></div>
            </div>
        </div>

        <div class="e710_1070">
            <div class="e710_1100"><InputField :config="nameConfig" @verify="verify" /></div>

            <div class="e710_1100">
                <div class="e710_1130">
                    <InputField :config="dateConfig" />
                </div>

                <div class="e710_1150">
                    <SelectField :config="statusConfig" @change="statusChange" />
                </div>
            </div>
        </div>

        <div id="spin" class="e710_1020"><TextField :config="textConfig" @verify="verify" /></div>

        <div class="e710_1100"><InputField :config="locConfig" @verify="verify" /></div>

        <div class="e710_1100">

            <div class="e710_1130">
                <InputField :config="latConfig" />
            </div>

            <div class="e710_1150">
                <InputField :config="longConfig" />
            </div>
        </div>

        <div class="e710_1110"><GoogleMapView :config="mapConfig" /></div>

        <div class="e710_1101">

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="addBtn" @click="addProject" /></div>
            </div>
        </div>

        <div class="e710_1102">

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="addBtn" @click="addProject" /></div>
            </div>

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import InputField from './InputField.vue'
import ButtonActive from './ButtonActive.vue'
import TextField from './TextField.vue'
import SelectField from './SelectField.vue'
import GoogleMapView from './GoogleMapView.vue'

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns'

export default {
  name: "AddProjectForm",
  emits: ["close"],

  components: {
    InputField,
    TextField,
    GoogleMapView,
    SelectField,
    ButtonActive,
  },

  props: {
    config:{
      type:Object,
    },
  },

  computed: {

        getImg: function(){

            return {
                "background-image":'url(' + this.image + ')',
            }
        }
    },
  
  data(){
        return {
            image:require('../assets/project_blank.png'),
            sizeWarning:require('../assets/warning.png'),
            newImage:false,
            title:"Add Project",

            dateConfig:{id:"date", type:"input", regx:this._DATE, name:"date", disabled:true, title:"Date Created", placeholder:"", value:format(new Date(), 'dd-MM-yyyy HH:mm:ss'), required:false, dataTest:""},
            locConfig:{id:"loc", type:"input", regx:this._LOCATION, name:"loc", disabled:false, title:"Location", placeholder:"", value:"", required:false, dataTest:""},
            nameConfig:{id:"name", type:"input", regx:this._TEXT, name:"name", disabled:false, title:"Name", placeholder:"", value:"", required:false, dataTest:""},
            latConfig:{id:"lat", type:"input", regx:this._TEXT, name:"lat", disabled:true, title:"Latitude", placeholder:"", value:"", required:false, dataTest:""},
            longConfig:{id:"long", type:"input", regx:this._TEXT, name:"long", disabled:true, title:"Longitude", placeholder:"", value:"", required:false, dataTest:""},
            textConfig:{id:"description", type:"input", name:"description", disabled:false, title:"Description", rows:3, placeholder:"", value:"123", required:false, dataTest:""},
            mapConfig:{},

            statusConfig:{id:"status", name:"status", title:"Project Status", value:0, required:true, dataTest:"", options: [
                { text: 'Design', value: this._DESIGN, selected:false},
                { text: 'Budget Approval', value: this._BUDGET_APPROVAL, selected:true},
                { text: 'Sourcing Vendors', value: this._VENDORS, selected:false},
                { text: 'Vendor Selection', value: this._SELECTION, selected:false},
                { text: 'External Approvals', value: this._EXTERNAL_APPROVAL, selected:false},
                { text: 'In Progress', value: this._IN_PROGRESS, selected:false},
                { text: 'Awaiting Deliverables', value: this._AWAITING_DELIVERABLES, selected:false},
                { text: 'Completed', value: this._COMPLETED, selected:false}
            ],},

            cancelBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Cancel", width:"200px"},
            addBtn:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Project", width:"200px"}, 
        }
  },

  async created(){

    console.log(this.config);

    this.dateConfig.value = this.config.date_created;
    this.locConfig.value = this.config.location;
    this.nameConfig.value = this.config.name;
    this.latConfig.value = this.config.latitude;
    this.longConfig.value = this.config.longitude
    this.textConfig.value = this.config.description;

    if(this.config.id){

        this.title = "Update Project";
        this.addBtn.text = "Update Project";

        await this.getImage(this.config.image);
    }
  },

  mounted(){

    document.getElementById("status").value = this.config.status;
  },

  methods: {

    close(){

       this.$emit("close");
    },

    statusChange(status){

        console.log(status);
        this.statusConfig.error = false;

    },

    verify(){

        //console.log(element);
    },

    newProfileImage(){

        document.getElementById('profile').click();
    },

    file_changed(event){

        this.newImage = true;

        var selectedFile = event.target.files[0];

        var reader = new FileReader();

        let _this = this;

        if(selectedFile.size < 3000000){

            reader.onload = function(event) {

                _this.image = event.target.result;

                document.getElementById("image").style.backgroundImage = 'url("' + _this.image + '")';
            };

        } else {

            this.userImage =  this.sizeWarning;
        }

        reader.readAsDataURL(selectedFile);
    },

    async getImage(image){

        const apiName = 'dtApiGateway';
        const path = '/image';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                path:'private/project/image/',
                image:image
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.image = await API.post(apiName, path, data);
    },

    async addProject(){

        let verified = true;

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        if(!document.getElementById("date").value.length){

            verified = false;

            this.dateConfig.error = true;

            //this.refresh = Math.random();
        }

        if(!document.getElementById("name").value.length){

            verified = false;

            this.nameConfig.error = true;
        }

        if(!document.getElementById("loc").value.length){

            verified = false;

            this.locConfig.error = true;
        }

        if(!document.getElementById("lat").value.length){

            verified = false;

            this.latConfig.error = true;
        }

        if(!document.getElementById("long").value.length){

            verified = false;

            this.longConfig.error = true;
        }

        if(!document.getElementById("status").value){

            verified = false;

            this.statusConfig.error = true;
        }

        if(verified){

            let project = {
                id:this.config.id,
                image:this.config.image,
                date:document.getElementById("date").value,
                name:document.getElementById("name").value,
                location:document.getElementById("loc").value,
                latitude:document.getElementById("lat").value,
                longitude:document.getElementById("long").value,
                description:document.getElementById("description").value,
                status:document.getElementById("status").value,
                b64:(this.newImage)?this.image:'',
            }

            console.log(project);

            const apiName = 'dtApiGateway';
            const path = '/project';

            let user = await Auth.currentAuthenticatedUser();

            const data = {
                body: {
                    add:project
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            this.projects = await API.post(apiName, path, data);
        }

        this.close();

        spinner.stop();
    },

  }
}

</script>

<style scoped>

    .e710_1000{
        width:100%;
    }

    .e710_1010{
        margin:20px auto;
        color:rgba(28, 28, 28, 1);
        font-family:'Open Sans';
        font-size:32px;
        font-weight:700;
    }

    .e710_1020{
        width:100%;
        position:relative;
        margin:0 auto;
    }

    .e710_1030{
        width:160px;
        height:200px;
        display: inline-block;
        vertical-align:top;
    }

    .e710_1040{
        margin:20px 25px 0 0;
        padding:6px;
        display: block;
        border-radius:4px;
        border: 1px solid rgba(200, 200, 200, 1);
        cursor:pointer;
    }

    .e710_1050{
        margin:0;
        width:120px;
        height:150px;
        display: flex;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor:pointer;
    }

    .e710_1060{
        display:none;
    }

    .e710_1070{
        width:calc(100% - 160px);
        display: inline-block;
    }

    .e710_1100{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1101{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1102{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1110{
        width:100%;
        position:relative;
        margin:0 auto;
        height:400px;
        margin-bottom:20px;
    }

    .e710_1130{
        width:48%;
        float:left;
        position:relative;
        height:105px;
    }

    .e710_1150{
        width:48%;
        float:right;
        position:relative;
        height:105px;
    }

    .e710_1160{
        width:49%;
        display:inline-block;
        position:relative;
        height:60px;
        margin:0 auto;
    }

    .e710_1170{
        width:200px;
        margin:0 auto;
    }

@media only screen and (max-width:600px) {

    .e710_1160{
        width:100%;
        display:block;
        margin:10px auto;
    }

    .e710_1101{
        display:none;
    }

    .e710_1102{
        display:block;
    }
}

@media only screen and (min-width:600px) {

    .e710_1101{
        display:block;
    }

    .e710_1102{
        display:none;
    }
}

@media only screen and (min-width:1200px) {

    .e710_1101{
        display:block;
    }

    .e710_1102{
        display:none;
    }
}
</style>