<template>
<div class="e282_1020">
       
        <div class="e282_1008"></div>

        <div class="e282_1025">

            <div class="e282_1026">

                <div class="e285_1030">Thank You!</div>

                <div class="e285_1040">Your request has been received.</div>

                <div class="e285_1040">We will get back to you soon.</div>

                <div class="e285_1050">
                    <ButtonActive :config="homeConfig" @click="home" />
                </div>

            </div>

        </div>
    </div>
</template>

<script>

import ButtonActive from './ButtonActive.vue'

export default {
    name: 'ThankYou',
    emits: ["home"],

    components: {

        ButtonActive,
    },


    props: {
        config:{
            type:Object,
        }
    },

    created() {

        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
    },

    updated() {
      
    },

    data(){
        return {

            headerBackground:'white',
            email:{id:"email", type:"input", name:"email", title:"Email", placeholder:"", dataTest:""},
            password:{id:"password", type:"password", name:"password", title:"Password", placeholder:"", dataTest:""},
            homeConfig:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"0.4", text:"Home", width:"132px"},
        }
    },

    methods: {

        home(){
            
            this.$emit('home');
        },
        
    }
}

</script>

<style scoped>
h3{
  font-weight:800;
  font-family:'Open Sans';
  font-size:36px;
}
.e282_1008 {
    width:100%;
    height:750px;
    background-image:url("../assets/m30Tpic.png");
    background-repeat:no-repeat;
    background-size:cover;
}
.e282_1010{
    position:absolute;
    z-index:0;
    top:88px;
    left:0px;
}

.e282_1020{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e282_1021{
    width:100%;
    margin:0 auto;
    position:relative;
    height:88px;
}

.e282_1025{
    width:492px;
    height:270px;
    margin-top:120px;
    z-index:4;
    position:absolute;
    top:80px;
    left:calc((100% - 492px)/2);
    font-family:'Open Sans';
    text-align:left;
    font-size:36px;
    border-radius:16px;
    background-color: rgba(255, 255, 255, 1);
}

.e282_1026{
    width:460px;
    height:270px;
    z-index:5;
    position:absolute;
    top:6px;
    left:6px;
}

.e282_1024{
    width:400px;
    margin:0 auto;
    position:relative;
}

.e282_1023{
    font-family:'Open Sans';
    text-align:left;
    font-size:36px;
    font-weight:700;
    color: rgba(240, 66, 0, 1);
    position:absolute;
    top:17px;
    left:0px;
    padding:10px 0;
}

.e285_1030{
    color: rgba(240, 66, 0, 1);
    margin:0 auto;
    text-align:center;
    font-size:32px;
    font-family:'Open Sans';
    font-weight:800;
    margin-top:20px;
}

.e285_1040{
    margin:0 auto;
    text-align:center;
    font-size:18px;
    font-family:'Open Sans';
    color:rgba(28, 28, 28, 1);
    font-weight:400;
    margin-top:20px;
}

.e285_1050{
    position:absolute;
    top:190px;
    left:164px;
}

.e282_1040{
    position:absolute;
    top:100px;
    left:0px;
}

.e282_1050{
    position:absolute;
    top:200px;
    left:0px;
}

.e282_1060{
    width:132px;
    padding-top:300px;
    margin:0 auto;
}

.e282_1070{
    width:100%;
    margin:0 auto;
}
</style>