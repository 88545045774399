<template>
    <div class="e500_1000">
        <span :for="option">{{ option.label }}</span>
        <input
            :id="option.id"
            type="checkbox"
            :value="option.id"
            @change="onChange"
        >
        <span class="checkmark" />
    </div>
  </template>

<script>

export default {
  name: 'CheckBox',
  emits: ['input'],

  components: {
      //Button
  },

  props: {
    option: {
      type: Object,
    },
  },

  data(){
    return {

        isChecked:false,
    }
  },

  created(){

  },

  mounted(){

    document.getElementById(this.option.id).checked = this.option.checked;

    document.getElementById(this.option.id).disabled = this.option.disabled;
  },

  methods: {

    onChange() {

        let checked = document.getElementById(this.option.id).checked;

        this.$emit('input', {checked:checked, id:this.option.id});
    }
  }
}

</script>

<style scoped>

.e500_1000{
    font-family:'Open Sans';
    font-weight:400;
    font-size:16px;
}
.checkbox-form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.checkbox-form .answers {
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
}

.checkbox-form label {
	margin-left: 1em;
}

.checkbox-form .item {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 1em;
	height: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
}

.checkbox-form .item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-form .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #c2c2c2;
}

.checkbox-form .item:hover input ~ .checkmark {
	background-color: #949494;
}

.checkbox-form .item input:checked ~ .checkmark {
	background-color: rgba(240, 66, 0, 1);
}

.checkbox-form .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-form .item .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>