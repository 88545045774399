<template>

    <div class=e290_1000 >

        <div class=e290_1010></div>
        <div class=e290_1020 @click="close"></div>

		<div v-if="authUser">
			<div class=e290_1190 @click="signOut">Logout</div>

			<div class=e290_1160 @click="profile"></div>
		</div>

        <div class=e290_1035 @click="leave_all"></div>

        <div v-if="type===0" class=e290_1030>

			<div id=e290_6000 class=e290_6000 @mouseleave="clear" >
				<div class=e290_6020 v-for="option in industries" :key="option" @click="vertical_select(option.id)">{{option.title}}</div>
			</div>

			<div id=e290_6010 class=e290_6010 @mouseleave="clear">
				<div class=e290_6020 v-for="option in services" :key="option" @click="service_select(option.id)">{{option.title}}</div>
			</div>

            <div class=e290_1040  @click="vertical" @mouseenter="vertical_enter" @mouseleave="vertical_leave">
            <span id="t3" class="e290_1050 noselect" >Industries</span>

                <div id="ch3" class="e290_1060" @click="vertical"></div>

            </div>
            <div class=e290_1070 @click="service" @mouseenter="service_enter" @mouseleave="service_leave">
                <span id="t4" class="e290_1080 noselect" >Service</span>

                <div id="ch4" class="e290_1090" @click="service"></div>

            </div>
            <div class=e290_1100>
                <span  class="e290_1110 noselect" @click="contact">Contact Us</span>
            </div>
            <div class=e290_1120>
                <span  class="e290_1130 noselect" @click="quote">Free Quotation</span>
            </div>

			<div class=e290_1170>
                <span  class="e290_1180 noselect" @click="news">Global Drone News</span>
            </div>

			<div v-if="authUser">
				<div class=e290_1150>
					<span  class="e290_1110 noselect" @click="login">Dashboard</span>
				</div>
			</div>
			<div v-else>
				<div class=e290_1140>
					<ButtonActive :config="signUp" :key="signUp" @click="signup"/>
				</div>
				<div class=e290_1155>
					<ButtonActive :config="signIn" @click="login"/>
				</div>
			</div>
        </div>
    </div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'
import { Auth } from 'aws-amplify';

export default {
  name: 'ResponsiveMenu',
  emits: ["home", "login", "signup", "vertical", "service", "contact", "quote", "close", "profile", "news"],

  components: {
    ButtonActive
  },

  props: {

	config:{
		type: Object
	},

	type:{
		type:Number
	}
  },

  data(){
        return {
			verticals:null,
			industries:null,
			services:null,
			background:'#ECF0F6',
            signUp:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1", text:"Sign up", width:"140px"},
            signIn:{color:"#ffffff", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1", text:"Log in", width:"140px"},
			cho:require('../assets/chevron-down-24_o.png'),
			chb:require('../assets/chevron-down-24.png'),
			authUser:false,
        }
  },

  created(){

		this.industries = this.config.industries;

		this.verticals = this.config.verticals;

		this.services = this.config.services;

		let _this = this;

		Auth.currentAuthenticatedUser()
		//.then(user => {
		.then(() => {

			_this.signUp.disabled = true;

			_this.authUser = true;

			//this.props.navigation.navigate("App");
		})
		.catch(() => {

			//console.log("ERROR", err);
		});
  },

  methods: {

	home(){

		this.$emit('home');
	},

    login(){

		this.$emit('login');
    },

	async signOut() {

        try {

            await Auth.signOut();

            this.$emit('home');

        } catch (error) {

            console.log('error signing out: ', error);
        }
    },

	news(){

		this.$emit('news');
	},

	contact(){

		this.$emit('contact');
	},

	quote(){

		this.$emit('quote');
	},

	signup(){

		this.$emit('signup');
	},

	profile(){

		this.$emit('profile');
	},

    leave_all(){

        console.log("leave all");
        document.getElementById("t3").style.color = "rgba(240, 66, 0, 1)";
		document.getElementById("ch3").style.backgroundImage = "url('" + this.cho + "')";

        document.getElementById("t3").style.color = "rgba(28, 28, 28, 1)";
		document.getElementById("ch3").style.backgroundImage = "url('" + this.chb + "')";

        this.clear();
    },

	vertical_enter(){

		document.getElementById("t3").style.color = "rgba(240, 66, 0, 1)";
		document.getElementById("ch3").style.backgroundImage = "url('" + this.cho + "')";
	},

	vertical_leave(){

		document.getElementById("t3").style.color = "rgba(28, 28, 28, 1)";
		document.getElementById("ch3").style.backgroundImage = "url('" + this.chb + "')";
	},

	vertical_select(id){

		this.$emit('vertical', id);
	},

	service_select(id){

		this.$emit('service', id);
	},

	service_enter(){

		document.getElementById("t4").style.color = "rgba(240, 66, 0, 1)";
		document.getElementById("ch4").style.backgroundImage = "url('" + this.cho + "')";
	},

	service_leave(){
 
		document.getElementById("t4").style.color = "rgba(28, 28, 28, 1)";
		document.getElementById("ch4").style.backgroundImage = "url('" + this.chb + "')";
	},

	vertical(){

		document.getElementById("e290_6000").style.display = "block";
		document.getElementById("e290_6010").style.display = "none";
	},

	clear(){

		document.getElementById("e290_6000").style.display = "none";
		document.getElementById("e290_6010").style.display = "none";
	},

	service(){
		document.getElementById("e290_6000").style.display = "none";
		document.getElementById("e290_6010").style.display = "block";
	},

    close(){

        this.$emit('close');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.e290_1000{
    width:100%;
	min-width:200px;
    height:400px;
    position:fixed;
    top:0px;
    left:0px;
    background-color:v-bind(background);
    z-index:1000;
}

.e290_1010{
    width:50px;
    height:50px;
    position:absolute;
    left:24px;
    top:19px;
    background-image:url(../assets/logo_vertical_1.png);
    background-repeat:no-repeat;
    background-size:cover;
}

.e290_1020{
    width:16px;
    height:16px;
    position:absolute;
    left:calc(100% - 30px);
    top:32px;
    background-image:url(../assets/close.png);
    background-repeat:no-repeat;
    background-size:cover;
    cursor:pointer;
}

.e290_1030 { 
	position:absolute;
	width:100%;
	left:30px;
	top:100px;
}

.e290_1035 { 
	width:calc(100% - 180px);
    height:250px;
    background-color:v-bind(background);
	position:absolute;
	left:180px;
	top:80px;
}

.e290_1040 { 
	width:113px;
	height:24px;
	position:absolute;
	left:0px;
	top:0px;
}



.e290_1050 { 
	color:rgba(28, 28, 28, 1);
	width:81px;
	height:22px;
	position:absolute;
	left:0px;
	top:1px;
	font-family:'Open Sans';
	font-weight:bold;
	text-align:left;
	font-size:16px;
    cursor:pointer;
}

.e290_1060 { 
	background-image:url('../assets/chevron-down-24.png');
	background-size:cover;
	background-repeat:no-repeat;
	width:16px;
	height:8px;
	position:absolute;
	left:90px;
	top:10px;
}

.e290_1060:hover { 
	background-image:url('../assets/chevron-down-24_o.png');
	cursor:pointer;
}

.e290_1070 { 
	width:89px;
	height:24px;
	position:absolute;
	left:0px;
	top:50px;
}

.e290_1080 { 
	color:rgba(28, 28, 28, 1);
	width:57px;
	height:22px;
	position:absolute;
	left:0px;
	top:1px;
	font-family:'Open Sans';
	font-weight:bold;
	text-align:left;
	font-size:16px;
    cursor:pointer;
}

.e290_1090 { 
	background-image:url('../assets/chevron-down-24.png');
	background-size:cover;
	background-repeat:no-repeat;
	width:16px;
	height:8px;
	position:absolute;
	left:65px;
	top:10px;
}

.e290_1090:hover { 
	background-image:url('../assets/chevron-down-24_o.png');
	cursor:pointer;
}

.e290_1100 { 
	width:86px;
	height:22px;
	position:absolute;
	left:0px;
	top:100px;
}

.e290_1110 { 
	color:rgba(28, 28, 28, 1);
	width:86px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:bold;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e290_1110:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_1120 { 
	width:150px;
	height:22px;
	position:absolute;
	left:0px;
	top:150px;
}

.e290_1130 { 
	color:rgba(28, 28, 28, 1);
	width:150px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e290_1130:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_1140 { 
	position:absolute;
	left:calc(100% - 180px);
	top:0px;
}

.e290_1155 { 
	position:absolute;
	left:calc(100% - 180px);
	top:80px;
}

.e290_1150 { 
	position:absolute;
	left:0px;
	top:250px;
}


.e290_1160 { 
	position:absolute;
	left:calc(100% - 100px);
	top:15px;
	width:50px;
	height:50px;
	background-image:url('../assets/profile.png');
	background-size:cover;
	background-repeat:no-repeat;
	cursor:pointer;
	z-index:10;
}

.e290_1170 { 
	width:150px;
	height:22px;
	position:absolute;
	left:0px;
	top:200px;
}

.e290_1180 { 
	color:rgba(28, 28, 28, 1);
	width:150px;
	height:22px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	font-weight:700;
	text-align:left;
	font-size:16px;
	white-space: nowrap;
}

.e290_1180:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_1190 { 
	position:absolute;
    width:60px;
    height:20px;
	left:calc(100% - 75px);
	top:350px;
    font-family:'Open Sans';
	font-weight:700;
	font-size:16px;
	cursor:pointer;
	z-index:10;
}

.e290_1190:hover{
	color:rgba(240, 66, 0, 1);
	cursor:pointer;
}

.e290_6000{
	position:absolute;
	top:40px;
	left:0px;
	width:230px;
	display:none;
	z-index:1050;
}

.e290_6010{
	position:absolute;
	top:90px;
	left:0px;
	width:200px;
	display:none;
	z-index:1050;
}

.e290_6020{
	background-color:rgba(240, 66, 0, 1);
	color:rgba(255, 255, 255, 1);
	width:230px;
	padding:10px 10px;
	position:relative;
	font-family:'Open Sans';
	font-weight:400;
	text-align:left;
	font-size:16px;
	cursor:pointer;
}

.e290_6020:hover{
	background-color:rgba(255, 255, 255, 1);
	color:rgba(240, 66, 0, 1);
}

@media only screen and (max-width: 600px) {

	.e290_1150{
		left:0px;
		top:250px;
	}
}

</style>
