<template>

    <div class="e460_1000" >

        <div v-if="notReady">

            <div id="spin" class="e460_1020" />
            <div id="spin" class="e460_1030 noselect" >Loading Data ... </div>
        </div>

        <div v-else-if="userProfile">

            <UserProfile :config="user" @close="close" />
        </div>

        <div v-else-if="type===1">
            
            <CompanyMenu @home="home" @profile="profile" @close="close"  @showProjects="showProjects" @showContracts="showContracts" @showBids="showBids" @showPilots="showPilots" @dashboard="dashboard" />

            <ProjectList v-if="projects" :config="user" />

            <ContractList v-else-if="contracts" />

            <BidList v-else-if="bids" />

            <PilotList v-else-if="pilots" :config="user" />

            <CompanyDashboard v-else :config="user" />

        </div>

        <div v-else-if="type===2">

            <PilotMenu @home="home" @profile="profile" @close="close" @showLogs="showLogs" @dashboard="dashboard"/>

            <FlightDashboard v-if="flightLogs" :config="user" :key="refresh" @showLog="showLog"/>

            <NewFlightLog v-else-if="flightLog" :config="flightData" :user="user" @close="close"/>

            <PilotDashboard v-else :config="logGroupedData"  @newFlightLog="newFlightLog"/>
        </div>
    </div>
</template>

<script>
import PilotDashboard from './PilotDashboard.vue'
import CompanyDashboard from './CompanyDashboard.vue'
import ProjectList from './ProjectList.vue'
import ContractList from './ContractList.vue'
import PilotList from './PilotList.vue'
import BidList from './BidList.vue'
import FlightDashboard from './FlightDashboard.vue'
import NewFlightLog from './NewFlightLog.vue'
import PilotMenu from './PilotMenu.vue'
import UserProfile from './UserProfile.vue'
import CompanyMenu from './CompanyMenu.vue'
import { Spinner } from 'spin.js';
import 'spin.js/spin.css';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
    name: 'MainDashboard',
    emits: ["home"],

    components: {
        PilotDashboard,
        CompanyDashboard,
        NewFlightLog,
        PilotMenu,
        FlightDashboard,
        UserProfile,
        CompanyMenu,
        ProjectList,
        ContractList,
        BidList,
        PilotList,
    },


    props: {
        config:{
            type:Object,
        },
        showProfile:{
            type:Boolean,
        }
    },

    async created() {

        this.userProfile = this.showProfile;

        await this.getUser();

        switch(this.type){

            case 0:

                this.userProfile = true;

                this.notReady = false;

                break;

            case 1:

                //await this.getContractList();

                this.notReady = false;

            break;

            case 2:
            {

                await this.getLogs();

                await this.getUserDrones();

                if(this.user.logs){

                    let n = 0;

                    this.logGroupedData = [];

                    this.user.logs.forEach(log => {

                        let item = this.logGroupedData.filter(data => data.id === log.drone);

                        if(!item.length){

                            let groupedData = {id:log.drone, total:(log.duration)?log.duration:0};

                            if(log.drone) {
                                
                                if(this.user.drones.filter(drone => drone.id == log.drone).length){

                                    groupedData.image = this.user.drones.filter(drone => drone.id == log.drone)[0].image;

                                } else groupedData.image = this.blank;
                            
                            } else groupedData.image = this.blank;

                            groupedData.color = this.graphColors[n++];

                            if(n >= this.graphColors.length) n = 0;

                            this.logGroupedData.push(groupedData);

                        } else {

                            item[0].total += log.duration;
                        }
                    })
                }

                this.notReady = false;
            }
            break;
        }
    },

    updated() {
      
    },

    data(){
        return {
            refresh:0,
            notReady:true,
            flightLog:false,
            flightLogs:false,
            pilots:false,
            projects:false,
            contracts:false,
            bids:false,
            flightData:null,
            userProfile:false,
            headerBackground:'white',
            user:{},
            type:0,
            logGroupedData:[],
            contractTitle:'New Contract',
            blank:require('../assets/drone_blank.png'),

            newLog:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"New Flight Log", width:"210px"},
            
        }
    },

    methods: {
        
        showProjects(){

            this.projects = true;

            this.contracts = false;

            this.bids = false;

            this.pilots = false;
        },

        showContracts(){

            this.projects = false;

            this.contracts = true;

            this.bids = false;

            this.pilots = false;
        },

        showBids(){

            this.projects = false;

            this.contracts = false;

            this.bids = true;

            this.pilots = false;
        },

        showPilots(){

            this.projects = false;

            this.contracts = false;

            this.bids = false;

            this.pilots = true;
        },

        showLogs(){

            this.flightLogs = true;

            this.refresh = Math.random();
        },

        async getContractList(){

            const apiName = 'dtApiGateway';
            const path = '/contracts';

            let user = await Auth.currentAuthenticatedUser()

            const data = {
                body: {
                
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            this.user.contracts = (await API.get(apiName, path, data));

            spinner.stop();

            this.notReady = false;
        },

        async getUser(){

            const apiName = 'dtApiGateway';
            const path = '/getDrones';

            let user
    
            try{
            
                user = await Auth.currentAuthenticatedUser({bypassCache: true});

                const data = {
                    body: {
                        userOnly:true
                    },
                    headers: {
                        "Authorization":user.signInUserSession.idToken.jwtToken
                    }
                };

                var target = document.getElementById('spin');

                var spinner = new Spinner(this.spinnerOpts).spin(target);

                spinner.spin(target);

                this.user = await API.post(apiName, path, data);

                this.refresh = Math.random();

                if(this.user.data.find(item => item.Name === 'custom:type')) {

                    this.type = parseInt(this.user.data.find(item => item.Name === 'custom:type').Value);

                    this.newProfile = false;

                } else this.newProfile = true;

                spinner.stop();

            } catch {
            
                this.user = null;
            }

            
        },

        async getLogs(){

            const apiName = 'dtApiGateway';
            const path = '/getFlightLogs';

            let user = await Auth.currentAuthenticatedUser()

            const data = {
                body: {
                
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            this.user.logs = (await API.get(apiName, path, data)).logs;

            //this.refresh = Math.random();

            spinner.stop();
        },

        async getUserDrones(){

            const apiName = 'dtApiGateway';
            const path = '/getDrones';

            let user = await Auth.currentAuthenticatedUser();

            const data = {
                body: {
                    thumbnail:true
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            var target = document.getElementById('spin');

            var spinner = new Spinner(this.spinnerOpts).spin(target);

            spinner.spin(target);

            this.user.drones = (await API.post(apiName, path, data)).data;

            this.refresh = Math.random();
        },

        home(){

            this.$emit('home');
        },

        profile(){

            this.userProfile = true;
        },

        newFlightLog(){

            this.flightLog = true;

            this.flightData = {data:this.flightDataTemplate};

            this.flightData.title = "New Flight Log";
        },

        showLog(flightLogData){
            
            this.flightLogs = false;

            this.flightLog = true;

            this.flightData = flightLogData;

            this.flightData.title = "Flight Log";
        },

        dashboard(){

            this.projects = false;

            this.contracts = false;

            this.pilots = false;

            this.flightLog = false;

            this.flightLogs = false;
        },

        close(){

            this.projects = false;

            this.contracts = false;

            this.pilots = false;

            this.flightLog = false;

            this.userProfile = false;

            this.flightLogs = false;
        },

        menu(){

            this.flightLog = false;

            this.flightLogs = false;
        },
    }
}

</script>

<style scoped>

h3{
  font-weight:800;
  font-family:'Open Sans';
  font-size:36px;
}

.e460_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e460_1010{
    width:100%;
    margin:0 auto;
    position:relative;
    height:88px;
}

.e460_1020{
    width:100px;
    height:100px;
    margin:100px auto 20px auto;
    position:relative;
}

.e460_1030{
    height:40px;
    margin:0 auto;
    font-family: 'Open Sans';
    font-weight:400;
    font-size:24px;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}

</style>