<template>
    <div class="e470_1000">
        <div class="e470_1010">
            <FlightHoursPie :config="config" :title="pieTitle" :key="config"/>
        </div>

        <div class="e470_1020">
            <FlightHoursTable :config="config" :key="config" />
        </div>
    </div>
</template>

<script>
import FlightHoursPie from './FlightHoursPie.vue'
import FlightHoursTable from './FlightHoursTable.vue'

export default {
    
  name: 'FlightHours',
  emits: [],

  components: {
    FlightHoursPie,
    FlightHoursTable
  },

  props: {
    config: {
      type: Object
    },
  },



  data(){
        return {
            hoursData:null,
            pieTitle:"Total Flying Hours",
        }
  },

  created(){

  },

  methods: {

  }
}

</script>

<style scoped>

.e470_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e470_1010{
    width:200px;
    margin:40px auto 20px auto;
    position:relative;
}

.e470_1020{
    width:100%;
    margin:100px auto 10px auto;
    position:relative;
}

@media only screen and (max-width:600px) {
    
    .e470_1020{
        width:300px;
    }
}

@media only screen and (min-width:600px) {

    .e470_1020{
        width:600px;
    }
}

@media only screen and (min-width:1200px) {

    .e470_1020{
        width:900px;
    }
}
</style>