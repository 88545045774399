<template>
    <div id="spin" class="e700_1000">

        <div class="close_icon" @click="close"></div>

        <div class="e700_1010">

            <div class="e700_1020">
                <SearchField :config="searchConfig" @verify="search"/>
            </div>

            <div class="e700_1030">
                <PilotTile v-if="showPilot" :config="pilotData" @add="addPilot" />
            </div>
        </div>
    </div>
</template>

<script>
import SearchField from './SearchField.vue'
import PilotTile from './PilotTile.vue'
import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
  name: 'AddPilotForm',
  emits: ["close", "add"],

  components: {
    SearchField,
    PilotTile,
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            showPilot:false,
            pilotData:null,
            searchConfig:{id:"search", type:"input", regx:this._EMAIL, name:"search", title:"Search Pilots", placeholder:"pilot.name@example.com", value:'', required:false, dataTest:""},
        }
  },

  created(){

  },

  methods: {

    close(){

        this.$emit("close");
    },

    addPilot(pilot){

        this.$emit("add", pilot);
    },

    async search(email){

        let found = false;

        for(let pilot of this.config){

            if(pilot.email){

                if(pilot.email === email) found = true;
            }
        }

        if(!found){

            if(email){
                const apiName = 'dtApiGateway';
                const path = '/search';

                let user = await Auth.currentAuthenticatedUser();

                const data = {
                    body: {
                        pilot:true,
                        email:email.value,
                    },
                    headers: {
                        "Authorization":user.signInUserSession.idToken.jwtToken
                    }
                };

                var target = document.getElementById('spin');

                var spinner = new Spinner(this.spinnerOpts).spin(target);

                spinner.spin(target);

                this.pilotData = await API.post(apiName, path, data);

                if(this.pilotData.license) this.showPilot = true;

                spinner.stop();
            }
        }
    }
  }
}

</script>

<style scoped>

.e700_1000{
    width:100%;     /* important - do not remove! */
    height:100%;    /* important - do not remove! */

}

.e700_1010{
    position:relative;
}

.e700_1020{
    width:80%;
    height:60px;
    margin:30px auto;
}

.e700_1030{
    width:180px;
    margin:30px auto;
}


@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>