<template>
    
    <div class="e120_1000">

        <div v-if="addProject" class=e120_1040>

            <AddProjectForm :config="project" @close="close" />
        </div>

        <div v-else-if="addContract" class=e120_1040>

            <AddContractForm :config="contract" @close="close" />
        </div>

        <div v-else-if="showContractList" class=e120_1050>

            <div  class="e120_1060" v-for="contract of contracts" :key="contract">

                <ContractTile :config="contract" @editContract="editContract" />
            </div>
        </div>

        <div v-else>

            <div class="e120_1010">

                <ButtonActive :config="addProjectBtn" @click="newProject" />
            </div>

            <div class="e120_1020">

                <ProjectTile class="e120_1030" v-for="project of projects" :key="project" :config="project" @editProject="editProject" @newContract="newContract" @showContracts="showContracts" @showProject="showProject"/>

                <div class="e120_3000">
                    <ThreeDViewer />
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
import ThreeDViewer from './ThreeDViewer.vue'
import ButtonActive from './ButtonActive.vue'
import AddProjectForm from './AddProjectForm.vue'
import AddContractForm from './AddContractForm.vue'
import ProjectTile from './ProjectTile.vue'
import ContractTile from './ContractTile.vue'

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

export default {
  name: 'ProjectList',
  emits: [],

  components: {
    ThreeDViewer,
    ButtonActive,
    AddProjectForm,
    ProjectTile,
    ContractTile,
    AddContractForm,
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
    return {
            projects:[],
            contracts:[],
            contract:null,
            addContract:false,
            addProject:false,
            showContractList:false,
            editProj:false,
            addProjectBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Project", width:"175px"},
            project:{checklist:0, date_created:'', id:0, image:'', latitude:0, location:'', longitude:0, metar:'', notes:'', taf:'', user_end:'', user_start:'', windspeed:0, duration:0, project:'', company:0, type:0},
        }
  },

  async created(){

    await this.getProjects();
  },

  methods: {

    async close(){

        await this.getProjects();

        this.addProject = false;

        this.addContract = false;

        this.showContractList = false;
    },

    newProject(){

        this.addProject = true;
    },

    editProject(project){

        this.project = project;

        this.addProject = true;

    },

    editContract(contract){

        this.contract = contract;

        this.addContract = true;
    },

    showProject(project){

        this.project = project;
    },

    async showContracts(project){

        this.project = project;

        await this.getContracts();

        this.showContractList = true;
    },

    newContract(project){

        this.project = project;

        this.addContract = true;

        this.addProject = false;

        this.showContractList = false;
    },

    async getProjects(){

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/project';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.projects = await API.post(apiName, path, data);

        spinner.stop();
    },

    async getContracts(){

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/contracts';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                pid:this.project.id
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.contracts = await API.post(apiName, path, data);

        for(let contract of this.contracts){

            contract.config = {label:contract.title, div:"view_" + contract.id, fontSize:"18px"};
        }

        this.close();

        spinner.stop();
    },


  }
}

</script>

<style scoped>

.e120_1000{
    width:100%;
    margin:0 auto;
}

.e120_1010{
    width:160px;
    height:100px;
    margin-left:calc(100% - 200px);
    position:relative;
}

.e120_1020{
    width:90%;
    margin:0 auto;
}

.e120_1030{
    margin:5px;
    display:inline-block;
}

.e120_1040{
    width:90%;
    max-width:600px;
    margin:0 auto;
}

.e120_1050{
    width:100%;
    margin:0 auto;
}

.e120_1060{
    width:100%;
}

.e120_1070{
    margin:5px;
    display:none;
}

.e120_3000{
    width:80%;
    height:800px;
    margin:0 auto;
}

@media only screen and (max-width:600px) {

    .e120_1030{
        margin:5px 0;
    }

    .e120_3000{
        width:100%;
        height:400px;
        margin:0 auto;
    }
}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>