<template>
    <div class="e520_1000" @click="showDrone">
        <div class="e520_1010">
            <div class="e520_1070">
                <div class="e520_1080" :style="getImg" ></div>
            </div>
        </div>

        <div class="e520_1015">
            <div class="e520_1020">
                <div class="e520_1040 noselect">License ID: {{config.license}}</div>
                <div class="e520_1040 noselect">Contact: {{config.phone}}</div>
                <div class="e520_1040 noselect">Flight Hours: {{config.flight_hrs}}&nbsp;HRS</div>
            </div>
        </div>

        <div class="e520_1060">
            <ButtonActiveSm v-if="config.remove" class="e520_1065" :config="removePilotBtn" @click="removePilot" />
            <ButtonActiveSm v-else-if="config.select"  class="e520_1065" :config="selectPilotBtn" @click="selectPilot" />
            <ButtonActiveSm v-else-if="config.unselect"  class="e520_1065" :config="deSelectPilotBtn" @click="deSelectPilot" />
            <ButtonActiveSm v-else-if="config.add"  class="e520_1065" :config="addPilotBtn" @click="addPilot" />
        </div>
    </div>
</template>

<script>
import ButtonActiveSm from './ButtonActiveSm.vue'
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
  name: "PilotTile",
  emits: ["add", "remove", "select", "deselect"],

  components: {
    ButtonActiveSm
  },

  props: {
    config: {
      type: Object,
    },
  },

  computed: {

    getImg: function(){

        return {
            "background-image":'url(' + this.image + ')',
        }
    },
  },

  data(){
        return {
            image:require('../assets/profile2.png'),
            addPilotBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Pilot", width:"80px"},
            removePilotBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Remove", width:"80px"},
            selectPilotBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Select", width:"80px"},
            deSelectPilotBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"De-Select", width:"100px"},
        }
  },

  async created(){

    //console.log(this.config);

    if(this.config) await this.getImage();
  },

  methods: {

    async getImage(){

        const apiName = 'dtApiGateway';
        const path = '/image';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                path:'private/profile/image/',
                image:this.config.image
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.image = await API.post(apiName, path, data);
    },

    addPilot(){

        this.$emit('add', this.config.image)
    },

    selectPilot(){

        this.$emit('select', this.config)
    },

    deSelectPilot(){

        this.$emit('deselect', this.config.image)
    },

    removePilot(){

        this.$emit('remove', this.config.image)
    }
  }
}

</script>

<style scoped>
.e520_1000{
    width:180px;
    font-family:'Open Sans';
    font-weight:400;
    font-size:12px;
    border:1px solid rgba(200, 200, 200, 1);
    background-color:rgba(255, 255, 255, 1);
    cursor:pointer;
    margin:5px;
    display:inline-block;
}

.e520_1010{
    width:100%;
    display:inline-block;
    vertical-align:top;
    margin:0 auto;
}

.e520_1015{
    margin:auto;
    width:160px;
}

.e520_1020{
    margin-left:0;
}

.e520_1025{
    display:inline-block;
    width:30%;
}

.e520_1030{
    width:100%;
    font-weight:bold;
    text-align:left;
    
}

.e520_1040{
    width:100%;
    font-weight:normal;
    padding-top:3px;
    text-align:left;
}

.e520_1050{
    width:100%;
    font-weight:normal;
    font-size:20px;
}

.e520_1060{
    width:80px;
    margin:0 auto;
    position:relative;
    display:flex;
}

.e520_1065{
    margin:10px 0;
}

.e520_1070{
  width:100%;
  height:160px;
  display:block;
  cursor:pointer;
  
}

.e520_1080{
  width:140px;
  height:150px;
  
  display:flex;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  cursor:pointer;
  margin:5px auto 0 auto;
}

</style>