<template>
    <div class="e530_1000">

        <div class="e530_1010"></div>

        <div class="e530_1020">
            
            <div class="e530_1030 noselect">
                <CollapseObject :config="config.config" />

                <div v-if="config.open & (!contract.owner)" class="e530_1035">
                    <ButtonActiveSm :config="bidBtn" @click="newBid" />
                </div>

                <div v-if="config.owner" class="e530_1160" @click="editContract" ></div>
            </div>

            <div :id="viewId" class="e530_1140">

                <div v-if="config.open">

                    <div class="e530_1071">
                        <div class="e530_1041">
                            <CollapseObject :config="bidConfig" />
                            <div :id="bidId" >
                                <ContractBidTile v-for="bid in config.bids" :key="bid" :config="bid" />
                            </div>
                            <CollapseObject :config="desConfig" />
                            <div :id="desId" class="e530_1150"><TextField :config="descriptionConfig" @verify="verify" /></div>
                            <CollapseObject :config="delConfig" />
                            <div :id="delId" class="e530_1150"><TextField :config="deliverablesConfig" @verify="verify" /></div>
                            <CollapseObject :config="tacConfig"/>
                            <div :id="tacId" class="e530_1150"><TextField :config="contractTermsConfig" @verify="verify" /></div>
                        </div>
                    </div>
                </div>

                <div v-else >
                    <div class="e530_1070">

                        <div id="spin" class="e530_1090">

                            <MessageList :config="contract.messages" :key="msgRefresh"/>

                            <div class="e530_1100">

                                <input :id="msgId" type="input" class="e530_1110" />

                                <div class="e530_1120" @click="sendMessage">
                                    <div class="e530_1130"></div>
                                </div>
                            </div>
                        </div>

                        <div class="e530_1040">
                            <CollapseObject :config="conConfig" />
                            <div :id="conId" class="e530_1150"></div>
                            <CollapseObject :config="desConfig" />
                            <div :id="desId" class="e530_1150"><TextField :config="descriptionConfig" @verify="verify" /></div>
                            <CollapseObject :config="delConfig" />
                            <div :id="delId" class="e530_1150"><TextField :config="deliverablesConfig" @verify="verify" /></div>
                            <CollapseObject :config="tacConfig" />
                            <div :id="tacId" class="e530_1150"><TextField :config="contractTermsConfig" @verify="verify" /></div>
                        </div>
                    </div>

                    <div id="spin" class="e530_1080">

                        <MessageList :config="contract.messages" :key="msgRefresh"/>

                        <div class="e530_1100">

                            <input :id="msgId" type="input" class="e530_1110" />

                            <div class="e530_1120" @click="sendMessage">
                                <div class="e530_1130"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MessageList  from './MessageList.vue'
import CollapseObject  from './CollapseObject.vue'
import ButtonActiveSm from './ButtonActiveSm.vue'
import ContractBidTile from './ContractBidTile.vue'
import TextField from './TextField.vue'

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
  name: "ContractTile",
  emits: ["newBid", "editContract"],

  components: {
    MessageList,
    CollapseObject,
    ButtonActiveSm,
    ContractBidTile,
    TextField,
  },

  props: {
    config: {
      type: Object,
    },
  },

  computed: {

  },

  data(){
        return {
            contract:null,
            msgRefresh:false,
            date:new Date(),
            msgId:'',
            viewId:'',
            bidId:'',
            conId:'',
            desId:'',
            delId:'',
            tacId:'',
            messageTxt:{id:"message_", type:"input", regx:this._TEXT, name:"message", title:"Message", placeholder:"", error:false, value:'', required:false, dataTest:""},
            conConfig:{label:"Contractor", div:"", fontSize:"16px"},
            delConfig:{label:"Deliverables", div:"", fontSize:"16px"},
            desConfig:{label:"Description", div:"", fontSize:"16px"},
            tacConfig:{label:"Terms & Conditions", div:"", fontSize:"16px"},
            bidConfig:{label:"Bids", div:"", fontSize:"16px"},

            descriptionConfig:{id:"description", type:"input", name:"description", disabled:false, title:"Description", rows:3, placeholder:"", required:false, dataTest:""},
            deliverablesConfig:{id:"deliverables", type:"input", name:"deliverables", disabled:false, title:"Deliverables", rows:3, placeholder:"", required:false, dataTest:""},
            contractTermsConfig:{id:"contractTerms", type:"input", name:"contractTerms", disabled:false, title:"Contract Terms", rows:3, placeholder:"", required:false, dataTest:""},

            bidBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Bid", width:"50px"},

        }
  },

  async created(){

    this.contract = this.config;

    this.descriptionConfig.id = "description_" + this.contract.id;
    this.descriptionConfig.value = this.contract.description;

    this.deliverablesConfig.id = "deliverables_" + this.contract.id;
    this.deliverablesConfig.value = this.contract.deliverables;

    this.contractTermsConfig.id = "contractTerms_" + this.contract.id;
    this.contractTermsConfig.value = this.contract.contract_terms;

    this.msgId = "message_" + this.contract.id;

    this.viewId = "view_" + this.contract.id;

    this.bidConfig.label = "Bids (" + this.config.bids.length + ")";

    this.bidId = "bid_" + this.contract.id;

    this.bidConfig.div = this.bidId;

    this.conId = "con_" + this.contract.id;

    this.conConfig.div = this.conId;

    this.desId = "des_" + this.contract.id;

    this.desConfig.div = this.desId;

    this.delId = "del_" + this.contract.id;

    this.delConfig.div = this.delId;

    this.tacId = "tac_" + this.contract.id;

    this.tacConfig.div = this.tacId;

    console.log(this.contract);
  },

  mounted(){

    if(document.getElementById(this.conId)) document.getElementById(this.conId).style.display = 'none';
    if(document.getElementById(this.desId)) document.getElementById(this.desId).style.display = 'none';
    if(document.getElementById(this.delId)) document.getElementById(this.delId).style.display = 'none';
    if(document.getElementById(this.tacId)) document.getElementById(this.tacId).style.display = 'none';
  },

  methods: {

    inputChange(){

    },

    verify(){

    },

    editContract(){

        this.$emit('editContract', this.config);
    },

    newBid(){

        this.$emit('newBid', this.config);
    },

    stateChange(option){

        this.messageTxt.value = option.text;
    },

    async getContractor(){

        if(!this.contract.contractor_data){

            const apiName = 'dtApiGateway';
            const path = '/contracts';

            let user = await Auth.currentAuthenticatedUser();

            const data = {
                body: {
                    cid:this.config.id,
                    contractor:this.config.contractor
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            this.contract.contractor_data = await API.post(apiName, path, data);

            this.msgRefresh = !this.msgRefresh;
            
            //console.log(this.contract);

            this.messageTxt.value = '';
        } 
    },

    async sendMessage(){

        const apiName = 'dtApiGateway';
        const path = '/contracts';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                cid:this.config.id,
                message:document.getElementById(this.msgId).value
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.contract.messages = await API.post(apiName, path, data);

        this.msgRefresh = !this.msgRefresh;
        
        //console.log(this.contract);

        this.messageTxt.value = '';

        document.getElementById(this.msgId).value = '';
    },
  }
}

</script>

<style scoped>
.e530_1000{
    width:90%;
    max-width:500px;
    font-family:'Open Sans';
    font-weight:400;
    font-size:12px;
    border:1px solid rgba(200, 200, 200, 1);
    background-color:rgba(255, 255, 255, 1);
    cursor:pointer;
    margin:5px 10px 5px 30px;
    display:flex;
}

.e530_1010{
    width:20px;
    background-color:rgba(255, 106, 0, 1);
    display:inline-block;
}

.e530_1020{
    width:calc(100% - 20px);
    display:inline-block;
    vertical-align:top;
}

.e530_1030{
    position:relative;
    font-size:18px;
    font-weight:700;
    text-align:left;
    vertical-align:top;
    padding:10px;
}

.e530_1035{
    position:absolute;
    top:8px;
    left:calc(100% - 60px);
}

.e530_1040{
    position:absolute;
    top:0;
    left:10px;
    height:105px;
    width:calc(100% - 20px);
    text-align:left;
}

.e530_1041{
    width:calc(100% - 10px);
    margin-left:10px;
    text-align:left;
}

.e530_1045{
    width:95%;
    text-align:left;
    vertical-align:top;
    height:85px;
    margin: 0 10px;
    display:flex;
    position:relative;
}

.e530_1050{
    width:150px;
    font-size:16px;
    font-weight:700;
    line-height:2em;
    display:inline-block;
    white-space: nowrap;
}

.e530_1050:hover{
    color:rgba(255, 106, 0, 1);
}

.e530_1060{
    width:60%;
    min-width:190px;
    position:relative;
    display:inline-block;
    vertical-align:top;
}

.e530_1061{
    width:39%;
    min-width:80px;
    position:relative;
    display:inline-block;
    vertical-align:top;
    height:58px;
    margin:20px 0 0 0;
}

.e530_1065{
    position:absolute;
    top:0;
    left:calc(100% - 120px);
}

.e530_1070{
    width:60%;
    height:100%;
    min-width:320px;
    max-width:680px;
    display:inline-block;
    vertical-align:top;
    position:relative;
}

.e530_1080{
    width:40%;
    display:inline-block;
}

.e530_1090{
    width:40%;
    display:none;
}

.e530_1100{
    background:rgba(240, 240, 240, 1);
    text-align:left;
}

.e530_1110{
    width:calc(100% - 50px);
    margin:10px 8px 10px 5px;
    padding:5px;
    border:1px solid rgba(150, 150, 150, 1);
    border-radius:3px;
    background-color:rgba(255, 255, 255, 1);
    display:inline-block;
}

.e530_1120{
    width:30px;
    height:30px;
    border-radius:50%;
    background-color:rgb(3, 103, 3);
    vertical-align:middle;
    display:inline-block;
    position:relative;
}

.e530_1130 { 
	position:absolute;
	left:8px;
	top:4px;
	width:20px;
	height:20px;
	background-image:url('../assets/send.png');
	background-size:cover;
	background-repeat:no-repeat;
	cursor:pointer;
	z-index:10;
}

.e530_1140 { 
    display:none;
    border-top:1px solid rgba(200, 200, 200, 1);
}

.e530_1145 { 
    display:none;
    border-top:1px solid rgba(200, 200, 200, 1);
}

.e530_1150 { 
    display:none;
}

.e530_1160 { 
    width:25px;
    height:25px;
    position:absolute;
    top: 10px;
    left: calc(100% - 30px);
    background-image:url('../assets/edit.png');
    background-size:cover;
    background-repeat:no-repeat;
    cursor:pointer;
    opacity:1;
}

@media only screen and (max-width:600px) {

    .e530_1040{
        position:relative;
        top:0;
    }

    .e530_1050{
        width:100%;
    }

    .e530_1070{
        width:100%;
    }

    .e530_1080{
        display:none;
    }

    .e530_1090{
        display:inline-block;
        width:calc(100% - 10px);
        margin:0 0 0 10px;
    }
}

@media only screen and (min-width:600px) {
    .e530_1050{
        width:100%;
    }

    .e530_1080{
        height:400px;
        width:40%;
    }

    .e530_1090{
        display:none;
    }
}

@media only screen and (min-width:1200px) {

    .e530_1040{
        top:calc(100% - 40px);
    }

    .e530_1050{
        width:150px;
    }

    .e530_1080{
        height:400px;
        width:calc(100% - 680px);
    }

    .e530_1090{
        display:none;
    }
}
</style>