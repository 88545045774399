<template>

    <div class="e460_1000">
        company
    </div>
</template>

<script>

export default {
    name: 'CompanyDashboard',
    emits: ["home", "profile"],

    components: {

    },


    props: {
        config:{
            type:Object,
        }
    },

    async created() {

        
    },

    data(){
        return {
            headerBackground:'white',
        }
    },

    methods: {

        home(){

            this.$emit('home');
        },

        profile(){

            this.$emit('profile');
        },
    }
}

</script>

<style scoped>

h3{
  font-weight:800;
  font-family:'Open Sans';
  font-size:36px;
}

.e460_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e460_1010{
    width:230px;
    margin:0 auto;
    padding:10px;
}

.e460_1020{
    margin:0 auto;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

.e460_1010{
    width:230px;
    margin:0 10px;
    padding:10px;
}
}

</style>