<template>
    <div class="e090_1000 noselect">
        <div :id="id" class="e090_1010" @click="toggleObject()"></div>
        <div class="e090_1020">{{config.label}}</div>
        <div :id="inner" class="e090_1030"></div>
    </div>
</template>

<script>

//import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

export default {
  name: "CollapseObject",
  emits: ["toggle"],

  components: {
      //Button
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
        return {
            id:0,
            inner:0,
            fontSize:'18px',
            toggle:false,
        }
  },

  created(){

    let r = (Math.random() + 1).toString(36).substring(7);

    this.id = "toggle_" + r;

    this.inner = "inner_" + r;

    this.fontSize = this.config.fontSize;
  },

  methods: {

    toggleObject(){

        if(this.toggle){

            document.getElementById(this.id).style.transform = 'rotate(0deg)';

            document.getElementById(this.config.div).style.display = 'none';

        } else {

            document.getElementById(this.id).style.transform = 'rotate(90deg)';

            document.getElementById(this.config.div).style.display = 'block';
        }

        this.toggle = !this.toggle;
    }
  }
}

</script>

<style scoped>
.e090_1000{
    width:100%;
    vertical-align:middle;
    height:26px;
    display:inline-block;
}

.e090_1010{
    width:12px;
    height:12px;
    margin-right:10px;
    position:relative;
    display:inline-block;
    background-image:url(../assets/arrow_right.png);
    background-size:cover;
    background-repeat:none;
    cursor:pointer
}

.e090_1020{
    width:calc(100% - 30px);
    display:inline-block;
    position:relative;
    text-align:left;
    font-size:v-bind(fontSize);
    font-weight:bold;
    font-family:'Open Sans';
}

.e090_1030{
    width:100%;
    margin:10px;
    display:flex;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>