<template>
    <div id="container" class="e480_1000">
        <canvas id="cvas" class="e480_1010"></canvas>
    </div>
</template>

<script>

export default {
  name: 'FlightHoursPie',
  emits: [],

  components: {
      //Button
  },

  props: {
    config: {
      type: Object
    },
    title:{
        type:String
    }
  },

  data(){
        return {
            width:0,
            height:0
        }
  },

  created(){

  },

  mounted(){

    if(this.config){

        let total = 0;

        let a0 = 0;

        let t0 = 40;

        let container = document.getElementById('container');
        
        let cw = container.clientWidth;

        this.width = cw + "px";

        let r = cw / 2;

        let lw = 20;

        this.height = (cw + t0) + "px";

        container.innerHTML = '<canvas id="cvas" width="' + this.width + '" height="' + this.height + '"></canvas>';

        let cvas = document.getElementById("cvas");

        let ctx = cvas.getContext("2d");

        ctx.font = "20px Open Sans";

        ctx.fillStyle = "#303030";
        
        let tm = ctx.measureText(this.title);

        ctx.fillText(this.title, (cw - tm.width) / 2, 20);

        ctx.font = "16px Open Sans";

        tm = ctx.measureText("HOURS");

        ctx.fillText("HOURS", (cw - tm.width) / 2, r + t0 + 25);


        ctx.lineStyle = "#ff0000";

        this.config.forEach(log => total += log.total);

        ctx.font = "bold 40px Open Sans";

        tm = ctx.measureText(total);

        ctx.fillText(total, (cw - tm.width) / 2, r + t0 + 5);

        this.config.forEach(log => {

            let alpha = log.total / total * 2 * Math.PI;

            ctx.beginPath(), 

            ctx.lineWidth = lw,

            ctx.strokeStyle = log.color;

            ctx.arc(r, r + t0, r - (lw / 2), a0, alpha + a0, !1),

            ctx.stroke();

            a0 += alpha;
        });
    }
  },

  methods: {

  }
}

</script>

<style scoped>

.e480_1000{
    width:100%;
    margin:0 auto;
    position:relative;
}

.e480_1010{
    width:v-bind(width);
    height:v-bind(height);
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>