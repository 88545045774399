<template>
    <div class="e520_1000" @click="showLog">
        <div class="e520_1010">
            <div class="e520_1030">{{config.time}}</div>
            <div class="e520_1040">{{config.location}}</div>
            
        </div>
        <div class="e520_1020">
            <div class="e520_1050">{{config.duration}}</div>
            <div class="e520_1060">MINS</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'FlightLog',
  emits: ['showLog'],

  components: {
      //Button
  },

  props: {
    config: {
      type: Object,
    },
  },

  data(){
        return {
        }
  },

  created(){

  },

  methods: {

    showLog(){

        this.$emit('showLog', this.config)
    }

  }
}

</script>

<style scoped>
.e520_1000{
    width:300px;
    margin:5px auto;
    font-family:'Open Sans';
    font-weight:400;
    font-size:12px;
    padding:5px;
    border:1px solid rgba(50, 50, 50, 1);
    background-color:rgba(255, 255, 255, 1);
    cursor:pointer;
}

.e520_1010{
    text-align:left;
    display:inline-block;
    width:230px;
    vertical-align:top;
}

.e520_1020{
    display:inline-block;
    text-align:center;
    width:50px;
}

.e520_1030{
    width:100%;
    font-weight:bold;
    
}

.e520_1040{
    width:100%;
    font-weight:normal;
    padding-top:5px;
}

.e520_1050{
    width:100%;
    font-weight:normal;
    font-size:20px;
}

.e520_1060{
    width:100%;
    font-weight:normal;
    font-size:10px;
}

</style>