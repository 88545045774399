<template>
    
    <div class="e140_1000">

        <div v-if="add" class=e140_1040>

            <AddContractForm :config="contract"  @close="close" />
        </div>

        <div v-else-if="bid" class=e140_1040>

            <AddBidForm :config="contract" @close="close" />
        </div>

        <div v-else-if="config">
            <div class="e140_1020">
                <div class="e140_1030" v-for="contract of contracts" :key="contract">
                    <ContractTile :config="contract" />
                </div>
            </div>
        </div>

        <div id="spin" v-else>
            <div  class="e140_1020">

                <div class="e140_1060 noselect">
                    <CollapseObject :config="awardConfig"  />
                </div>

                <div id="award" class="e140_1030">
                    <div v-for="contract of contracts" :key="contract">
                        <ContractTile v-if="contract.contractor" :config="contract" />
                    </div>
                </div>
                
                <div class="e140_1060 noselect">
                    <CollapseObject :config="createConfig"  />
                </div>

                <div id="create" class="e140_1031">
                    <div v-for="contract of contracts" :key="contract">
                        <ContractTile v-if="contract.owner" :config="contract" @editContract="editContract"/>
                    </div>
                </div>

                <div class="e140_1060 noselect">
                    <CollapseObject :config="completeConfig"  />
                </div>

                <div id="complete" class="e140_1032">
                    <div v-for="contract of contracts" :key="contract">
                        <ContractTile v-if="contract.owner & contract.completed_supplier" :config="contract" />
                    </div>
                </div>

                <div class="e140_1060 noselect">
                    <CollapseObject :config="openConfig"  />
                </div>

                <div id="open" class="e140_1032">
                    <div v-for="contract of contracts" :key="contract">
                        <ContractTile v-if="contract.open & (!contract.owner)" :config="contract" @newBid="newBid" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>

import AddContractForm from './AddContractForm.vue'
import AddBidForm from './AddBidForm.vue'
import ContractTile from './ContractTile.vue'
import CollapseObject from './CollapseObject.vue'

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

export default {
  name: 'ContractList',
  emits: [],

  components: {
    AddContractForm,
    AddBidForm,
    ContractTile,
    CollapseObject,
  },

  props: {
    config:{
      type:Object,
    }
  },

  data(){
    return {
            add:false,
            bid:false,
            contracts:[],
            project:null,
            contract:null,
            award:0,
            create:0,
            complete:0,
            opn:0,
            awarded:'',
            created:'',
            completed:'',
            open:'',
            awardConfig:{label:"Awarded", base:"Awarded", div:"award", fontSize:"18px"},
            createConfig:{label:"Created", base:"Created", div:"create", fontSize:"18px"},
            completeConfig:{label:"Completed", base:"Completed", div:"complete", fontSize:"18px"},
            openConfig:{label:"Open", base:"Open", div:"open", fontSize:"18px"},
        }
  },

  async created(){

    console.log(this.config);

    await this.getContracts();
  },

  methods: {

    close(){

        this.add = false;

        this.bid = false;

        //this.getContracts();
    },

    addContractForm(){

        this.add = true;
    },

    editContract(contract){

        this.contract = contract;

        this.add = true;
    },

    newBid(contract){

        this.contract = contract;

        this.bid = true;
    },

    async getContracts(){

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        const apiName = 'dtApiGateway';
        const path = '/contracts';

        let user = await Auth.currentAuthenticatedUser();

        let data = {
            body: {
                
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        if(this.config) data.body.pid = this.config.id;

        console.log(data);

        this.contracts = await API.post(apiName, path, data);

        console.log(this.contracts);

        for(let contract of this.contracts){

            contract.config = {label:contract.title, div:"view_" + contract.id, fontSize:"16px"};

            if(contract.contractor) this.award++;
            if(contract.open & (!contract.owner)) this.opn++;

            if(contract.owner) this.create++;
            if(contract.completed_owner & contract.completed_supplier) this.complete++;
        }

        this.awardConfig.label = `${this.awardConfig.base} (${this.award})`;
        this.createConfig.label = `${this.createConfig.base} (${this.create})`; 
        this.completeConfig.label = `${this.completeConfig.base} (${this.complete})`;
        this.openConfig.label = `${this.openConfig.base} (${this.opn})`;


        this.close();

        spinner.stop();
    },
  }
}

</script>

<style scoped>

.e140_1000{
    width:100%;
    min-width:340px;
    margin:0 auto;
}

.e140_1010{
    width:180px;
    height:100px;
    margin-left:calc(100% - 200px);
    position:relative;
}

.e140_1020{
    width:100%;
}

.e140_1030{
    margin:5px;
    display:none;
}

.e140_1031{
    margin:5px;
    display:none;
}

.e140_1032{
    margin:5px;
    display:none;
}

.e140_1040{
    width:100%;
    max-width:600px;
    margin:0 auto;
    vertical-align:top;
}

.e140_1050{
    width:100%;
    text-align:left;
    font-family:'Open Sans';
    font-weight:700;
    font-size:24px;
    margin:0 5px;
    display:inline-block;
}

.e140_1051{
    width:calc(100% - 120px);
    max-width:600px;
    text-align:left;
    font-family:'Open Sans';
    font-weight:700;
    font-size:24px;
    margin:0 5px;
    display:inline-block;
}

.e140_1055{
    width:100px;
    padding-top:3px;
    display:inline-block;
    vertical-align:top;
}

.e140_1060{
    width:90%;
    margin:20px auto 0 auto;
}

.e140_3000{
    width:80%;
    height:800px;
    margin:0 auto;
}

.e140_1070{
    width:100%;
    max-width:200px;
    text-align:left;
    font-family:'Open Sans';
    font-weight:400;
    font-size:16px;
    margin:0 auto;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>