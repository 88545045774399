<template>
    <div v-if="config" class="e500_1000" >
        <div class="e500_1020" ></div>
        <div class="e500_1010" :style="getImg" />
        <div class="e500_1030" >
            <div class="e500_1040" >{{ config.total }}</div>
            <div v-if="config.total > 1" class="e500_1050" >HOURS</div>
            <div v-else class="e500_1050" >HOUR</div>
        </div>
    </div>
</template>

<script>
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

export default {
  name: 'FlightHoursLog',
  emits: [],

  components: {
      //Button
  },

  props: {
    config: {
      type: Object
    },
  },

  computed: {

        getImg: function()
        {
            return {
                "background-image":'url(' + this.image + ')',
            }
        },
    },

  data(){
        return {
            image:require('../assets/drone_blank.png'),
        }
  },

  async created(){

    await this.getImage()
  },

  methods: {

    async getImage(){

        const apiName = 'dtApiGateway';
        const path = '/image';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                path:'private/drone/thumbnail/',
                image:this.config.image
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        this.image = await API.post(apiName, path, data);
    },

  }
}

</script>

<style scoped>
.e500_1000{
    margin:10px;
    height:100px;
    border: 1px solid rgba(200, 200, 200, 1);
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.e500_1010{
    display:inline-block;
    margin:0 20px;
    width:100px; 
    height:100px; 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.e500_1020{
    width:30px;
    height:99px;
    background-color:v-bind('config.color');
    display:inline-block;
}

.e500_1030{
    font-family:'Open Sans';
    font-size:30px;
    width:100px;
    display:inline-block;
}

.e500_1040{
    font-weight:bold;
}

.e500_1050{
    font-size:14px; 
    font-weight:400;
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>