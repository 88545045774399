<template>
    <div class="e120_1000">
        <div class="e120_1010">{{ config.text }}</div>
        <div v-if="config.edit" class="e120_1020" @click="remove">x</div>
    </div>
</template>

<script>

export default {
  name: 'TagItem',
  emits: ["remove"],

  components: {
      
  },

  props: {
    config:{
        type:Object,
      }
  },

  data(){
        return {
            background:'',
            fontSize:'0px',
            color:'',
        }
  },

  created(){

    this.background = this.config.background;

    this.fontSize = this.config.fontSize;

    this.color = this.config.color;
  },

  methods: {

    remove(){

        this.$emit('remove', this.config);
    }
  }
}

</script>

<style scoped>

.e120_1000{
    font-family:'Open Sans';
    font-weight:700;
    font-size:v-bind(fontSize);
    border: 1px solid v-bind('background');
    background-color:v-bind('background');
    color:v-bind('color');
    margin:0 auto;
}

.e120_1010{
    padding:4px;
    display:inline-block;
    
}

.e120_1020{
    padding:3px;
    display:inline-block;
    cursor:pointer;
}

.e120_1020:hover{
    color:rgba(0, 0, 0, 1);
}

@media only screen and (max-width:600px) {

}

@media only screen and (min-width:600px) {

}

@media only screen and (min-width:1200px) {

}
</style>