<template>
    <div class="e710_1000">

        <div class="e710_1010 noselect">{{ title }}</div>


        <div class="e710_1100"><InputField :config="titleConfig" @verify="verify" /></div>

        <div class="e710_1100">
            <div class="e710_1130">
                <InputField :config="pStartConfig" />
            </div>

            <div class="e710_1150">
                <InputField :config="pEndConfig" />
            </div>
        </div>


        <div id="spin" class="e710_1020"><TextField :config="descriptionConfig" @verify="verify" /></div>

        <div class="e710_1020"><TextField :config="deliverablesConfig" @verify="verify" /></div>

        <div class="e710_1020"><TextField :config="contractTermsConfig" @verify="verify" /></div>

        <div class="e710_1101">

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="addBtn" @click="addContract" /></div>
            </div>
        </div>

        <div class="e710_1102">

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="addBtn" @click="addContract" /></div>
            </div>

            <div class="e710_1160">
                <div class="e710_1170"><ButtonActive :config="cancelBtn" @click="close" /></div>
            </div>
        </div>
    </div>
</template>

<script>
import InputField from './InputField.vue'
import ButtonActive from './ButtonActive.vue'
import TextField from './TextField.vue'

import { Spinner } from 'spin.js';
import 'spin.js/spin.css';

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns'

export default {
  name: "AddContractForm",
  emits: ["close", "updatedContracts"],

  components: {
    InputField,
    TextField,
    ButtonActive,
  },

  props: {
    config:{
      type:Object,
    }
  },

  computed: {

        getImg: function(){

            return {
                "background-image":'url(' + this.image + ')',
            }
        }
    },

  data(){
        return {
            title:'Add Project',
            titleConfig:{id:"title", type:"input", regx:this._TEXT, name:"title", disabled:false, title:"Title", placeholder:"", value:this.config.name + ":", required:false, dataTest:""},
            descriptionConfig:{id:"description", type:"input", name:"description", disabled:false, title:"Description", rows:3, placeholder:"", required:false, dataTest:""},
            deliverablesConfig:{id:"deliverables", type:"input", name:"deliverables", disabled:false, title:"Deliverables", rows:3, placeholder:"", required:false, dataTest:""},
            contractTermsConfig:{id:"contractTerms", type:"input", name:"contractTerms", disabled:false, title:"Contract Terms", rows:3, placeholder:"", required:false, dataTest:""},
            pStartConfig:{id:"pStart", type:"input", regx:this._DATE, name:"pStart", disabled:false, title:"Proposed Start Date", placeholder:"", value:format(new Date(), 'yyyy-MM-dd HH:mm:ss'), required:false, dataTest:""},
            pEndConfig:{id:"pEnd", type:"input", regx:this._DATE, name:"pEnd", disabled:false, title:"Proposed End Date", placeholder:"", value:format(new Date(), 'yyyy-MM-dd HH:mm:ss'), required:false, dataTest:""},
            
            statusConfig:{id:"status", name:"status", title:"Project Status", value:0, required:true, dataTest:"", options: [
                { text: 'Design', value: this._DESIGN },
                { text: 'Budget Approval', value: this._BUDGET_APPROVAL },
                { text: 'Sourcing Vendors', value: this._VENDORS },
                { text: 'Vendor Selection', value: this._SELECTION },
                { text: 'External Approvals', value: this._EXTERNAL_APPROVAL },
                { text: 'In Progress', value: this._IN_PROGRESS },
                { text: 'Awaiting Deliverables', value: this._AWAITING_DELIVERABLES },
                { text: 'Completed', value: this._COMPLETED }
            ],},

            cancelBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Cancel", width:"200px"},
            addBtn:{color:"#FFFFFF", background:"#F04200", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Add Contract", width:"200px"}, 
        }
  },

  async created(){
console.log(this.config);
    this.descriptionConfig.value = this.config.description;
    this.deliverablesConfig.value = this.config.deliverables;
    this.contractTermsConfig.value = this.config.contract_terms;

    if(this.config.id){
        this.titleConfig.value = this.config.title;
        this.title = "Update Contract";
        this.addBtn.text = "Update Contract";
    }
  },

  methods: {

    close(){

       this.$emit("close");
    },

    statusChange(status){

        console.log(status);
        this.statusConfig.error = false;

    },

    verify(){


    },

    async addContract(){

        let verified = true;

        var target = document.getElementById('spin');

        var spinner = new Spinner(this.spinnerOpts).spin(target);

        spinner.spin(target);

        if(!document.getElementById("title").value.length){

            verified = false;

            this.titleConfig.error = true;

            //this.refresh = Math.random();
        }

        if(!document.getElementById("description").value.length){

            verified = false;

            this.descriptionConfig.error = true;
        }

        if(!document.getElementById("deliverables").value){

            verified = false;

            this.deliverablesConfig.error = true;
        }

        if(!document.getElementById("contractTerms").value){

            verified = false;

            this.contractTermsConfig.error = true;
        }

        if(!document.getElementById("pStart").value){

            verified = false;

            this.contractTermsConfig.error = true;
        }

        if(!document.getElementById("pEnd").value){

            verified = false;

            this.contractTermsConfig.error = true;
        }

        if(verified){

            let contract = {
                pid:this.config.id,
                title:document.getElementById("title").value,
                description:document.getElementById("description").value,
                deliverables:document.getElementById("deliverables").value,
                contract_terms:document.getElementById("contractTerms").value,
                proposed_start:document.getElementById("pStart").value,
                proposed_end:document.getElementById("pEnd").value,
            }

            console.log(contract);

            const apiName = 'dtApiGateway';
            const path = '/contracts';

            let user = await Auth.currentAuthenticatedUser();

            const data = {
                body: {
                    add:contract
                },
                headers: {
                    "Authorization":user.signInUserSession.idToken.jwtToken
                }
            };

            console.log(data);

            this.contracts = await API.post(apiName, path, data);

            this.$emit("updatedContracts", this.contracts);
        }

        this.close();

        spinner.stop();
    },

  }
}

</script>

<style scoped>

    .e710_1000{
        width:100%;
    }

    .e710_1010{
        margin:20px auto;
        color:rgba(28, 28, 28, 1);
        font-family:'Open Sans';
        font-size:30px;
        font-weight:700;
    }

    .e710_1020{
        width:100%;
        position:relative;
        margin:0 auto;
    }

    .e710_1030{
        width:160px;
        height:200px;
        display: inline-block;
        vertical-align:top;
    }

    .e710_1040{
        margin:20px 25px 0 0;
        padding:6px;
        display: block;
        border-radius:4px;
        border: 1px solid rgba(200, 200, 200, 1);
        cursor:pointer;
    }

    .e710_1050{
        margin:0;
        width:120px;
        height:150px;
        display: flex;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        cursor:pointer;
    }

    .e710_1060{
        display:none;
    }

    .e710_1070{
        width:calc(100% - 160px);
        display: inline-block;
    }

    .e710_1100{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1101{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1102{
        width:100%;
        position:relative;
        margin:0 auto;
        height:105px;
    }

    .e710_1110{
        width:100%;
        position:relative;
        margin:0 auto;
        height:400px;
        margin-bottom:20px;
    }

    .e710_1130{
        width:48%;
        float:left;
        position:relative;
        height:105px;
    }

    .e710_1150{
        width:48%;
        float:right;
        position:relative;
        height:105px;
    }

    .e710_1160{
        width:49%;
        display:inline-block;
        position:relative;
        height:60px;
        margin:0 auto;
    }

    .e710_1170{
        width:200px;
        margin:0 auto;
    }

@media only screen and (max-width:600px) {

    .e710_1160{
        width:100%;
        display:block;
        margin:10px auto;
    }

    .e710_1101{
        display:none;
    }

    .e710_1102{
        display:block;
    }
}

@media only screen and (min-width:600px) {

    .e710_1101{
        display:block;
    }

    .e710_1102{
        display:none;
    }
}

@media only screen and (min-width:1200px) {

    .e710_1101{
        display:block;
    }

    .e710_1102{
        display:none;
    }
}
</style>