<template>
	<div class=e400_1000>
		<div  class="e400_1010">
			<div class=e400_1020>

				<div class=e400_1030>
					<div  class="e400_1040"></div>
					<div class=e400_1050>
						<span  class="e400_1060">Our White Paper</span>
						<div class=e400_1070>
							<div class=e400_1080>
								<span  class="e400_1090">End-to-end commercial drone solutions leveraging state-of-the-art technology</span>
								<span  class="e400_1100">
								<p>Recent advances in materials, cloud, sensor and communications technology have allowed the creation of a new generation of drones that boast superior stability, lighter weight and advanced sesnor payloads.</p>
								<p>Image and telemetry data from these drones can be uploaded in real time to the cloud for defect analysis, classification and visualisation. Automated report writing also eases the preparation of contractor tenders, litigation documents and historical records.Read our whitepaper to learn more about Drone Tech/s vision for the future of sustainable inspection, sensing and survey operations.</p></span>
							</div>
						</div>
					</div>
				</div>

				<div class=e400_1110>
					<div class=e400_1120>
						<div class=e400_1130>
							<div  class="e400_1140"></div>
							<div class=e400_1150>
								<div class=e400_1160>
									<div  class="e400_1170"></div>
								</div>
							</div>
							<div  class="e400_1180"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class=e400_1190>
			<ButtonActive :config="data" />
		</div>
	</div>
</template>

<script>
import ButtonActive from './ButtonActive.vue'

export default {
	name: 'OfferinLeft',
	emits: [],

	components: {
		ButtonActive,
    },

	props: {

	},

	data(){
		return {
			data:{color:"#F04200", disabled:true, background:"#FFFFFF", highlightBorder:"#F04200", border:"#F04200", text:"Download Whitepaper", width:'277px'},
		}
	},

	methods: {

	}
}
</script>

<style scoped>
.e400_1000 { 
	width:100%;
	height:650px;
	position:relative;
}
.e400_1010 { 
	background-color:rgba(255, 255, 255, 1);
	height:600px;
	position:absolute;
	left:calc((100% - 1440px)/2);
	top:0px;
}
.e400_1020 { 
	height:490px;
	position:absolute;
	left:112px;
	top:10px;
}

.e400_1030 { 
	width:640px;
	height:490px;
	position:absolute;
	left:550px;
	top:0px;
}
.e400_1040 { 
	border-radius:500px;
	background-color:rgba(240, 66, 0, 1);
	width:28px;
	height:28px;
	position:absolute;
	left:0px;
	top:10px;
}
.e400_1050 { 
	width:602px;
	height:490px;
	position:absolute;
	left:38px;
	top:0px;
}
.e400_1060 { 
	color:rgba(240, 66, 0, 1);
	width:525px;
	height:32px;
	position:absolute;
	left:0px;
	top:0px;
	font-family:'Open Sans';
	text-align:left;
	font-size:32px;
	letter-spacing:0;
	line-height:px;
}
.e400_1070 { 
	width:602px;
	height:457px;
	position:absolute;
	left:0px;
	top:32.3466796875px;
}
.e400_1080 { 
	width:602px;
	height:457px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1090 { 
	color:rgba(0, 0, 0, 1);
	width:536px;
	height:79px;
	position:absolute;
	left:0px;
	top:10px;
	font-family:'Open Sans';
	text-align:left;
	font-size:24px;
	letter-spacing:0;
	line-height:px;
}
.e400_1100 { 
	color:rgba(51, 51, 51, 1);
	width:602px;
	height:373px;
	position:absolute;
	left:0px;
	top:80px;
	font-family:'Open Sans';
	text-align:left;
	font-size:18px;
	letter-spacing:0;
}
.e400_1110 { 
	width:518px;
	height:443px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1120 { 
	width:518px;
	height:444px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1130 { 
	width:518px;
	height:443px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1140 { 
	background-color:rgba(240, 66, 0, 1);
	width:418px;
	height:388px;
	position:absolute;
	left:14px;
	top:39px;
}
.e400_1150 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1160 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
}
.e400_1170 { 
	width:400px;
	height:400px;
	position:absolute;
	left:0px;
	top:0px;
	border-top-left-radius:16px;
	border-top-right-radius:16px;
	border-bottom-left-radius:16px;
	border-bottom-right-radius:16px;
	background-image:url(../assets/m30_camera.png);
	background-repeat:no-repeat;
	background-size:cover;
}
.e400_1180 { 
	background-image:url(../assets/hatch.png);
	background-size:contain;
	width:237px;
	height:196px;
	position:absolute;
	left:280px;
	top:248px;
}
.e400_1190 { 
	position:absolute;
	left:50%;
	top:473px;
}

@media only screen and (max-width: 600px) {

	.e400_1000 { 
		height:1000px;
	}

	.e400_1010 { 
		height:600px;
		left:calc((100% - 420px)/2);
		top:0px;
	}

	.e400_1020 { 
		left:30px;
		top:25px;
	}

	.e400_1040 { 
		width:20px;
		height:20px;
		left:4px;
		top:14px;
	}

	.e400_1060 { 
		font-weight:600;
	}

	.e400_1090 { 
		width:350px;
		left:-35px;
		top:20px;
		line-height:1.3em;
		font-weight:bold;
		font-size:20px;
	}

	.e400_1100 { 
		width:340px;
		height:373px;
		left:-35px;
		top:100px;
		font-size:14px;
	}

	.e400_1110 { 
		width:420px;
		height:400px;
		left:0;
		top:560px;
	}	
	
	.e400_1030 { 
		left:10px;
		top:0px;
	}

	.e400_1170 { 
		width:320px;
		height:320px;
	}

	.e400_1140 { 
		width:340px;
		height:310px;
	}

	.e400_1180 { 
		left:230px;
		top:160px;
	}

	.e400_1190 { 
		left:calc((100% - 277px)/2);
		top:520px;
	}
}

@media only screen and (min-width: 600px) {

	.e400_1000 { 
		height:950px;
	}

	.e400_1010 { 
		height:600px;
		left:calc((100% - 600px)/2);
		top:0px;
	}

	.e400_1020 { 
		height:490px;
		width:600px;
		position:absolute;
		left:50px;
		top:45px;
	}

	.e400_1060 { 
		font-weight:700;
	}

	.e400_1090 { 
		width:500px;
		left:-35px;
		top:20px;
		line-height:1.2em;
		font-size:26px;
	}

	.e400_1100 { 
		width:500px;
		height:373px;
		left:-35px;
		top:100px;
		font-family:'Open Sans';
		text-align:left;
		font-size:14px;
		letter-spacing:0;
	}

	.e400_1110 { 
		width:420px;
		height:400px;
		left:calc((100% - 500px));
		top:470px;
	}	

	.e400_1120 { 
		width:518px;
		height:444px;
		position:absolute;
		left:0px;
		top:0px;
	}

	.e400_1030 { 
		width:640px;
		height:490px;
		position:absolute;
		left:10px;
		top:0px;
	}

	.e400_1170 { 
		width:320px;
		height:320px;
	}

	.e400_1140 { 
		width:340px;
		height:310px;
	}

	.e400_1180 { 
		left:230px;
		top:160px;
	}

	.e400_1190 { 
		position:absolute;
		left:calc((100% - 277px)/2);
		top:420px;
	}
}

@media only screen and (min-width: 1200px) {
	.e400_1000 { 
		height:700px;
	}
	.e400_1010 { 
		height:650px;
		left:calc((100% - 1440px)/2);
		top:0px;
	}

	.e400_1020 { 
		left:112px;
		top:85px;
	}

	.e400_1060 { 
		font-weight:700;
	}

	.e400_1090 { 
		width:537px;
		left:0px;
		top:20px;
		font-size:26px;
	}
	.e400_1100 { 
		width:602px;
		height:373px;
		left:0px;
		top:80px;
		font-size:18px;
	}
	.e400_1110 { 
		width:518px;
		height:443px;
		left:0px;
		top:0px;
	}

	.e400_1030 { 
		left:550px;
		top:0px;
	}
	.e400_1170 { 
		width:400px;
		height:400px;
	}
	.e400_1180 { 
		left:280px;
		top:248px;
	}
	.e400_1190 { 
		left:50%;
		top:530px;
	}
}
</style>
