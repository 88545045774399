<template>
    <div class="e175_1000">
        <div class="e175_1010">
            <CollapseObject :config="bidConfig" />
        </div>

        <div class="e175_1110">{{ data.currency }}</div>
        <div class="e175_1120">{{ data.quote }}</div>
        <div class="e175_1130">
            <ButtonActiveSm :config="selBtn" />
        </div>

        <div :id="bidDiv" class="e170_1030">

            <div class="e170_1035">
                <div class="e170_1010">
                    <PilotTile  :config="data.pilotData" :key="data.pilotData"/>
                </div>

                <div class="e170_1010">
                    <UserDrone  :config="data.droneData" :key="data.droneData" />
                </div>
            </div>

            <div class="e170_1040">
                <TextField :config="textConfig" :key="textConfig.value"/>
            </div>

        </div>
    </div>
</template>

<script>
import PilotTile from './PilotTile.vue'
import UserDrone from './UserDrone.vue'
import TextField from './TextField.vue'
import ButtonActiveSm from './ButtonActiveSm.vue'
import CollapseObject from './CollapseObject.vue'

import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

//import { Spinner } from 'spin.js';
//import 'spin.js/spin.css';
//import { API } from 'aws-amplify';
//import { Auth } from 'aws-amplify';

export default {
  name: "ContractBidTile",
  emits: [],

  components: {
    PilotTile,
    UserDrone,
    TextField,
    ButtonActiveSm,
    CollapseObject,
  },

  props: {
    config:{
      type:Object,
    }
  },

  computed: {

    },

  data(){
        return {
            bid:null,
            bidDiv:'',
            data:{company_name:'', description:'', quote:'0.00', currency:'USD', title:'', pilotData:{license:''}, droneData:{make:0, model:0}},
            bidConfig:{label:"Bid", base:"Bid", div:"bid", fontSize:"18px"},
            selBtn:{color:"#F04200", background:"#FFFFFF", border:"#F04200", disabled:false, highlightBorder:"#F04200", opacity:"1.0", text:"Select", width:"60px"},
            textConfig:{id:"description", type:"input", name:"description", disabled:true, title:"Description", rows:3, placeholder:"", value:"", required:false, dataTest:""},
        }
  },

  async created(){

    await this.getBidData();

    let r = (Math.random() + 1).toString(36).substring(7);

    this.bidDiv = "bid_" + r;

    this.bidConfig.label = this.data.company_name;

    this.bidConfig.div = this.bidDiv;

    this.textConfig.value = this.data.description;
  },

  methods: {

    async getBidData(){

        const apiName = 'dtApiGateway';
        const path = '/bids';

        let user = await Auth.currentAuthenticatedUser();

        const data = {
            body: {
                bid:this.config.id,
            },
            headers: {
                "Authorization":user.signInUserSession.idToken.jwtToken
            }
        };

        let bid = await API.post(apiName, path, data);

        this.data = bid[0];
        
        //console.log(this.data);
    },

  }
}

</script>

<style scoped>
.e175_1000{
    width:calc(100% - 10px);
    padding:5px 10px;
    margin:5px 0;
    border:1px solid rgba(200, 200, 200, 1);
    font-family:'Open Sans';
    font-size:14px;
    font-weight:bold;
    cursor:pointer;
}

.e175_1010{
    width:calc(100% - 180px);
    overflow:hidden;
    display:inline-block;
    vertical-align:middle;
}

.e170_1030{
    margin:5px;
    display:none;
}

.e170_1035{
    display:inline-block;
    vertical-align:top;
}

.e170_1010{
    vertical-align:top;
    display:inline-block;
}

.e170_1040{
    width:calc(100% - 450px);
    position:relative;
    display:inline-block;
}

.e170_1100{
    width:100%;
    height:85px;
    text-align:right;
}

.e170_1110{
    width:80px;
    height:85px;
    margin-right:10px;
    display:inline-block;
}

.e170_1120{
    text-align:right;
    width:150px;
    height:85px;
    display:inline-block;
}

.e175_1110{
    width:40px;
    text-align:right;
    display:inline-block;
    vertical-align:middle;
}

.e175_1120{
    text-align:right;
    width:60px;
    display:inline-block;
    vertical-align:middle;
}

.e175_1130{
    margin-left:20px;
    width:60px;
    display:inline-block;
    vertical-align:middle;
}

@media only screen and (max-width:600px) {

    .e170_1040{
        width:100%;
        display:block;
    }

    .e170_1035{
        width:100%;
        display:block;
        margin:0 auto;
    }
}

@media only screen and (min-width:600px) {
    .e170_1040{
        width:90%;
        display:block;
        margin:0 auto;
    }

    .e170_1035{
        width:90%;
        display:block;
        margin:0 auto;
    }
}

@media only screen and (min-width:1200px) {
    .e170_1040{
        width:calc(100% - 450px);
        position:relative;
        display:inline-block;
    }
}
</style>